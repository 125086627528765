import CentDetailed from "@/images/previews/cent-detailed.png";
import CentSimple from "@/images/previews/cent-simple.png";

import CentColorBlind from "@/images/previews/cent-color-blind.png";
import CentDefault from "@/images/previews/cent-default.png";
import CentInferno from "@/images/previews/cent-inferno.png";
import CentRdYlGn from "@/images/previews/cent-RdYlGn.png";
import CentTurbo from "@/images/previews/cent-turbo.png";
import CentViridis from "@/images/previews/cent-viridis.png";

import SpocColorBlind from "@/images/previews/spoc-color-blind.png";
import SpocPlasma from "@/images/previews/spoc-plasma.png";
import SpocSinebow from "@/images/previews/spoc-sinebow.png";
import SpocTurbo from "@/images/previews/spoc-turbo.png";
import SpocWarm from "@/images/previews/spoc-warm.png";

import ColorBlind from "@/images/schemes/color-blind.png";
import Inferno from "@/images/schemes/inferno.png";
import Plasma from "@/images/schemes/plasma.png";
import RdGn from "@/images/schemes/RdGn.png";
import RdYlGn from "@/images/schemes/RdYlGn.png";
import Sinebow from "@/images/schemes/sinebow.png";
import Turbo from "@/images/schemes/turbo.png";
import Viridis from "@/images/schemes/viridis.png";
import Warm from "@/images/schemes/warm.png";

import {
    EDataColorSchemes,
    EDetailedFingerprint,
} from "@/components/preferences/models";

// https://git.lum-gmbh.de/sepview/web/-/wikis/Architecture/Preferences-preview-files

export function getCentDetailImage(value: EDetailedFingerprint) {
    return value === EDetailedFingerprint.Detailed ? CentDetailed : CentSimple;
}

export function getCentColorImage(scheme: EDataColorSchemes) {
    switch (scheme) {
        case EDataColorSchemes.ColorBlind:
            return CentColorBlind;

        case EDataColorSchemes.Inferno:
            return CentInferno;

        case EDataColorSchemes.RdYlGn:
            return CentRdYlGn;

        case EDataColorSchemes.Turbo:
            return CentTurbo;

        case EDataColorSchemes.Viridis:
            return CentViridis;

        case EDataColorSchemes.RdGn:
        default:
            return CentDefault;
    }
}

export function getSpocColorImage(scheme: EDataColorSchemes) {
    switch (scheme) {
        case EDataColorSchemes.ColorBlind:
            return SpocColorBlind;

        case EDataColorSchemes.Plasma:
            return SpocPlasma;

        case EDataColorSchemes.Sinebow:
            return SpocSinebow;

        case EDataColorSchemes.Turbo:
            return SpocTurbo;

        case EDataColorSchemes.Warm:
            return SpocWarm;
    }
}

export function getSchemeImage(scheme: EDataColorSchemes) {
    switch (scheme) {
        case EDataColorSchemes.ColorBlind:
            return ColorBlind;

        case EDataColorSchemes.RdGn:
            return RdGn;

        case EDataColorSchemes.Inferno:
            return Inferno;

        case EDataColorSchemes.RdYlGn:
            return RdYlGn;

        case EDataColorSchemes.Turbo:
            return Turbo;

        case EDataColorSchemes.Viridis:
            return Viridis;

        case EDataColorSchemes.Warm:
            return Warm;

        case EDataColorSchemes.Plasma:
            return Plasma;

        case EDataColorSchemes.Sinebow:
            return Sinebow;
    }
}
