import {boundMethod} from "autobind-decorator";
import {drag} from "d3-drag";
import {select} from "d3-selection";
import {TryGetLockOptions} from "react-beautiful-dnd";

import {IDragBound} from "./models";

import {parseNumber} from "@translate/T";

class Draggable {
    // remembers the starting position of the element on dragging
    private initialLeft = 0;
    private initialTop = 0;

    // the start position of the mouse while dragging
    private dragStartX = 0;
    private dragStartY = 0;

    private isDragging = false;
    private preventDrag = false;
    private readonly className = "grab-cursor";

    public constructor(
        private readonly ele: HTMLElement,
        private readonly maxBounds: () => IDragBound,
        private readonly onResize?: (box: IDragBound) => void,
    ) {
        const dragHandler = drag()
            .subject(drag().subject)
            .on("start", this.onDragStart)
            .on("drag", this.onDrag)
            .on("end", this.onDragEnd);

        this.ele.className += " " + this.className;
        select<HTMLElement, string>(this.ele).call(dragHandler as any);
    }

    public dispose() {
        const dragHandler = drag()
            .subject(drag().subject)
            .on("start", null)
            .on("drag", null)
            .on("end", null);

        select<HTMLElement, string>(this.ele).call(dragHandler as any);
        this.ele.className = this.ele.className.replace(
            " " + this.className,
            "",
        );
    }

    private get maxTop() {
        const {top} = this.maxBounds();
        if (top === undefined) {
            return -Infinity;
        }

        return top;
    }

    private get maxBottom() {
        const {bottom} = this.maxBounds();
        if (bottom === undefined) {
            return Infinity;
        }

        const height = parseNumber(
            this.ele.style.height.replace("px", "") ?? "0",
        );
        return bottom - height;
    }

    private get maxLeft() {
        const {left} = this.maxBounds();
        if (left === undefined) {
            return -Infinity;
        }

        return left;
    }

    private get maxRight() {
        const {right} = this.maxBounds();
        if (right === undefined) {
            return Infinity;
        }

        const width = parseNumber(
            this.ele.style.width.replace("px", "") ?? "0",
        );
        return right - width;
    }

    @boundMethod
    private onDragStart(e: DragEvent) {
        if (this.onPreventDrag(e)) {
            return;
        }

        const ele = this.ele;

        this.initialTop = parseNumber(ele.style.top.replace("px", "") ?? "0");
        this.initialLeft = parseNumber(ele.style.left.replace("px", "") ?? "0");
        this.dragStartX = e.x;
        this.dragStartY = e.y;
    }

    @boundMethod
    private onDrag(e: DragEvent) {
        if (this.preventDrag) {
            return;
        }

        const ele = this.ele;
        const parent = ele.parentElement;
        if (!parent) {
            return;
        }

        const diffX = this.dragStartX - e.x;
        const diffY = this.dragStartY - e.y;

        const box = parent.getBoundingClientRect();
        ele.style.top =
            Math.max(
                Math.min(this.initialTop - diffY, this.maxBottom - box.top),
                this.maxTop - box.top,
            ) + "px";
        ele.style.left =
            Math.max(
                Math.min(this.initialLeft - diffX, this.maxRight - box.left),
                this.maxLeft - box.left,
            ) + "px";

        if (!this.isDragging) {
            const cursorStyle = document.createElement("style");
            cursorStyle.innerHTML = "*{cursor: grabbing!important;}";
            cursorStyle.id = "cursor-style";
            document.head.appendChild(cursorStyle);
            this.isDragging = true;
        }
    }

    @boundMethod
    private onDragEnd() {
        if (this.isDragging) {
            document.getElementById("cursor-style")?.remove();
        }

        this.isDragging = false;
        this.preventDrag = false;

        const ele = this.ele.getBoundingClientRect();
        const parent = this.ele.parentElement;
        if (!parent) {
            return;
        }

        const box = parent.getBoundingClientRect();
        this.onResize?.({top: ele.top - box.top, left: ele.left - box.left});
    }

    private onPreventDrag(e: DragEvent) {
        const target = (e as TryGetLockOptions).sourceEvent!
            .target as HTMLElement;

        if (
            target.id.includes("no-drag") ||
            target instanceof HTMLInputElement
        ) {
            this.preventDrag = true;
            return true;
        }
    }
}

export default Draggable;
