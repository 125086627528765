import {IntlShape} from "react-intl";

import {ICsvData, ICsvRow} from "./models";

import {blob2ClipBoard, download} from "@/services/download";
import {intl2Num} from "@translate/T";

function createLine(row: ICsvRow, seperator: string) {
    return row
        .map((x) => (!x.length ? "" : '"' + x.replace(/"/g, "'") + '"'))
        .join(seperator);
}

export function getSeperator(intl: IntlShape) {
    return intl2Num(intl, 1.1, 1).includes(",") ? ";" : ",";
}

/**
 * Downloads CSV data as file download.
 * @param name filename, excluding .csv extension.
 * @param data CSV data to download.
 * @param toClipBoard If true, we send data to clipboard.
 */
export function downloadCsv(
    intl: IntlShape,
    name: string,
    data: ICsvData,
    toClipBoard: boolean = false,
) {
    const seperator = getSeperator(intl);
    const content = data.map((x) => createLine(x, seperator)).join("\r\n");

    if (toClipBoard) {
        blob2ClipBoard(content);
        return;
    }

    download(`${name}.csv`, content, "text/csv");
}
