import {IntlShape} from "react-intl";

import {IStalableValue} from "@chart/models";
import {ILocalizedText} from "@translate/models";
import BasicServicesRegistry from "../../services";
import BarGraphScaleService from "../../services/other/bargraph-scale";
import {AbstractCompareDisplayService} from "../../services/other/compare-display";
import {IGroupValue} from "../../services/other/models";

export interface IColorEventHandler {
    colorsChanged?(): void;
}

export interface IColorProvider {
    getColors(): string[];
    subscribe(handler: IColorEventHandler): () => void;
}

export interface IBarData {
    thenBys: IGroupValue[];
    groupBys: IGroupValue[];

    id: string;
    values: IBarValue[];
    unit: ILocalizedText;
    decimals: number;
    preLabel?: ILocalizedText[];
}

export interface IBarValue extends IStalableValue {
    error: number[];
    value: number[];

    groupBy: IGroupValue;
    thenBy?: IGroupValue;
}

export interface ITooltip {
    preName?: ILocalizedText;
    name: ILocalizedText;
    unit: ILocalizedText;
    value: ILocalizedText;
}

export interface IBar {
    color: string;
    id: string;
    baseline: number;

    height: number;
    width: number;
    x: number;
    y: number;
}

export interface IValueText {
    id: string;
    color: string;

    x: number;
    y: number;
    text: ILocalizedText;
}

export interface IErrorPoint {
    x: number;
    top: number;
    bottom: number;
}

export interface IErrorSeries {
    show: boolean;
    id: string;
    points: IErrorPoint;
    barWidth: number;
}

export type BarGarphRegistry = BasicServicesRegistry & {
    barGraph: BarGraphScaleService;
    compareDisplay: AbstractCompareDisplayService;
};

export type FormatValue = (
    intl: IntlShape,
    value?: number,
    error?: number,
) => string;

export const CLICK = (e: React.MouseEvent<Element, MouseEvent>) =>
    e.ctrlKey && e.shiftKey && e.altKey;
