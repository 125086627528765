import {boundMethod} from "autobind-decorator";
import React from "react";

import sleep from "@/services/sleep";

export async function scroll2View(
    element: HTMLElement | string, // either element or id of element
    focus: boolean = false,
    clickId?: string, // id if something needs to be clicked first
) {
    if (clickId) {
        const advancedTab = document.getElementById(clickId);
        if (advancedTab) {
            advancedTab.click();
            await sleep(2); // time to render
        }
    }

    const ele =
        typeof element === "string"
            ? document.getElementById(element)
            : element;
    ele?.scrollIntoView?.({
        behavior: "smooth",
        block: "center",
        inline: "center",
    });

    if (focus) {
        await sleep(6); // time to scroll
        ele?.focus();
    }
}

export interface IValidatedFormProps {
    suffixId: string;

    dataTestId?: string;
    noFocus?: boolean; // sometimes you do not want focus, as user could "wurst-finger"

    onSubmit(form: HTMLFormElement): void;
    doNotSubmit?(): boolean;
}

interface IValidatedFormState {
    showErrors: boolean;
}

class ValidatedForm extends React.PureComponent<
    IValidatedFormProps,
    IValidatedFormState
> {
    public readonly state: IValidatedFormState = {showErrors: false};

    private readonly form = React.createRef<HTMLFormElement>();

    @boundMethod
    public manualSubmit() {
        this.form.current?.submit();
    }

    @boundMethod
    public async handleSubmit(e: React.FormEvent) {
        const {onSubmit, doNotSubmit} = this.props;
        const form = this.form.current!;

        if (doNotSubmit?.()) {
            return;
        }

        e.preventDefault();

        // trim all inputs that shall not allow " " as a value
        const inputs = form.querySelectorAll<
            HTMLInputElement | HTMLTextAreaElement
        >('input,textarea[id*="trim"]');
        for (const input of inputs) {
            if (input.value.trim() === "") {
                input.value = "";
            }
        }

        if (!form.checkValidity()) {
            this.setState({showErrors: true});

            const error = form.querySelector(
                "input:invalid, select:invalid",
            ) as HTMLInputElement | null;
            if (error) {
                error.focus();
            }

            return;
        }

        const ele: HTMLElement | null =
            form.querySelector(".is-invalid") ??
            form.querySelector("#text-danger") ?? // has to be id, since classname is too widely spread
            form.querySelector(".alert-danger");
        if (ele) {
            this.setState({showErrors: true});
            scroll2View(ele, true);
            return;
        }

        onSubmit(form);
    }

    public componentDidMount() {
        const {noFocus} = this.props;
        if (noFocus) {
            return;
        }

        const firstInput = this.form.current?.querySelector("input");
        if (firstInput) {
            firstInput.focus();
        }
    }

    public render() {
        const {children, dataTestId, suffixId} = this.props;
        const id = "validated-form-" + suffixId;

        return (
            <form
                ref={this.form}
                noValidate={true}
                id={id}
                data-testid={dataTestId ?? id}
                onSubmit={this.handleSubmit}
                className={this.state.showErrors ? "was-validated" : undefined}
            >
                {children}
            </form>
        );
    }
}

export default ValidatedForm;
