import {boundMethod} from "autobind-decorator";
import {parseISO} from "date-fns";
import React from "react";
import {Link} from "react-router-dom";

import http from "@/services/http";
import signal from "@/services/signal";
import {TABLE_PADDING} from "@/styles/_variables";
import {getDocIdInfo} from "./doc-type";
import {
    EDocIdPrefixes,
    IChangedMeasurement,
    IMeasurement,
    IProjectDocumentResponse,
} from "./models";

import Card from "@toolbox/design/Card";
import Time from "@toolbox/design/Time";
import T, {parseNumber} from "@translate/T";
import RecordingIndicator from "./RecordingIndicator";
import {isCalibration} from "./SearchResult";

interface IRecentMeasurementsProps {
    project: number;
    canShowMore: boolean;

    onMoreClicked(): void;
}

interface IRecentMeasurementsState {
    items: IMeasurement[];
}

class RecentMeasurements extends React.PureComponent<
    IRecentMeasurementsProps,
    IRecentMeasurementsState
> {
    public readonly state: IRecentMeasurementsState = {items: []};

    private readonly card = React.createRef<Card>();
    private unsubscribe?: () => void;

    private get linkWidth() {
        const body = this.card.current?.cardWidthNoBorder ?? 0;
        const padding = 2 * TABLE_PADDING;

        return body - padding;
    }

    @boundMethod
    public onMoreClicked(e: React.SyntheticEvent) {
        e.preventDefault();

        this.props.onMoreClicked();
    }

    public async componentDidMount() {
        await this.refresh();

        this.unsubscribe = signal.register({
            measurementsChanged: this.tryRefresh,
        });
    }

    public componentWillUnmount() {
        this.unsubscribe?.();
    }

    public render() {
        const {canShowMore} = this.props;
        const {items} = this.state;
        const forceUpdate = () => this.forceUpdate();
        if (!items.length) {
            return null;
        }

        return (
            <Card
                ref={this.card}
                headerClassName="bg-secondary"
                title={<T>Recent measurements</T>}
                triggerOnSizeChange={forceUpdate}
            >
                <table className="table table-sm table-striped table-borderless table-center">
                    <tbody>{this.renderLinks()}</tbody>
                </table>

                {canShowMore && (
                    <div className="card-footer single-href">
                        <Link to="" onClick={this.onMoreClicked}>
                            <T>More</T>
                        </Link>
                    </div>
                )}
            </Card>
        );
    }

    private renderLinks() {
        return this.state.items.map((x, i) => (
            <tr key={i}>
                <td>
                    <span
                        className="text-dot-overflow span-outside-link"
                        style={{maxWidth: this.linkWidth}}
                    >
                        <Link to={x.url}>{x.name}</Link>
                    </span>
                    <br />
                    <RecordingIndicator
                        completion={parseISO(x.completion)}
                    />{" "}
                    <Time value={parseISO(x.time)} />
                </td>
            </tr>
        ));
    }

    private async refresh() {
        try {
            const {project} = this.props;
            const response = await http
                .get(`/api/projects/${project}/measurements`)
                .json<IProjectDocumentResponse>();

            const items = response.items
                .map((x) => {
                    const id = getDocIdInfo(x.docId, project, isCalibration(x));

                    switch (id.prefix) {
                        case EDocIdPrefixes.CentMeasures:
                        case EDocIdPrefixes.FracMeasures:
                        case EDocIdPrefixes.SpocMeasures:
                            const item: IMeasurement = {
                                completion: x.completion,
                                id: parseNumber(id.id),
                                name: x.name,
                                time: x.created,
                                url: id.url,
                            };
                            return item;

                        default:
                            return null;
                    }
                })
                .filter(Boolean) as IMeasurement[];

            this.setState({items});
        } catch {
            this.setState({items: []});
        }
    }

    @boundMethod
    private tryRefresh(measurements: IChangedMeasurement[]) {
        const {project} = this.props;
        const knowns = this.state.items.map((x) => x.id);

        // update only if there's unknown measurements
        const unknowns = measurements.filter(
            (x) => x.project === project && knowns.indexOf(x.id) < 0,
        );
        if (unknowns.length) {
            this.refresh();
        }
    }
}

export default RecentMeasurements;
