import {ILocalizedText} from "@toolbox/translate/models";

export const HTML_PRINT_KEY = "htmlprint";
export const HTTP_UNLIMITED = "httpunlimited";
export const HTTP_LOGS_KEY = "httplogs";
export const SESSION_KEY = "session";
export const SIGNAL_KEY = "signalupdate";
export const SIGNAL_LOGS_KEY = "signallogs";
export const STORAGE_KEY = "language";
export const WARNING_KEY = "warningalerts";

export enum EExpireReasons {
    LoggedOut,
    Expired,
}

export enum ELoadingStatus {
    Nothing,
    Waiting,
    Success,
    Failed,
    Aborted,
}

// user/group roles (flags)
export enum ERoles {
    None = 0, // no role assigned
    Viewer = 1, // Can view documents
    Recorder = 2, // Can record measurements
    Editor = 4, // can edit documents and delete own documents
    Manager = 8, // can manage project and delete project documents
    Administrator = 16, // can manage users and groups
    GlobalAdministrator = 32, // the global administrator
}

export interface INavLink {
    url: string;
    text: React.ReactNode;
}

export interface ILicenses {
    cent: boolean;
    frac: boolean;
    fuge: boolean;
    reader: boolean;
    sizer: boolean;
    spoc: boolean;
    xray: boolean;
}

export interface ILanguageNotify {
    update: boolean;
    applied: boolean;
}

export interface IIntlLocale<TValue = IRegionId> {
    // country stuff
    id: TValue | "";
    region: ILocalizedText;

    // date stuff, depending on country, but if non selected, depending on language
    locale: globalThis.Locale;

    // language stuff
    language: ILanguageId | ""; // the "code" from locale simplified
    passiv?: boolean; // tells you, if the county is not the first that comes you your mind if you hear the language
    native: ILocalizedText; // add language if you have locale
}

export interface IAcsvError {
    id: number;
    doc?: IDocLink;
    path: string;
    sample?: IDocLink;
}

interface IDocLink {
    docId: string;
    name: string;
    project: number;
}

export type IRegionId =
    | "AO"
    | "AR"
    | "AT"
    | "AU"
    | "BE"
    | "BG"
    | "BR"
    | "CA"
    | "CH"
    | "CL"
    | "CN"
    | "CO"
    | "CZ"
    | "DE"
    | "DK"
    | "DZ"
    | "EG"
    | "ES"
    | "FI"
    | "FR"
    | "GB"
    | "GR"
    | "HK"
    | "HR"
    | "HU"
    | "ID"
    | "IE"
    | "IL"
    | "IN"
    | "IS"
    | "IT"
    | "JP"
    | "KR"
    | "KZ"
    | "LU"
    | "MA"
    | "MX"
    | "MY"
    | "MZ"
    | "NL"
    | "NO"
    | "NZ"
    | "PH"
    | "PL"
    | "PT"
    | "RO"
    | "RS"
    | "RU"
    | "SA"
    | "SE"
    | "SG"
    | "SI"
    | "SK"
    | "TH"
    | "TN"
    | "TR"
    | "TW"
    | "US"
    | "UY"
    | "UZ"
    | "VN"
    | "ZA";

export type ILanguageId =
    | "af"
    | "ar"
    | "bg"
    | "cz"
    | "da"
    | "de"
    | "el"
    | "en"
    | "es"
    | "fi"
    | "fr"
    | "gb"
    | "he"
    | "hi"
    | "hr"
    | "hu"
    | "id"
    | "is"
    | "it"
    | "ja"
    | "kk"
    | "ko"
    | "lb"
    | "ms"
    | "nl"
    | "nn"
    | "pl"
    | "pt"
    | "ro"
    | "ru"
    | "sk"
    | "sl"
    | "sr"
    | "sv"
    | "th"
    | "tr"
    | "uz"
    | "vi"
    | "zh-Hans";
