import {boundMethod} from "autobind-decorator";
import React from "react";

import http from "@/services/http";
import {ERoles} from "@/services/models";
import session from "@/services/session";
import {IQueuedSopModel} from "./models";

import DeleteButton from "@toolbox/button-like/DeleteButton";
import {CancelButton} from "@toolbox/modals/Modal";
import T from "@translate/T";
import SopInfo from "./SopInfo";

interface IManageSopQueueProps {
    items: IQueuedSopModel[];
}

class ManageSopQueue extends React.PureComponent<IManageSopQueueProps> {
    public render() {
        const queue = this.props.items.map((x) => (
            <SopInfo sop={x} key={x.id} />
        ));

        return (
            <React.Fragment>
                <table className="table table-sm table-striped table-borderless sop-queue-table">
                    <thead>
                        <tr>
                            <th>
                                <T>SOP</T>
                            </th>
                            <th>
                                <T>Device</T>
                            </th>
                            <th>
                                <T>Time</T>
                            </th>
                            <th>
                                <T>User</T>
                            </th>
                            {session.hasRole(ERoles.Recorder) && (
                                <th>
                                    <T>Actions</T>
                                </th>
                            )}
                        </tr>
                    </thead>
                    <tbody>{queue}</tbody>
                </table>
                {this.renderFooter()}
            </React.Fragment>
        );
    }

    private renderFooter() {
        if (!session.hasRole(ERoles.Recorder)) {
            return null;
        }

        return (
            <div className="modal-footer">
                <DeleteButton
                    onDelete={this.clear}
                    otherName={<T>Remove all</T>}
                />

                <CancelButton>
                    <T>Close</T>
                </CancelButton>
            </div>
        );
    }

    @boundMethod
    private async clear() {
        const {items} = this.props;
        let success = true;
        for (const {id} of items) {
            try {
                await http.delete(`/api/centrifuge/sop/queue/${id}`);
            } catch {
                success = false;
            }
        }

        return success;
    }
}

export default ManageSopQueue;
