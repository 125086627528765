import React from "react";
import {Link} from "react-router-dom";

import {TABLE_PADDING} from "@/styles/_variables";
import {IProjectModel} from "../models";

import http from "@/services/http";
import Card from "@toolbox/design/Card";
import T from "@translate/T";

interface IRecentProjectsState {
    projects: IProjectModel[];
}

class RecentProjects extends React.PureComponent<{}, IRecentProjectsState> {
    public readonly state: IRecentProjectsState = {projects: []};

    private readonly card = React.createRef<Card>();

    private get linkWidth() {
        const body = this.card.current?.cardWidthNoBorder ?? 0;
        const padding = 2 * TABLE_PADDING;

        return body - padding;
    }

    public componentDidMount() {
        this.retrive();
    }

    public render() {
        const forceUpdate = () => this.forceUpdate();
        if (!this.state.projects.length) {
            return null;
        }

        return (
            <Card
                ref={this.card}
                headerClassName="bg-secondary"
                title={<T>Recent projects</T>}
                triggerOnSizeChange={forceUpdate}
            >
                <table className="table table-sm table-striped table-borderless table-center">
                    <tbody>{this.renderProjects()}</tbody>
                </table>
            </Card>
        );
    }

    private renderProjects() {
        return this.state.projects.map((x, i) => (
            <tr key={i}>
                <td className={"d-flex" + (x.isHidden ? " bg-tertiary" : "")}>
                    <span
                        className="text-dot-overflow"
                        style={{maxWidth: this.linkWidth}}
                    >
                        <Link to={"/project/" + x.id}>{x.name}</Link>
                    </span>
                </td>
            </tr>
        ));
    }

    private async retrive() {
        try {
            const projects = await http
                .get("/api/recent-projects", {cache: "no-cache"})
                .json<IProjectModel[]>();

            this.setState({projects});
        } catch {
            this.setState({projects: []});
        }
    }
}

export default RecentProjects;
