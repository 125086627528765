import {boundMethod} from "autobind-decorator";
import React from "react";
import {IntlShape} from "react-intl";

import hardDriveSpace from "@/services/hard-drive-space";
import {FOOTER_VALUE} from "./models";

import WarningAlert from "@toolbox/design/WarningAlert";
import {intl2Num, intl2Str} from "@translate/T";

interface IDiskSpaceWarningState {
    value: number;
}

class DiskSpaceWarning extends React.PureComponent<{}, IDiskSpaceWarningState> {
    public readonly state: IDiskSpaceWarningState = {
        value: hardDriveSpace.value,
    };

    private unsubscribe?: () => void;

    public componentDidMount() {
        this.unsubscribe = hardDriveSpace.subscribe({
            hardDriveSpaceChanged: (value) => this.setState({value}),
        });
    }

    public componentWillUnmount() {
        this.unsubscribe?.();
    }

    public render() {
        if (this.state.value > FOOTER_VALUE) {
            return null;
        }

        return <WarningAlert renderMessage={this.renderMessage} />;
    }

    @boundMethod
    private renderMessage(intl: IntlShape) {
        return intl2Str(
            intl,
            "Server has {value} GB of free space left, should be at least 10 GB, please contact your administrator.",
            {value: intl2Num(intl, this.state.value, 1)},
        );
    }
}

export default DiskSpaceWarning;
