import {EModules} from "@/models";
import {ISop} from "@shared/sop/models";
import {IPagedList} from "@toolbox/building-blocks/models";

export enum ESortFields {
    None = "",
    Name = "name",
    Saved = "saved",
}

export interface ITemplateNameId extends ITemplateName {
    id: number;
}

export interface ITemplateName {
    name: string;
}

export interface ITemplateModel extends ITemplateNameId {
    engine: EModules;
    modified: string;
}

export interface IRegularAndQueueSop extends ISop {
    queue: boolean;
}

export interface ISopListResponse {
    gotAllSop: boolean;
    sops: IRegularAndQueueSop[];
}

export type ITemplateResponse = IPagedList<ITemplateModel>;
