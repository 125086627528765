import {
    faCompressArrowsAlt,
    faExpandArrowsAlt,
    faTimes,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "bootstrap/js/src/modal";

import {boundMethod} from "autobind-decorator";
import $ from "jquery";
import React from "react";
import ReactDOM from "react-dom";

import T from "@translate/T";

export const CancelButton: React.FunctionComponent = (props) => (
    <button
        type="button"
        data-dismiss="modal"
        className="btn btn-secondary"
        id="close-modal"
    >
        <FontAwesomeIcon icon={faTimes} fixedWidth={true} className="mr-1" />
        {props.children ?? <T>Cancel</T>}
    </button>
);

let container: HTMLDivElement;
function getModalContainer() {
    if (container) {
        return container;
    }

    container = document.createElement("div");
    container.setAttribute("id", "modal-root");
    document.body.appendChild(container);

    return container;
}

interface IModalProps {
    dialogClassName?: string;
    contentClassName?: string;
    headerClassName?: string;
    dialogStyle?: React.CSSProperties | undefined;
    contentStyle?: React.CSSProperties | undefined;

    canFullscreen?: boolean;
    noAutoFocus?: boolean;
    cannotLeave?: boolean;
    notCentered?: boolean;
    header?: JSX.Element | string;
    size: "sm" | "md" | "lg" | "xl";

    afterClose?(): void;
    onFullscreen?(): void;
}

class Modal extends React.PureComponent<IModalProps> {
    private readonly root = React.createRef<HTMLDivElement>();
    private readonly content = React.createRef<HTMLDivElement>();
    private readonly header = React.createRef<HTMLDivElement>();

    public get isFullscreen() {
        return (
            this.content.current !== null &&
            this.content.current === document.fullscreenElement
        );
    }

    public componentDidMount() {
        const {noAutoFocus} = this.props;
        const root = this.root.current!;

        const root$ = $(root);
        root$.on("hidden.bs.modal", () => this.props.afterClose?.());

        if (!noAutoFocus) {
            root$.on("shown.bs.modal", () => {
                const firstInput = root.querySelector("input");
                if (firstInput) {
                    firstInput.focus();
                }
            });
        }

        root$.modal({focus: !noAutoFocus});
    }

    public componentWillUnmount() {
        $(this.root.current!)
            .removeClass("fade")
            .off("shown.bs.modal")
            .off("hidden.bs.modal")
            .modal("hide")
            .modal("dispose");
    }

    @boundMethod
    public getHeader() {
        return this.header.current;
    }

    // triggers the modal close animation
    @boundMethod
    public triggerClose() {
        const root = this.root.current;
        if (!root) {
            return;
        }

        $(root).modal("hide");
    }

    @boundMethod
    public async requestFullscreen() {
        await this.content.current?.requestFullscreen();
    }

    @boundMethod
    public async abortFullscreen() {
        await document.exitFullscreen();
    }

    @boundMethod
    public async onFullscreen(e: React.SyntheticEvent) {
        e.preventDefault();

        const {onFullscreen} = this.props;
        if (this.isFullscreen) {
            await this.abortFullscreen();
            onFullscreen?.();
            return;
        }

        await this.requestFullscreen();
        onFullscreen?.();
    }

    public render() {
        const {
            children,
            cannotLeave,
            contentClassName,
            contentStyle,
            dialogClassName,
            dialogStyle,
            notCentered,
            size,
        } = this.props;
        const backdrop = cannotLeave ? "static" : "true";
        const keyboard = cannotLeave ? "false" : "true";
        let _dialogClassName =
            `modal-dialog modal-${size}` +
            (dialogClassName ? " " + dialogClassName : "");
        const _contentClassName =
            "modal-content can-go-fullscreen" +
            (contentClassName ? " " + contentClassName : "");

        if (!notCentered) {
            _dialogClassName += " modal-dialog-centered";
        }

        return ReactDOM.createPortal(
            <div
                ref={this.root}
                id="root"
                className="modal fade p-0"
                data-backdrop={backdrop}
                data-keyboard={keyboard}
                tabIndex={-1}
                role="dialog"
            >
                <div
                    className={_dialogClassName}
                    style={dialogStyle}
                    role="document"
                >
                    <div
                        ref={this.content}
                        id="modal-content"
                        className={_contentClassName}
                        style={contentStyle}
                    >
                        {this.renderHeader()}
                        {children}
                    </div>
                </div>
            </div>,
            getModalContainer(),
        );
    }

    private renderHeader() {
        const {header, headerClassName} = this.props;
        if (!header) {
            return null;
        }

        let className = "modal-header align-items-center bg-secondary";
        if (headerClassName) {
            if (headerClassName.includes("bg-")) {
                className = `card-header has-buttons ${headerClassName}`;
            } else {
                className += ` ${headerClassName}`;
            }
        }

        return (
            <div ref={this.header} className={className}>
                <h5 className="modal-title mb-0">{header}</h5>
                {this.renderButton()}
            </div>
        );
    }

    private renderButton() {
        const {cannotLeave, canFullscreen} = this.props;

        return (
            <div className="d-flex">
                {canFullscreen && (
                    <button
                        type="button"
                        className="close"
                        onClick={this.onFullscreen}
                    >
                        <FontAwesomeIcon
                            id="fullscreen-never"
                            icon={faExpandArrowsAlt}
                            fixedWidth={true}
                            size="2xs"
                        />
                        <FontAwesomeIcon
                            id="fullscreen-only"
                            icon={faCompressArrowsAlt}
                            fixedWidth={true}
                            size="2xs"
                        />
                    </button>
                )}
                {!cannotLeave && (
                    <button
                        type="button"
                        className="close"
                        aria-label="close"
                        data-dismiss="modal"
                    >
                        <FontAwesomeIcon
                            icon={faTimes}
                            fixedWidth={true}
                            size="sm"
                        />
                    </button>
                )}
            </div>
        );
    }
}

export default Modal;
