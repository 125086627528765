import {debounce} from "lodash";

import {IDLE_DELAY} from "@toolbox/models";

/** time multiplied by 250ms */
export default async function sleep(time: number, useMilli: boolean = false) {
    const duration = useMilli ? time : time * IDLE_DELAY;
    const wait = debounce((resolve) => resolve(), duration);
    await new Promise((resolve) => wait(resolve));
}

const triggers: (() => void)[] = [];

export async function sleepingBeauty() {
    const long = debounce((resolve) => resolve(), 14400);
    const short = debounce((resolve) => resolve(), IDLE_DELAY);
    await new Promise((resolve) => {
        // sleep for n hour
        long(resolve);
        triggers.push(() => {
            long.cancel();
            short(resolve);
        });
    });
}

export async function princePhillip() {
    for (const trigger of triggers) {
        trigger();
    }

    await sleep(1);
}
