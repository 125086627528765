import "./ScrollableTable.module.scss";

import {boundMethod} from "autobind-decorator";
import React from "react";

import resize from "@/services/resize";

export interface IScrollableTableProps {
    className: string;
    thead: JSX.Element;
    tbody: JSX.Element;
}

// in order to add a scrollbar to table body, we had to create the 2nd table
class ScrollableTable extends React.PureComponent<IScrollableTableProps> {
    private readonly div = React.createRef<HTMLTableElement>();
    private readonly thead = React.createRef<HTMLTableElement>();
    private readonly tbody = React.createRef<HTMLTableElement>();

    private unsubscribe?: () => void;

    public componentDidMount() {
        this.updateHeader();

        this.unsubscribe = resize.subscribe(
            this.tbody.current!,
            this.updateHeader,
        );
    }

    public componentDidUpdate() {
        this.updateHeader();
    }

    public componentWillUnmount() {
        this.unsubscribe?.();
    }

    public render() {
        const {className, thead, tbody} = this.props;

        return (
            <React.Fragment>
                <table ref={this.thead} className={className}>
                    {thead}
                </table>

                <div ref={this.div} className="border border-top-0">
                    <div className="div-overflow-y" styleName="div-overflow">
                        <table ref={this.tbody} className={className}>
                            {tbody}
                        </table>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    @boundMethod
    private updateHeader() {
        let fullWidth = this.div.current?.clientWidth ?? 0;
        const thead =
            this.thead.current?.childNodes[0]?.childNodes[0]?.childNodes;
        const tbody =
            this.tbody.current?.childNodes[0]?.childNodes[0]?.childNodes;

        if (thead === undefined || tbody === undefined) {
            return;
        }

        const len = tbody.length;
        for (let i = 0; i < len - 1; i++) {
            const width = (tbody[i] as HTMLElement).clientWidth;
            fullWidth -= width;
            (thead[i] as HTMLElement).style.width = width + "px";
        }

        // last element need full width
        (thead[len - 1] as HTMLElement).style.width = fullWidth + "px";
    }
}

export default ScrollableTable;
