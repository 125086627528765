import {IDeviceId} from "@toolbox/models";

export enum ESampleColorSchemes {
    OldSchool,
    Modern,
    Retro,
    Grayscale,
}

export enum EDataColorSchemes {
    RdGn,
    RdYlGn,
    Viridis,
    Inferno,
    Turbo,
    ColorBlind,
    Warm,
    Plasma,
    Sinebow,
}

export enum EDetailedFingerprint {
    Simple,
    Detailed,
}

export enum EConnection {
    Ethernet,
    Usb,
    Both,
}

export enum EPressureUnits {
    Pa,
    Psi,
    Nmm2,
}

// exponent of 10^x
export enum EPascal {
    µPa = -6,
    Pa = 0,
    KPa = 3,
    MPa = 6,
    GPa = 9,
}

export enum EFingerprintOrientations {
    Auto,
    Landscape,
    Portrait,
}

export enum ESmoothing {
    None,
    Average3,
    Average5,
    Average9,
    SavitzkyGolay,
}

export interface IDeviceConnection extends Omit<IDeviceId, "fabrication"> {
    connection: EConnection;
}
