import {boundMethod} from "autobind-decorator";
import React from "react";
import {injectIntl, IntlShape} from "react-intl";

import resize from "./services/resize";

import FooterExtra from "@/components/info-global/FooterExtra";
import {getLongDate} from "@toolbox/design/Time";
import {intl2Str, parseNumber} from "@translate/T";

export function getSepviewVersion() {
    return "SEPView" + trademark() + " " + APP_VERSION;
}

export function trademark() {
    return "\u00ae";
}

interface IFooterProps {
    intl: IntlShape;
}

interface IFooterState {
    fixed: string;
}

class Footer extends React.PureComponent<IFooterProps, IFooterState> {
    public readonly state: IFooterState = {fixed: ""};

    private readonly footer = React.createRef<HTMLDivElement>();

    private unsubscribe?: () => void;

    public componentDidMount() {
        this.unsubscribe = resize.subscribe(document.body, this.calcFixed);
        this.calcFixed();
    }

    public componentWillUnmount() {
        this.unsubscribe?.();
    }

    public render() {
        const {fixed} = this.state;

        return (
            <footer
                ref={this.footer}
                className={`container-fluid${fixed} pb-2`} // padding, hence it will be counted towards height
            >
                <hr className="mt-0" />
                {this.renderFooter()}
            </footer>
        );
    }

    private renderFooter() {
        const {intl} = this.props;

        return (
            <FooterExtra>
                <span
                    title={
                        getLongDate(intl, new Date(BUILD_TIME)) +
                        " / " +
                        BUILD_HASH
                    }
                >
                    {getSepviewVersion()}
                </span>
                <br />
                {intl2Str(intl, "Copyright {build}", {build: BUILD_YEAR})}
                <React.Fragment> &copy; </React.Fragment>
                <a
                    href="http://www.lum-gmbh.com/"
                    rel="noreferrer"
                    target="_blank"
                >
                    LUM GmbH
                </a>
            </FooterExtra>
        );
    }

    @boundMethod
    private calcFixed() {
        let _body = document.body.clientHeight;
        let _margin = 0; // margin of body of container-col
        const _window = window.innerHeight;
        const _footer = this.footer.current?.clientHeight ?? 0;

        const container = document.getElementById("container-col");
        if (container) {
            const bottom = window.getComputedStyle(container).marginBottom;
            _margin = parseNumber(bottom.replace("px", ""));
            _margin -= 16; // substract 16px, cause of <hr/> margin, which is overlapping
        }

        // if no classname, meaning just plain footer, substract it from body
        if (!this.state.fixed) {
            _body -= _footer;
        }

        const fixed =
            _window > _body + _margin + _footer ? " fixed-bottom" : "";
        this.setState({fixed});
    }
}

export default injectIntl(Footer);
