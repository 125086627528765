import React from "react";

interface IContainerColProps {
    col?: 4 | 6 | 8 | 10;
}

class ContainerCol extends React.PureComponent<IContainerColProps> {
    public render() {
        return <div className="container-fluid">{this.col()}</div>;
    }

    private col() {
        const {col, children} = this.props;

        if (!col) {
            return children;
        }

        const xl = col;
        const lg = Math.min(12, col + 2);
        const md = Math.min(12, lg + 2);
        const sm = Math.min(12, md + 2);

        const xlCol = `col-xl-${xl} offset-xl-${(12 - xl) / 2}`;
        const lgCol = `col-lg-${lg} offset-lg-${(12 - lg) / 2}`;
        const mdCol = `col-md-${md} offset-md-${(12 - md) / 2}`;
        const smCol = `col-sm-${sm} offset-sm-${(12 - sm) / 2}`;

        return (
            <div
                id="container-col" // id has to match Footer.tsx => this.calcFixed()
                className={`form-row my-${col >= 8 ? "3" : "5"}`}
            >
                <div className={`${xlCol} ${lgCol} ${mdCol} ${smCol}`}>
                    {children}
                </div>
            </div>
        );
    }
}

export default ContainerCol;
