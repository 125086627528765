import "./DirectGroup.module.scss";

import {boundMethod} from "autobind-decorator";
import React from "react";

import {ECheckStates} from "@toolbox/button-like/models";
import {ISample} from "./models";

import TristateCheckbox from "@toolbox/button-like/TristateCheckbox";
import SampleWithTimestamp from "./SampleWithTimestamp";

interface IDirectGroupProps {
    samples: ISample[];
    toggle(checked: boolean, id: string | string[]): void;
}

class DirectGroup extends React.PureComponent<IDirectGroupProps> {
    private get checkState(): ECheckStates {
        const {samples} = this.props;
        const checked = samples.filter((x) => x.selected);
        switch (checked.length) {
            case 0:
                return ECheckStates.NotChecked;

            case samples.length:
                return ECheckStates.Checked;

            default:
                return ECheckStates.Indeterminate;
        }
    }

    @boundMethod
    public onSelfChanged(checked: boolean) {
        const {toggle, samples} = this.props;

        toggle(
            checked,
            samples.map((x) => x.id),
        );
    }

    public render() {
        const {samples, children} = this.props;
        const id = samples[0].id + "-group";

        return (
            <div className="form-check">
                <TristateCheckbox
                    suffixId={id}
                    value={this.checkState}
                    onChange={this.onSelfChanged}
                />
                <label
                    className="form-check-label"
                    styleName="item-name"
                    htmlFor={id}
                >
                    {children}
                </label>
                <ul className="list-unstyled">{this.renderNested()}</ul>
            </div>
        );
    }

    private renderNested() {
        const {samples, toggle} = this.props;

        return samples.map((x, i) => (
            <SampleWithTimestamp key={i} sample={x} toggle={toggle} />
        ));
    }
}

export default DirectGroup;
