import {boundMethod} from "autobind-decorator";
import React from "react";
import {Redirect, Route, RouteComponentProps, Switch} from "react-router";

import {ERoles} from "@/services/models";
import session from "@/services/session";

import AccessDenied from "@toolbox/render-page/AccessDenied";
import T, {parseNumber} from "@translate/T";
import ManageProject from "./ManageProject";
import ProjectView from "./ProjectView";
import ProjectsList from "./list/Index";

class Project extends React.PureComponent {
    public render() {
        return (
            <Switch>
                <Route
                    path="/project/:id/edit"
                    render={this.renderManageProject}
                />
                <Route path="/project/:id" render={this.renderProject} />
                <Route path="/alignments" render={this.renderAlignment} />

                <Route component={ProjectsList} />
            </Switch>
        );
    }

    @boundMethod
    private renderProject(props: RouteComponentProps<{id?: string}>) {
        return this.renderWithId(props, (id) => {
            if (
                !session.hasRole(ERoles.Viewer, id) &&
                !session.hasRole(ERoles.Recorder, id) &&
                !session.hasRole(ERoles.Editor, id) &&
                !session.hasRole(ERoles.Manager, id)
            ) {
                return <AccessDenied reason={<T>view this project</T>} />;
            }

            return <ProjectView id={id} baseUrl={props.match.url} />;
        });
    }

    @boundMethod
    private renderAlignment(props: RouteComponentProps) {
        if (!session.hasRole(ERoles.Administrator)) {
            return <AccessDenied reason={<T>view alignments</T>} />;
        }

        return <ProjectView id={NaN} baseUrl={props.match.url} />;
    }

    @boundMethod
    private renderManageProject(props: RouteComponentProps<{id?: string}>) {
        return this.renderWithId(props, (id) => {
            if (!session.hasRole(ERoles.Manager, id)) {
                return <AccessDenied reason={<T>manage this project</T>} />;
            }

            return <ManageProject id={id} />;
        });
    }

    private renderWithId(
        props: RouteComponentProps<{id?: string}>,
        render: (id: number) => JSX.Element,
    ) {
        const id = parseNumber(props.match.params.id ?? "");
        if (isNaN(id) || id <= 0) {
            return <Redirect to="/" push={true} />;
        }

        return render(id);
    }
}

export default Project;
