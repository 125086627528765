import {kebabCase} from "lodash";
import React from "react";
import {IntlShape, injectIntl} from "react-intl";

import {
    DEFAULT_ENERGY_DECIMAL,
    DEFAULT_TEMPERATURE_DECIMAL,
    DEFAULT_WAVELENGTH_DECIMAL,
} from "@toolbox/display-blocks/models";
import {ILocalizedText} from "@translate/models";
import {EPropertyTypes, IMaterialFunctionModel} from "../../models";

import {getDisplayLabel} from "@chart/chart-labels";
import InlineCheckbox from "@toolbox/button-like/InlineCheckbox";
import CustomizeLabel from "@toolbox/display-blocks/CustomizeLabel";
import {
    displayWavelength,
    displayWavelengthLabel,
    getPicoNumberForBoolean,
} from "@toolbox/display-blocks/Wavelength";
import {kev2Nm} from "@toolbox/functions/units/length";
import NumberInput from "@toolbox/nativ-inputs/NumberInput";
import T, {getDecimal, intl2Str, parseNumber} from "@translate/T";
import {
    getEnergyWavelength,
    getFormulaUnit,
    usesEnergy,
    usesTemperature,
    usesWavelength,
} from "../FunctionRow";

interface IMaterialInputsProps extends IMaterialFunctionModel {
    intl: IntlShape;
    type: EPropertyTypes;

    massCoeff?: string;
    sync: boolean;
    isValid: boolean;

    setFormula(e: React.ChangeEvent<HTMLInputElement>): void;
    setNotes(e: React.ChangeEvent<HTMLTextAreaElement>): void;
    setMassCoeff(value: number | null): void;
    setMassCoeffCheck(checked: boolean): void;
    setNumberValue(value: number, id: string): void;
}

class MaterialInputs extends React.PureComponent<IMaterialInputsProps> {
    public render() {
        const {type} = this.props;
        const useTemperature = usesTemperature(type);
        const useWavelength = usesWavelength(type);
        const useEnergy = usesEnergy(type);

        return (
            <React.Fragment>
                {this.renderFunction(useTemperature, useWavelength, useEnergy)}
                {this.renderExtraLabel()}
                {this.renderTemperaturRange(useTemperature)}
                {this.renderWavelengthRange(useWavelength)}
                {this.renderComments()}
            </React.Fragment>
        );
    }

    private renderFunction(
        useTemperature: boolean,
        useWavelength: boolean,
        useEnergy: boolean,
    ) {
        const {intl, type, setFormula, isValid} = this.props;
        let {formula} = this.props;

        // translate number to choosen language
        formula = formula.replace(/\./g, getDecimal(intl));
        if (useEnergy) {
            formula = formula.replace(/L/g, "E");
        }

        return (
            <CustomizeLabel
                label={
                    <React.Fragment>
                        <T>Function</T>
                        {getFormulaUnit(type)}
                    </React.Fragment>
                }
                htmlFor="function"
                labelNumber={3}
            >
                <input
                    type="text"
                    id="function"
                    className={"form-control" + (!isValid ? " is-invalid" : "")}
                    required={true}
                    value={formula}
                    onChange={setFormula}
                />
                {this.renderFormulaNotes(useTemperature, useWavelength)}
            </CustomizeLabel>
        );
    }

    private renderFormulaNotes(
        useTemperature: boolean,
        useWavelength: boolean,
    ) {
        let prompt: React.JSX.Element = <React.Fragment />;
        if (useTemperature && useWavelength) {
            prompt = <T>Use T for temperature, L for wavelength.</T>;
        } else if (useTemperature) {
            prompt = <T>Use T for temperature.</T>;
        } else if (useWavelength) {
            prompt = <T>Use E for energie.</T>;
        }

        return <span className="form-text text-muted">{prompt}</span>;
    }

    private renderExtraLabel() {
        const {massCoeff, sync, setMassCoeff, setMassCoeffCheck} = this.props;
        if (massCoeff === undefined) {
            return null;
        }

        const title: ILocalizedText = (intl) =>
            intl2Str(
                intl,
                "Based on material density, assuming 20° C as temperature",
            );

        return (
            <CustomizeLabel
                label={
                    <React.Fragment>
                        <T>Mass coefficient</T>
                        {getFormulaUnit(EPropertyTypes.MassCoefficient)}
                    </React.Fragment>
                }
                htmlFor={kebabCase("massCoeff")}
                labelNumber={3}
            >
                <div className="form-row ml-0">
                    <NumberInput
                        id="massCoeff"
                        className="col"
                        decimals={4}
                        inputOnly={true}
                        noTrailingZero={true}
                        optional={true}
                        value={!!massCoeff ? parseNumber(massCoeff) : null}
                        onChange={setMassCoeff}
                    />

                    <InlineCheckbox
                        idSuffix="mass-coeff-sync"
                        className="col-auto m-0"
                        checked={sync}
                        title={title}
                        toggle={setMassCoeffCheck}
                    >
                        <T>Keep in sync</T>
                    </InlineCheckbox>
                </div>
            </CustomizeLabel>
        );
    }

    private renderTemperaturRange(useTemperature: boolean) {
        const {maxTemperature, minTemperature, setNumberValue} = this.props;
        if (
            !useTemperature ||
            maxTemperature === undefined ||
            minTemperature === undefined
        ) {
            return null;
        }

        const invalid = minTemperature > maxTemperature;

        return (
            <div className="form-row mb-2">
                <CustomizeLabel
                    label={getDisplayLabel({
                        name: (intl) => intl2Str(intl, "Minimum temperature"),
                        unit: (intl) => intl2Str(intl, "°C"),
                    })}
                    htmlFor={kebabCase("minTemperature")}
                    half={true}
                >
                    <NumberInput
                        id="minTemperature"
                        decimals={DEFAULT_TEMPERATURE_DECIMAL}
                        invalid={invalid}
                        min={-273}
                        max={500}
                        value={minTemperature}
                        onChange={setNumberValue}
                    />
                </CustomizeLabel>

                <CustomizeLabel
                    label={getDisplayLabel({
                        name: (intl) => intl2Str(intl, "Maximum temperature"),
                        unit: (intl) => intl2Str(intl, "°C"),
                    })}
                    htmlFor={kebabCase("maxTemperature")}
                    half={true}
                >
                    <NumberInput
                        id="maxTemperature"
                        decimals={DEFAULT_TEMPERATURE_DECIMAL}
                        invalid={invalid}
                        min={-273}
                        max={500}
                        value={maxTemperature}
                        onChange={setNumberValue}
                    />
                </CustomizeLabel>
            </div>
        );
    }

    private renderWavelengthRange(useWavelength: boolean) {
        const {type, setNumberValue} = this.props;
        const {maxWavelength, minWavelength} = this.props;

        if (
            !useWavelength ||
            maxWavelength === undefined ||
            minWavelength === undefined
        ) {
            return null;
        }

        const useEnergy = usesEnergy(type);
        const [min, max] = useEnergy ? [kev2Nm(1), kev2Nm(0.01)] : [0.01, 2000];
        const usePico = getPicoNumberForBoolean(useEnergy);
        const decimals = useEnergy
            ? DEFAULT_ENERGY_DECIMAL
            : DEFAULT_WAVELENGTH_DECIMAL;
        const minValue = getEnergyWavelength(minWavelength, type);
        const maxValue = getEnergyWavelength(maxWavelength, type);
        const invalid = minValue > maxValue;

        return (
            <div className="form-row mb-2">
                <CustomizeLabel
                    label={getDisplayLabel({
                        name: (intl) =>
                            intl2Str(intl, "Minimum {wavelength}", {
                                wavelength: displayWavelengthLabel(
                                    intl,
                                    usePico,
                                ),
                            }),
                        unit: (intl) => displayWavelength(intl, usePico, -1),
                    })}
                    htmlFor={kebabCase("minWavelength")}
                    half={true}
                >
                    <NumberInput
                        id="minWavelength"
                        decimals={decimals}
                        invalid={invalid}
                        min={min}
                        max={max}
                        value={minValue}
                        onChange={setNumberValue}
                    />
                </CustomizeLabel>

                <CustomizeLabel
                    label={getDisplayLabel({
                        name: (intl) =>
                            intl2Str(intl, "Maximum {wavelength}", {
                                wavelength: displayWavelengthLabel(
                                    intl,
                                    usePico,
                                ),
                            }),
                        unit: (intl) => displayWavelength(intl, usePico, -1),
                    })}
                    htmlFor={kebabCase("maxWavelength")}
                    half={true}
                >
                    <NumberInput
                        id="maxWavelength"
                        decimals={decimals}
                        invalid={invalid}
                        min={min}
                        max={max}
                        value={maxValue}
                        onChange={setNumberValue}
                    />
                </CustomizeLabel>
            </div>
        );
    }

    private renderComments() {
        const {notes, setNotes} = this.props;

        return (
            <CustomizeLabel
                label={<T>Source / Comments</T>}
                htmlFor="formula-notes"
                labelNumber={3}
            >
                <textarea
                    id="formula-notes"
                    className="form-control"
                    required={true}
                    value={notes}
                    onChange={setNotes}
                />
            </CustomizeLabel>
        );
    }
}

export default injectIntl(MaterialInputs);
