// workaround for every svg-download for Firefox
export function isFirefox() {
    return window.navigator.userAgent.includes("Firefox");
}

export function isRecorder() {
    return window.location.pathname.includes("/recorder");
}

export function isHttps() {
    return window.location.origin.includes("https://");
}
