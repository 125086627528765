import {
    faCheckCircle,
    faCircleNotch,
    faClock,
    faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import React from "react";
import {IntlShape, injectIntl} from "react-intl";
import {Link} from "react-router-dom";

import {EDocumentTypes} from "@/components/project/models";
import {EDeviceClasses} from "@toolbox/models";
import {EServerId} from "../shared/models";
import {EImportProgress, EMissingImport, IImportFile} from "./models";

import {sopLicenseDisplayName} from "@/components/license/SopFeatures";
import {bitwiseAND} from "@toolbox/functions/bitwise";
import {device2ServerId} from "@toolbox/functions/device-check";
import T from "@translate/T";

export function getDocumentUrl(
    id: string | number,
    project: number,
    type: EDocumentTypes,
    deviceClass?: EDeviceClasses,
): string | null {
    const serverApi = device2ServerId(deviceClass);

    switch (type) {
        case EDocumentTypes.Unknown:
            return null;

        case EDocumentTypes.Project:
            return "/project/" + id;

        case EDocumentTypes.User:
            return "/admin/users/edit/" + id;

        case EDocumentTypes.Group:
            return "/admin/groups/edit/" + id;

        case EDocumentTypes.Stamp:
            return `/stamps/edit/${id}`;

        case EDocumentTypes.Material:
            return `/materials/edit/${id}`;

        case EDocumentTypes.Hindrance:
            return `/hindrance/edit/${id}`;

        case EDocumentTypes.Template:
            return `/templates/edit/${id}`;

        case EDocumentTypes.Sop:
            return `/project/${project}/${serverApi}/sop/${id}`;

        case EDocumentTypes.Measurement:
            return `/project/${project}/${serverApi}/measurement/${id}`;

        case EDocumentTypes.Analysis:
            return `/project/${project}/${serverApi}/analysis/${id}`;

        case EDocumentTypes.Report:
            return `/project/${project}/${serverApi}/report/${id}`;

        case EDocumentTypes.Alignment:
            return `/alignments/${EServerId.Spoc}/alignment/${id}`;
    }
}

interface IFileStatusProps {
    intl: IntlShape;
    file: IImportFile;
    project: number;
}

class FileStatus extends React.PureComponent<IFileStatusProps> {
    public render() {
        return (
            <div className="list-group-item">
                <div className="d-flex w-100 justify-content-between">
                    <h5 className="mb-1">{this.props.file.name}</h5>
                    {this.renderIcon()}
                </div>

                {this.renderDocs()}
                {this.renderStatus()}
                {this.renderMaterialInfo()}
                {this.renderMissingMaterials()}
                {this.renderMissingSopLicenses()}
                {this.renderMissingEnum()}
            </div>
        );
    }

    private renderIcon() {
        switch (this.props.file.progress) {
            default:
            case EImportProgress.Pending:
                return <FontAwesomeIcon icon={faClock} fixedWidth={true} />;

            case EImportProgress.Processing:
                return (
                    <FontAwesomeIcon
                        icon={faCircleNotch}
                        fixedWidth={true}
                        className="text-primary"
                        spin={true}
                    />
                );

            case EImportProgress.Imported:
                return (
                    <FontAwesomeIcon
                        icon={faCheckCircle}
                        fixedWidth={true}
                        className="text-success"
                    />
                );

            case EImportProgress.NotImported:
            case EImportProgress.FileTooLarge:
                return (
                    <FontAwesomeIcon
                        icon={faExclamationCircle}
                        fixedWidth={true}
                        className="text-danger"
                    />
                );
        }
    }

    private renderDocs() {
        const {file, project} = this.props;
        const docs = file.importedDocs.map((x, i) => {
            const url = getDocumentUrl(x.id, project, x.type, x.deviceClass);

            if (url) {
                return (
                    <li key={i}>
                        <Link to={url}>{x.name}</Link>
                    </li>
                );
            }

            return <li key={i}>{x.name}</li>;
        });

        if (!docs.length) {
            return null;
        }

        // pl-3 to counter ml-5, which is default
        return <ul className="pl-3">{docs}</ul>;
    }

    private renderStatus() {
        switch (this.props.file.progress) {
            case EImportProgress.Pending:
                return (
                    <div>
                        <small className="text-primary">
                            <T>Pending...</T>
                        </small>
                    </div>
                );

            case EImportProgress.Processing:
                return (
                    <div>
                        <small className="text-info">
                            <T>Importing...</T>
                        </small>
                    </div>
                );

            case EImportProgress.NotImported:
                return (
                    <div>
                        <small className="text-danger">
                            <T>File is invalid or could not be imported.</T>
                        </small>
                    </div>
                );

            case EImportProgress.FileTooLarge:
                return (
                    <div>
                        <small className="text-danger">
                            <T>
                                File too large due to upload size limitation.
                                Please contact your IT administrator.
                            </T>
                        </small>
                    </div>
                );
            default:
                return null;
        }
    }

    private renderMaterialInfo() {
        const {file, project} = this.props;
        if (isNaN(project)) {
            return null;
        }

        if (file.importedDocs.find((x) => x.type === EDocumentTypes.Material)) {
            return (
                <div>
                    <small className="text-info">
                        <T>You have imported at least one new material.</T>
                    </small>
                </div>
            );
        }

        if (
            file.importedDocs.find((x) => x.type === EDocumentTypes.Hindrance)
        ) {
            return (
                <div>
                    <small className="text-info">
                        <T>
                            You have imported at least one new Hindrance
                            function.
                        </T>
                    </small>
                </div>
            );
        }

        return null;
    }

    private renderMissingMaterials() {
        const {missingMaterials} = this.props.file;

        if (!missingMaterials?.length) {
            return null;
        }

        return (
            <div>
                <small className="text-warning">
                    <T>Some reference materials are missing:</T>
                    {missingMaterials.map((x, i) => (
                        <li key={i}>{x}</li>
                    ))}
                </small>
            </div>
        );
    }

    private renderMissingSopLicenses() {
        const {intl, file} = this.props;

        if (!file.missingSopLicenses?.length) {
            return null;
        }

        return (
            <div>
                <small className="text-warning">
                    <T>Some licenses are missing:</T>
                    {file.missingSopLicenses.map((x, i) => (
                        <li key={i}>{sopLicenseDisplayName(x)(intl)}</li>
                    ))}
                </small>
            </div>
        );
    }

    private renderMissingEnum() {
        const {missing} = this.props.file;
        if (!missing) {
            return null;
        }

        return Object.values(EMissingImport)
            .filter((x) => typeof x === "number")
            .map((x) =>
                this.getMissing(bitwiseAND(missing, x as EMissingImport)),
            );
    }

    private getMissing(missing: EMissingImport) {
        switch (missing) {
            default:
            case EMissingImport.None:
                return null;

            case EMissingImport.MissingAnalysisData:
                return (
                    <div key={missing}>
                        <small className="text-warning">
                            <T>
                                Note: Your LUM file from SEPView 6 additionally
                                contained one or more analysis. Import of
                                analysis from SEPView 6 is not supported.
                            </T>
                        </small>
                    </div>
                );

            case EMissingImport.DoubledDocument:
                return (
                    <div key={missing}>
                        <small className="text-warning">
                            <T>Document is already existing.</T>
                        </small>
                    </div>
                );
        }
    }
}

export default injectIntl(FileStatus);
