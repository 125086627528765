import {faAnchor, IconDefinition} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import React from "react";

import TasksPanel from "@toolbox/building-blocks/TasksPanel";
import ContainerCol from "@toolbox/display-blocks/ContainerCol";

interface INotFoundProps {
    header: JSX.Element;
    tasks?: JSX.Element;

    extra?: JSX.Element;
    icon?: IconDefinition;
}

class ErrorMessage extends React.PureComponent<INotFoundProps> {
    public render() {
        const {children, header, icon, extra} = this.props;

        return (
            <ContainerCol col={6}>
                <div className="error-message" id="error-message">
                    {this.renderTasks()}

                    <h3>{header}</h3>
                    <hr />

                    <div className="alert alert-danger" role="alert">
                        <FontAwesomeIcon
                            data-testid="icon"
                            icon={icon ?? faAnchor}
                            fixedWidth={true}
                            className="mr-1"
                        />
                        {children}
                    </div>

                    {extra}
                </div>
            </ContainerCol>
        );
    }

    private renderTasks() {
        const {tasks} = this.props;

        if (!tasks) {
            return null;
        }

        return <TasksPanel>{tasks}</TasksPanel>;
    }
}

export default ErrorMessage;
