import {boundMethod} from "autobind-decorator";
import React from "react";
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";

import aCsvError from "@/services/acsv-error";
import {ERoles, IAcsvError} from "@/services/models";
import session from "@/services/session";

import WarningAlert from "@toolbox/design/WarningAlert";
import T from "@translate/T";
import {getDocIdInfo} from "../project/search/doc-type";

interface IACsvErrorWarningState {
    errors: IAcsvError[];
}

class ACsvErrorWarning extends React.PureComponent<{}, IACsvErrorWarningState> {
    public readonly state: IACsvErrorWarningState = {
        errors: aCsvError.errors,
    };

    private unsubscribe?: () => void;

    public componentDidMount() {
        this.unsubscribe = aCsvError.subscribe({
            acsverrorChanged: (errors) => this.setState({errors}),
        });
    }

    public componentWillUnmount() {
        this.unsubscribe?.();
    }

    public render() {
        const {errors} = this.state;
        if (!errors.length) {
            return null;
        }

        return errors.map((error, i) => (
            <WarningAlert key={i} renderElement={this.renderMessage(error)}>
                {this.renderButtons(error)}
            </WarningAlert>
        ));
    }

    private renderMessage(error: IAcsvError) {
        if (!error.doc) {
            return this.renderSOPErrorMessage(error);
        }

        return !error.sample
            ? this.renderMeasurementErrorMessage(error)
            : this.renderSampleErrorMessage(error);
    }

    @boundMethod
    private renderSOPErrorMessage(error: IAcsvError) {
        return (
            <FormattedMessage
                id="Automatic CSV path {path} could not be accessed. If you are unsure, contact your administrator or check support data."
                defaultMessage="Automatic CSV path {path} could not be accessed. If you are unsure, contact your administrator or check support data."
                values={{path: <strong>{error.path}</strong>}}
            />
        );
    }

    @boundMethod
    private renderMeasurementErrorMessage(error: IAcsvError) {
        const {project, docId, name} = error.doc!;
        const url = getDocIdInfo(docId, project).url;

        return (
            <FormattedMessage
                id="Automatic CSV export (A-CSV) for {measurement} encountered an error, concerned path is {path} If you are unsure, please contact your administrator or check support data."
                defaultMessage="Automatic CSV export (A-CSV) for {measurement} encountered an error, concerned path is {path} If you are unsure, please contact your administrator or check support data."
                values={{
                    path: <strong>{error.path}</strong>,
                    measurement: <Link to={url}>{name}</Link>,
                }}
            />
        );
    }

    @boundMethod
    private renderSampleErrorMessage(error: IAcsvError) {
        const {doc, sample} = error;
        const url = getDocIdInfo(doc!.docId, doc!.project).url;
        const urlS = getDocIdInfo(sample!.docId, sample!.project).url;

        return (
            <FormattedMessage
                id="Automatic CSV export (A-CSV) file for {sample} of {measurement} not written yet due to some profiles still missing."
                defaultMessage="Automatic CSV export (A-CSV) file for {sample} of {measurement} not written yet due to some profiles still missing."
                values={{
                    path: <strong>{error.path}</strong>,
                    measurement: <Link to={url}>{doc!.name}</Link>,
                    sample: <Link to={urlS}>{sample!.name}</Link>,
                }}
            />
        );
    }

    private renderButtons(error: IAcsvError) {
        const onDismissClick = () => aCsvError.dismiss(error);
        const onSubmitClick = () => aCsvError.submitAnyway(error);
        if (!session.hasRole(ERoles.Recorder)) {
            return null;
        }

        return (
            <div className="btn-group btn-group-sm ml-1">
                {!!error.sample && (
                    <button
                        type="button"
                        className="btn btn-sm btn-primary"
                        onClick={onSubmitClick}
                    >
                        <T>Write file anyway</T>
                    </button>
                )}
                <button
                    type="button"
                    className="btn btn-sm btn-warning"
                    onClick={onDismissClick}
                >
                    <T>Dismiss</T>
                </button>
            </div>
        );
    }
}

export default ACsvErrorWarning;
