import {DEFAULT_LANGUAGE, ILanguage} from "@/components/navbar/models";
import EventHandlers from "@/services/handlers";
import http from "@/services/http";
import {getIntlLocale} from "./intl-locale";
import intlLocale from "./intlLocale";
import {ILanguageId, ILanguageNotify} from "./models";

import {getTranslator, getValidIntl} from "@translate/Provider";

interface IEventHandler {
    updatesChanged?(update: boolean, applied: boolean): void;
    languagesChanged?(options: ILanguage[]): void;
}

// service to tell if there is a new language online update
class Languages {
    private readonly events = new EventHandlers<IEventHandler>();

    private _update: boolean = false;
    private _applied: boolean = false;
    private _options: ILanguage[] = [DEFAULT_LANGUAGE];

    public get update() {
        return this._update;
    }

    public get applied() {
        return this._applied;
    }

    public get options() {
        return this._options;
    }

    public async retrieve() {
        await this.retrieveUpdates();
        await this.retrieveLanguages();
    }

    public async retrieveLanguages() {
        try {
            const response = await http
                .get("/api/languages")
                .json<ILanguageId[]>();

            const options = await Promise.all(
                response
                    .filter((x) => x === getValidIntl(x)) // filter invalid
                    .map(async (id) => ({
                        id,
                        name: await this.translate(id),
                    })),
            );

            this._options = options;
        } catch {
            // default english
            this._options = [DEFAULT_LANGUAGE];
        }

        this.events.publish((x) => x.languagesChanged?.(this._options));
    }

    public async dismiss(json: Partial<ILanguageNotify>) {
        try {
            const response = await http
                .post("/api/languages/dismiss-update", {json})
                .json<ILanguageNotify>();

            this._update = response.update;
            this._applied = response.applied;
            this.events.publish((x) =>
                x.updatesChanged?.(this._update, this._applied),
            );
        } catch {
            // keep old version
        }
    }

    public subscribe(handler: IEventHandler) {
        return this.events.register(handler);
    }

    private async retrieveUpdates() {
        try {
            const response = await http
                .get("/api/languages/retrieve-update", {cache: "no-cache"})
                .json<ILanguageNotify>();

            this._update = response.update;
            this._applied = response.applied;
            this.events.publish((x) =>
                x.updatesChanged?.(this._update, this._applied),
            );
        } catch {
            // keep old version of error list
        }
    }

    private translate(id: ILanguageId) {
        const value = getIntlLocale(id);
        const native = value.native(getTranslator(DEFAULT_LANGUAGE.id, "", {})); // create own intl on default language
        return intlLocale.translateSingleString(value.language, native);
    }
}

const languages = new Languages();
export default languages;
