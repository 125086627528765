// tslint:disable-next-line:no-implicit-dependencies
import "../../../../node_modules/tippy.js/dist/tippy.css";

import Tippy from "@tippyjs/react";
import {Placement} from "popper.js";
import React from "react";

import trigger from "@/services/trigger";

interface ITooltipProps {
    content?: React.ReactNode;
    placement?: Placement;
    triggerId?: string;

    className?: string;
    gElement?: boolean;
    noSpanElement?: boolean; // IMPORTANTE: IntlContext.Consumer as first child will destroy layout of tooltip (disabled elements as well)
    visible?: boolean; // if true, dauervisible
    noArrow?: boolean;
}

class Tooltip extends React.PureComponent<ITooltipProps> {
    public componentDidMount() {
        if (!this.props.content) {
            this.notifyService();
        }
    }

    public componentDidUpdate(prevProps: Readonly<ITooltipProps>) {
        const {content} = this.props;

        if (prevProps.content !== content) {
            this.notifyService();
        }
    }

    public componentWillUnmount() {
        if (!this.props.content) {
            this.notifyService();
        }
    }

    public render() {
        const {
            children,
            className,
            content,
            gElement,
            noArrow,
            noSpanElement,
            placement,
            visible,
        } = this.props;
        const arrow = noArrow ? false : true;

        if (!content) {
            return children;
        }

        if (gElement) {
            return (
                <Tippy
                    visible={visible}
                    content={content}
                    arrow={arrow}
                    placement={placement ?? "top"}
                >
                    <g className={className}>{children}</g>
                </Tippy>
            );
        }

        if (noSpanElement) {
            return (
                <Tippy
                    visible={visible}
                    content={content}
                    arrow={arrow}
                    placement={placement ?? "top"}
                >
                    {children as unknown as React.ReactElement}
                </Tippy>
            );
        }

        return (
            <Tippy
                visible={visible}
                content={content}
                arrow={arrow}
                placement={placement ?? "top"}
            >
                <span className={className}>{children}</span>
            </Tippy>
        );
    }

    private notifyService() {
        const {triggerId} = this.props;
        if (triggerId) {
            trigger.trigger(triggerId);
        }
    }
}

export default Tooltip;
