import "./Functions.module.scss";

import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {boundMethod} from "autobind-decorator";
import React from "react";
import {IntlContext, IntlShape} from "react-intl";

import {ERoles} from "@/services/models";
import session from "@/services/session";
import {EPropertyTypes, IMaterialFunctionModel} from "../models";

import {
    displayWavelengthLabel,
    getPicoNumberForBoolean,
} from "@toolbox/display-blocks/Wavelength";
import {kev2Nm} from "@toolbox/functions/units/length";
import T from "@translate/T";
import FunctionRow, {
    getFormulaUnit,
    usesEnergy,
    usesTemperature,
    usesWavelength,
} from "./FunctionRow";
import EditorModal from "./editor-modal/Index";

export function getFunctionTitle(type: EPropertyTypes) {
    switch (type) {
        default:
        case EPropertyTypes.Viscosity:
            return <T>Viscosity</T>;

        case EPropertyTypes.Density:
            return <T>Density</T>;

        case EPropertyTypes.Rpri:
            return <T>Real Part of Refractive Index (RPRI)</T>;

        case EPropertyTypes.Ipri:
            return <T>Imaginary Part of Refractive Index (IPRI)</T>;

        case EPropertyTypes.Attenuation:
            return <T>Attenuation coefficient</T>;

        case EPropertyTypes.MassCoefficient:
            return <T>Mass coefficient</T>;
    }
}

export interface IMaterialFunctionsProps {
    type: EPropertyTypes;
    values: IMaterialFunctionModel[];
    extras?: IMaterialFunctionModel[];

    onChange(
        values: IMaterialFunctionModel[],
        extra?: IMaterialFunctionModel[],
    ): void;
    setActiveEditor(activeEditor: boolean): void;
    getDensity4MassCoeff?(): number | undefined;
}

interface IMaterialFunctionsState {
    materialFunction?: IMaterialFunctionModel;
    extra?: IMaterialFunctionModel;
}

class MaterialFunctions extends React.PureComponent<
    IMaterialFunctionsProps,
    IMaterialFunctionsState
> {
    public readonly state: IMaterialFunctionsState = {};

    private readonly ref = React.createRef<EditorModal>();

    @boundMethod
    public addNewFunction() {
        const {type, setActiveEditor} = this.props;
        setActiveEditor(true);

        const materialFunction: IMaterialFunctionModel = {
            formula: "",
            notes: "",
        };
        if (usesTemperature(type)) {
            materialFunction.maxTemperature = 450;
            materialFunction.minTemperature = 4;
        }

        if (usesWavelength(type)) {
            const useEnergy = usesEnergy(type);
            materialFunction.maxWavelength = useEnergy ? kev2Nm(10) : 900;
            materialFunction.minWavelength = useEnergy ? kev2Nm(30) : 60;
        }

        let extra: IMaterialFunctionModel | undefined;
        if (type === EPropertyTypes.Attenuation) {
            extra = {
                formula: "",
                notes: "",
                minTemperature: 20,
                maxTemperature: 20,
                minWavelength: materialFunction.minWavelength,
                maxWavelength: materialFunction.maxWavelength,
            };
        }

        this.setState({materialFunction, extra});
    }

    @boundMethod
    public showEdit(value: IMaterialFunctionModel) {
        const {setActiveEditor, type, values, extras} = this.props;
        setActiveEditor(true);

        const index = values.indexOf(value);
        let extra: IMaterialFunctionModel | undefined;
        if (type === EPropertyTypes.Attenuation) {
            extra = extras?.[index];
        }

        this.setState({materialFunction: value, extra});
    }

    @boundMethod
    public abortEdit() {
        this.setState({materialFunction: undefined, extra: undefined}, () =>
            this.props.setActiveEditor(false),
        );
    }

    @boundMethod
    public saveFunction(
        model: IMaterialFunctionModel,
        extra?: IMaterialFunctionModel,
    ) {
        const {onChange, values, setActiveEditor, extras} = this.props;
        const index = values.indexOf(this.state.materialFunction!);
        if (index < 0) {
            // created
            const added = values.concat(model);
            const _added = extras?.concat(extra ?? []);
            this.setState(
                {materialFunction: undefined, extra: undefined},
                () => {
                    onChange(added, _added);
                    setActiveEditor(false);
                },
            );
            return;
        }

        // edited
        const replaced = [...values];
        const _replaced = extras !== undefined ? [...extras] : undefined;
        replaced[index] = model;
        if (_replaced && extra) {
            _replaced[index] = extra;
        }

        this.setState({materialFunction: undefined, extra: undefined}, () => {
            onChange(replaced, _replaced);
            setActiveEditor(false);
        });
    }

    @boundMethod
    public removeFunction(value: IMaterialFunctionModel) {
        const {onChange, values, extras} = this.props;
        const index = values.indexOf(value);

        onChange(
            values.filter((x) => x !== value),
            extras?.filter((x) => x !== extras[index]),
        );
    }

    public render() {
        return (
            <React.Fragment>
                <IntlContext.Consumer children={this.renderFunctionsList} />
                {this.renderEditor()}
            </React.Fragment>
        );
    }

    @boundMethod
    private renderFunctionsList(intl: IntlShape) {
        const {type} = this.props;
        const useTemperature = usesTemperature(type);
        const useWavelength = usesWavelength(type);
        const usePico = getPicoNumberForBoolean(usesEnergy(type));

        return (
            <table
                id={"material-properties-" + type}
                className="table table-sm table-center table-bordered mb-2"
            >
                <thead className="thead-dark">
                    <tr>
                        <th>
                            {getFunctionTitle(type)}
                            {getFormulaUnit(type)}
                        </th>
                        {useTemperature && (
                            <th
                                className="space-nowrap"
                                styleName="single-cell"
                            >
                                <T>Temperature</T>
                            </th>
                        )}
                        {useWavelength && (
                            <th
                                className="space-nowrap"
                                styleName="single-cell"
                            >
                                {displayWavelengthLabel(intl, usePico)}
                            </th>
                        )}
                        <th styleName="comments-cell">
                            <T>Source / Comments</T>
                        </th>
                        {this.renderAddFunctionButton(type)}
                    </tr>
                </thead>
                <tbody>{this.renderRows()}</tbody>
            </table>
        );
    }

    private renderRows() {
        const {values, type} = this.props;

        return values.map((x, i) => (
            <FunctionRow
                key={i}
                id={"edit-property-" + type + "-" + i.toString()}
                value={x}
                type={type}
                onEdit={this.showEdit}
                onDelete={this.removeFunction}
            />
        ));
    }

    private renderAddFunctionButton(type: EPropertyTypes) {
        if (!session.hasRole(ERoles.Editor)) {
            return null;
        }

        return (
            <th className="space-nowrap" styleName="button-cell">
                <button
                    type="button"
                    id={"add-button-" + type}
                    data-testid={"add-button-" + type}
                    className="btn btn-primary w-100"
                    onClick={this.addNewFunction}
                >
                    <FontAwesomeIcon
                        icon={faPlus}
                        fixedWidth={true}
                        className="mr-1"
                    />
                    <T>Add</T>
                </button>
            </th>
        );
    }

    private renderEditor() {
        const {type, getDensity4MassCoeff} = this.props;
        const {materialFunction, extra} = this.state;
        if (!materialFunction) {
            return null;
        }

        return (
            <EditorModal
                ref={this.ref}
                value={materialFunction}
                extra={extra}
                type={type}
                onAbort={this.abortEdit}
                onSave={this.saveFunction}
                getDensity4MassCoeff={getDensity4MassCoeff}
            />
        );
    }
}

export default MaterialFunctions;
