import {boundMethod} from "autobind-decorator";
import React from "react";
import {DropzoneState} from "react-dropzone";

import T from "@translate/T";

interface IDropAreaProps {
    args: DropzoneState;
    cta: JSX.Element;
}

class DropArea extends React.PureComponent<IDropAreaProps> {
    @boundMethod
    public onClick(_: React.SyntheticEvent) {
        document.getElementById("drop-area")?.click(); // ref does not work
    }

    public render() {
        const {args, cta} = this.props;

        return (
            <div
                className="border"
                style={{cursor: "pointer"}}
                {...args.getRootProps()}
                onClick={this.onClick} // has to be last to overwrite ...args
            >
                <input
                    id="drop-area"
                    data-testid="drop-area"
                    {...args.getInputProps()}
                />
                <div className="text-center mx-3 my-5">
                    {cta}
                    <br />
                    <T>Or drag and drop file(s) here.</T>
                </div>
            </div>
        );
    }
}

export default DropArea;
