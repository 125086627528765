import {
    faCircleNotch,
    faTimes,
    faUser,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {boundMethod} from "autobind-decorator";
import React from "react";

import http from "@/services/http";
import session, {ISession} from "@/services/session";

import ValidatedForm from "@toolbox/button-like/ValidatedForm";
import T from "@translate/T";

interface ILoginProps {
    onAuthenticated(): void;
}

interface ILoginState {
    user: string;
    password: string;

    authError: number;
    showErrors: boolean;
}

class Login extends React.PureComponent<ILoginProps, ILoginState> {
    public readonly state: ILoginState = {
        authError: 0,
        password: "",
        showErrors: false,
        user: "",
    };

    @boundMethod
    public async handleSubmit() {
        this.setState({authError: 1}); // will show loading icon

        try {
            const {password, user} = this.state;
            const response = await http
                .post("/api/auth", {json: {password, user}})
                .json<ISession>();

            await session.setSession(response);

            this.props.onAuthenticated();
        } catch (error) {
            if (error instanceof http.HTTPError) {
                this.setState({authError: error.response.status});
                return;
            }

            // network error
            this.setState({authError: -1});
        }
    }

    @boundMethod
    public setValue(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();

        const {id, value} = e.target;
        this.setState({[id]: value} as unknown as ILoginState);
    }

    public render() {
        return (
            <ValidatedForm suffixId="login" onSubmit={this.handleSubmit}>
                <div className="mb-2">
                    <label className="mb-1" htmlFor="user">
                        <T>Username</T>
                    </label>
                    <input
                        type="text"
                        id="user"
                        autoComplete="username"
                        className="form-control"
                        required={true}
                        onChange={this.setValue}
                    />
                    <div className="invalid-feedback">
                        <T>Username is required!</T>
                    </div>
                </div>

                <div className="mb-2">
                    <label className="mb-1" htmlFor="password">
                        <T>Password</T>
                    </label>
                    <input
                        type="password"
                        id="password"
                        className="form-control"
                        autoComplete="current-password"
                        required={true}
                        onChange={this.setValue}
                    />
                    <div className="invalid-feedback">
                        <T>Password is required!</T>
                    </div>
                    {this.renderAuthenticationError()}
                </div>

                <button type="submit" className="btn btn-primary">
                    {this.renderIcon()}
                    <T>Login</T>
                </button>
            </ValidatedForm>
        );
    }

    private renderAuthenticationError() {
        let message: React.ReactChild;
        switch (this.state.authError) {
            case 0:
            case 1:
                return null;

            case 401:
                message = <T>Username/password is incorrect!</T>;
                break;

            case 403:
                message = <T>User account has been disabled!</T>;
                break;

            default:
                message = <T>Couldn't authenticate with server!</T>;
                break;
        }

        return (
            <small id="auth-error" className="form-text text-danger">
                {message}
            </small>
        );
    }

    private renderIcon() {
        switch (this.state.authError) {
            case 0:
                return (
                    <FontAwesomeIcon
                        icon={faUser}
                        fixedWidth={true}
                        className="mr-1"
                    />
                );

            case 1:
                return (
                    <FontAwesomeIcon
                        icon={faCircleNotch}
                        fixedWidth={true}
                        className="mr-1"
                        spin={true}
                    />
                );

            default:
                return (
                    <FontAwesomeIcon
                        icon={faTimes}
                        fixedWidth={true}
                        className="mr-1"
                    />
                );
        }
    }
}

export default Login;
