import {faUsers} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import React from "react";
import {Link} from "react-router-dom";

import {ERoles} from "@/services/models";
import session from "@/services/session";
import {IProjectRole} from "./models";

import T from "@translate/T";
import RolesRow from "./RolesRow";

function renderLink(group: string, url?: string) {
    const content = (
        <React.Fragment>
            <FontAwesomeIcon
                icon={faUsers}
                fixedWidth={true}
                className="mr-1"
            />
            {group}
        </React.Fragment>
    );

    if (!url) {
        return <em className="text-muted">{content}</em>;
    }

    return <Link to={url}>{content}</Link>;
}

interface IRolesTableProps {
    groupsUrl?: string;

    roles: ERoles;
    groups: string[];
    projects: IProjectRole[];
}

class RolesTable extends React.PureComponent<IRolesTableProps> {
    public render() {
        return (
            <div className="form-row">
                <div className="col-lg-9">
                    <h5>
                        <T>Global roles</T>
                    </h5>
                    {this.renderGlobal()}

                    <h5>
                        <T>Projects</T>
                    </h5>
                    {this.renderProjects()}
                </div>

                <div className="col-lg-3">
                    <h5>
                        <T>Groups</T>
                    </h5>
                    {this.renderGroups()}
                </div>
            </div>
        );
    }

    private renderProjects() {
        const {projects} = this.props;

        if (!projects.length) {
            return (
                <div className="form-text text-muted mb-2">
                    <em>
                        <T>No project has been assigned to user.</T>
                    </em>
                </div>
            );
        }

        const extra = (id: number) =>
            session.hasRole(ERoles.Manager, id) ? "/edit" : "";

        const rows = projects.map(({id, name, role}) => (
            <RolesRow value={role} key={id}>
                <Link to={`/project/${id}${extra(id)}`}>{name}</Link>
            </RolesRow>
        ));

        return (
            <table className="table table-sm table-striped table-center mb-2">
                <thead className="thead-dark">
                    <tr>
                        <th>
                            <T>Project</T>
                        </th>
                        <th>
                            <T>Viewer</T>
                        </th>
                        <th>
                            <T>Recorder</T>
                        </th>
                        <th>
                            <T>Editor</T>
                        </th>
                        <th>
                            <T>Manager</T>
                        </th>
                    </tr>
                </thead>
                <tbody>{rows}</tbody>
            </table>
        );
    }

    private renderGroups() {
        const {groupsUrl, groups} = this.props;

        if (!groups.length) {
            return (
                <div className="form-text text-muted mb-2">
                    <em className="mr-1">
                        <T>User does not belong to any group.</T>
                    </em>
                    {!!groupsUrl && (
                        <Link to={groupsUrl}>
                            <T>Manage groups</T>
                        </Link>
                    )}
                </div>
            );
        }

        return groups.map((group) => {
            const url = groupsUrl
                ? groupsUrl + "/edit/" + encodeURIComponent(group)
                : undefined;

            return (
                <li key={group} className="list-inline-item mb-2">
                    {renderLink(group, url)}
                </li>
            );
        });
    }

    private renderGlobal() {
        return (
            <table className="table table-sm mb-2">
                <tbody>
                    <RolesRow
                        value={this.props.roles}
                        showGlobal={true}
                        showText={true}
                    />
                </tbody>
            </table>
        );
    }
}

export default RolesTable;
