import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import React from "react";
import {IntlContext} from "react-intl";

import {ILocalizedText} from "@translate/models";

interface IWarningAlertProps {
    icon?: IconProp;
    renderMessage?: ILocalizedText;
    renderElement?: JSX.Element;

    otherBg?: string;
}

class WarningAlert extends React.PureComponent<IWarningAlertProps> {
    public render() {
        const {children, icon, renderElement, renderMessage, otherBg} =
            this.props;

        return (
            <div
                className={`alert ${
                    otherBg ?? "alert-warning"
                } has-buttons text-center`}
            >
                <div>
                    <FontAwesomeIcon
                        icon={icon ?? faExclamationTriangle}
                        fixedWidth={true}
                        className="mr-1"
                    />
                    {!!renderMessage && (
                        <IntlContext.Consumer children={renderMessage} />
                    )}
                    {renderElement}
                </div>
                <div className="card-height" />
                {children}
            </div>
        );
    }
}

export default WarningAlert;
