import {boundMethod} from "autobind-decorator";
import React from "react";

import {ERoles} from "@/services/models";
import {ILocalizedText} from "@translate/models";

import InlineCheckbox from "@toolbox/button-like/InlineCheckbox";
import {bitwiseAND, bitwiseNOT, bitwiseOR} from "@toolbox/functions/bitwise";
import {intl2Str} from "@translate/T";

interface IRoleProps {
    idSuffix: string;
    flag: ERoles;
    role: ERoles;
    inheritedRole?: ERoles;

    disabled?: boolean;
    title?: ILocalizedText;

    onChange(combined: ERoles): void;
}

class Role extends React.PureComponent<IRoleProps> {
    private get indeterminate() {
        const {flag, inheritedRole} = this.props;

        if (inheritedRole === undefined) {
            return undefined;
        }

        return bitwiseAND(inheritedRole, flag) === flag;
    }

    @boundMethod
    public onChange(checked: boolean) {
        const {flag, onChange, role} = this.props;
        const combined = checked
            ? bitwiseOR(role, flag)
            : bitwiseAND(role, bitwiseNOT(flag));

        onChange(combined);
    }

    public render() {
        const {children, disabled, flag, title, role, idSuffix} = this.props;
        const isChecked = bitwiseAND(role, flag) === flag;
        const indeterminate = this.indeterminate;
        let altTitle: ILocalizedText | undefined;

        if (indeterminate) {
            altTitle = (intl) =>
                intl2Str(intl, "Inherited from group membership.");
        }

        return (
            <td>
                <InlineCheckbox
                    idSuffix={`role-assignment-${flag}-${idSuffix}`}
                    dataTestId="role-input"
                    disabled={disabled}
                    title={title ?? altTitle}
                    checked={isChecked}
                    indeterminate={indeterminate}
                    toggle={this.onChange}
                >
                    {children}
                </InlineCheckbox>
            </td>
        );
    }
}

export default Role;
