import {
    ScaleBand,
    ScaleContinuousNumeric,
    ScaleLinear,
    ScaleLogarithmic,
} from "d3-scale";
import {ZoomTransform} from "d3-zoom";

import {EModules} from "@/models";
import {EAxisScale, IChartLabels, IMargins} from "@chart/models";
import {IZoomScale} from "@shared/services/other/scale";
import {IFakeMap} from "@toolbox/models";
import {ISampleBaseModel} from "../models";

import PanAndBrushContainer from "@chart/PanAndBrushContainer";

export const MAX_WIDTH_PER_THUMB = 150;

export enum EAnalysisLayouts {
    Container_2_10,
    Container_3_9,
    Container_4_8,
    Container_5_7,
    Container_6_6,
    Container_7_5,
    Container_8_4,
    Container_9_3,
    Container_12_12,
}

export enum EChangeReason {
    // enums for consol log
    Calculation = "calc",
    DependencyChange = "depend",
    ForceUpdate = "force",
    Initialize = "init",
    Initialized = "inited",
    ServerChange = "server",
    Synchronization = "sync",
    UnitChange = "unit",
    UserChange = "user",
}

export interface IChartState<TValue> extends IZoomScale {
    margins: IMargins;
    height: number;
    width: number;

    // we do not keep or safe it, after we stop dragging, we remove it
    panning?: ZoomTransform;

    scale: ISeriesScale;
    scaleArgs: IScaleArgs;

    labels: IChartLabels;
    colors: string[];
    data?: TValue;
}

export interface ISeriesScale {
    x: ScaleContinuousNumeric<number, number>;
    y: ScaleContinuousNumeric<number, number>;
    yR?: ScaleContinuousNumeric<number, number>;
    band?: ScaleBand<string>;

    multiX?: IMultiScale;
    multiY?: IMultiScale;
    massivX?: IFakeMap<ScaleContinuousNumeric<number, number>>;
    massivY?: IFakeMap<ScaleContinuousNumeric<number, number>>;

    getDomains?: (
        _min: number,
        _splitPoint: number,
        _max: number,
    ) => {
        reglDomainX: number[];
        reglDomainY: number[];
    };
}

export interface IMultiScale {
    splitPoint: number;

    log: ScaleLogarithmic<number, number>;
    lin: ScaleLinear<number, number>;
}

export interface IChartMinMax {
    maxX: number;
    minX: number;
    maxY: number;
    minY: number;
    maxYR: number;
    minYR: number;
}

export interface IScaleArgs {
    x: EAxisScale;
    y: EAxisScale;
}

export interface IMultipleRects {
    sample?: ISampleBaseModel;
    ref: React.RefObject<PanAndBrushContainer>;
    x: number;
    y: number;
}

export interface INewAnalysisRequest<TValue> {
    project: number;
    engine: EModules;
    alignments?: number[];
    measurements: number[];
    samples: TValue[];

    existing: boolean; // flag to signal backend, that new analysis created from existing analysis
}
