import "./ThirdParty.module.scss";

import {boundMethod} from "autobind-decorator";
import MarkdownIt from "markdown-it";
import React from "react";
import {injectIntl, IntlShape} from "react-intl";
import {Titled} from "react-titled";

import http from "@/services/http";
import fileUrl from "./third-party.md";

import ContainerCol from "@toolbox/display-blocks/ContainerCol";
import T, {intl2Str} from "@translate/T";

interface IThirdPartyProps {
    intl: IntlShape;
}

interface IThirdPartyState {
    licenses: string;
}

class ThirdParty extends React.PureComponent<
    IThirdPartyProps,
    IThirdPartyState
> {
    public readonly state: IThirdPartyState = {licenses: ""};

    private readonly md = new MarkdownIt();

    public async componentDidMount() {
        try {
            const response = await http.get(fileUrl);
            const licenses = await response.text();
            this.setState({licenses});
        } catch {
            this.setState({licenses: ""});
        }
    }

    @boundMethod
    public getTitle(parent: string) {
        return intl2Str(this.props.intl, "Third Party Licenses | {parent}", {
            parent,
        });
    }

    public render() {
        return (
            <Titled title={this.getTitle}>
                <ContainerCol col={8}>
                    <div styleName="open-source">
                        <h3>
                            <T>Third party licenses</T>
                        </h3>
                        <hr />

                        {this.renderContent()}
                    </div>
                </ContainerCol>
            </Titled>
        );
    }

    private renderContent() {
        const {licenses} = this.state;
        if (!licenses) {
            return null;
        }

        const html = this.md.render(licenses);
        return <div dangerouslySetInnerHTML={{__html: html}} />;
    }
}

export default injectIntl(ThirdParty);
