import {faFlask} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {boundMethod} from "autobind-decorator";
import React from "react";

import {EModules} from "@/models";
import {ERoles} from "@/services/models";
import session from "@/services/session";
import {NOT_UNDEFINED} from "@toolbox/functions/models";
import {EServerId, IAnalysisData, ISampleData} from "../../models";
import {ICompareStorage} from "../../services/other/compare-display";
import {IModuleGroup} from "../analyze-button/models";

import {hasFit, isMwl} from "@toolbox/functions/engine-check";
import {filterObjects} from "@toolbox/functions/object-iterator";
import T from "@translate/T";
import AnalyzeModal from "./AnalyzeModal";

export function getAnalysisData(
    data: IAnalysisData,
    engine: EModules,
    oldEngine: EModules,
) {
    data.bargraphZoom = undefined;
    data.hiddenstats = undefined;
    // settings will not be touched here

    const compare = {...data.compare};
    compare.compared = undefined;
    if (!hasFit(engine)) {
        compare.category = undefined;
    }

    if (isMwl(oldEngine) !== isMwl(engine)) {
        compare.groupBy = undefined;
        compare.thenBy = undefined;
    }

    data.compare = filterObjects<Partial<ICompareStorage>>(
        compare,
        NOT_UNDEFINED,
    );

    return data;
}

export function getSampleData(data: ISampleData) {
    // color stays
    // enabled stays
    // manual stays

    return data;
}

interface IAnalyzeButtonProps {
    project: number;
    serverId: EServerId;

    className?: string;
    hrefTarget?: string;

    getModules(targets: string): Promise<IModuleGroup[]>;
    getTargets(): (engine?: EModules) => string; // without engine send no &storage=
}

interface IAnalyzeButtonState {
    modules: IModuleGroup[];
    getTargets?(engine?: EModules): string;
}

class AnalyzeButton extends React.PureComponent<
    IAnalyzeButtonProps,
    IAnalyzeButtonState
> {
    public readonly state: IAnalyzeButtonState = {modules: []};

    private readonly ref = React.createRef<AnalyzeModal>();

    @boundMethod
    public async onClick() {
        const {getModules, getTargets: _getTargets} = this.props;
        const getTargets = _getTargets(); // we get full storage only once
        const modules = await getModules(getTargets()); // without engine it is without &storage=
        this.setState({modules, getTargets});
    }

    @boundMethod
    public afterClose() {
        this.setState({getTargets: undefined});
    }

    public render() {
        const {project, className} = this.props;

        if (!session.hasRole(ERoles.Editor, project)) {
            return null;
        }

        let _className = "btn btn-secondary";
        if (className) {
            _className += ` ${className}`;
        }

        return (
            <React.Fragment>
                <button
                    type="button"
                    id="analyze-button"
                    className={_className}
                    onClick={this.onClick}
                >
                    <FontAwesomeIcon
                        icon={faFlask}
                        fixedWidth={true}
                        className="mr-1"
                    />
                    <T>Analyze</T>
                </button>

                {this.renderModal()}
            </React.Fragment>
        );
    }

    private renderModal() {
        const {project, hrefTarget, serverId} = this.props;
        const {modules, getTargets} = this.state;

        if (!getTargets) {
            return null;
        }

        return (
            <AnalyzeModal
                ref={this.ref}
                hrefTarget={hrefTarget}
                modules={modules}
                project={project}
                serverId={serverId}
                getTargets={getTargets}
                afterClose={this.afterClose}
            />
        );
    }
}

export default AnalyzeButton;
