import React from "react";
import {injectIntl, IntlShape} from "react-intl";

import {IProjectStats} from "../models";

import {
    getAnalyses,
    getButtons,
    getMeasurements,
    getSops,
    getTitle,
} from "./ProjectView";

interface IProjectListProps {
    intl: IntlShape;
    project: IProjectStats;
}

class ProjectList extends React.PureComponent<IProjectListProps> {
    public render() {
        const {intl, project} = this.props;
        const {analyses, id, measurements, name, sops} = project;

        return (
            <React.Fragment>
                <td className="big-width-cell">{getTitle(name, id)}</td>
                <td>{getSops(intl, sops, id)}</td>
                <td>{getMeasurements(intl, measurements, id)}</td>
                <td>{getAnalyses(intl, analyses, id)}</td>
                <td className="text-right">
                    <div className="btn-group btn-group-sm">
                        {getButtons(id, intl)}
                    </div>
                </td>
            </React.Fragment>
        );
    }
}

export default injectIntl(ProjectList);
