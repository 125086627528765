import styles from "./TasksPanel.module.scss";

import {boundMethod} from "autobind-decorator";
import React from "react";
import {
    createHtmlPortalNode,
    HtmlPortalNode,
    InPortal,
    OutPortal,
} from "react-reverse-portal";

import modal from "@/services/modal";
import resize from "@/services/resize";
import {EFloatStates} from "./models";

interface ITasksPanelState {
    hasModal?: boolean;
    floatState: EFloatStates;
    height: number;
}

class TasksPanel extends React.PureComponent<{}, ITasksPanelState> {
    public readonly state: ITasksPanelState = {
        floatState: EFloatStates.Hidden,
        hasModal: modal.hasModal,
        height: 0,
    };

    private readonly buttonSizer = React.createRef<HTMLDivElement>();
    private readonly panel = React.createRef<HTMLDivElement>();
    private portalNode = createHtmlPortalNode();
    private handlers = new Map<
        Element,
        (intersecting: IntersectionObserverEntry) => void
    >();
    private observer = new IntersectionObserver((entries) => {
        for (const entry of entries) {
            const handler = this.handlers.get(entry.target);
            if (!handler) {
                this.observer.unobserve(entry.target);
                continue;
            }

            handler(entry);
        }
    });

    private unsubscribe?: () => void;

    public componentDidMount() {
        const panel = this.panel.current!;
        this.observer.observe(panel);

        this.handlers.set(panel, (entry) => {
            const showFloat =
                entry.intersectionRatio === 0 &&
                entry.boundingClientRect.bottom < 0;

            this.setState(this.setFloatVisibility(showFloat));
        });
        const unModal = modal.subscribe({
            modalChanged: (hasModal) => this.setState({hasModal}),
        });
        const unResize = resize.subscribe(
            this.buttonSizer.current!,
            this.setHeight,
        );

        this.setHeight();

        this.unsubscribe = () => {
            unModal();
            unResize();
        };
    }

    public componentWillUnmount() {
        const panel = this.panel.current!;
        this.handlers.delete(panel);
        this.observer.unobserve(panel);

        this.unsubscribe?.();
    }

    @boundMethod
    public onAnimationEnd(e: React.AnimationEvent<HTMLElement>) {
        if (e.animationName !== styles.slideInDown) {
            return;
        }

        switch (this.state.floatState) {
            case EFloatStates.SlideDown:
                this.setState({floatState: EFloatStates.Visible});
                break;

            case EFloatStates.SlideUp:
                this.setState({floatState: EFloatStates.Hidden});
                break;
        }
    }

    public render() {
        const {floatState, height} = this.state;

        return (
            <React.Fragment>
                <InPortal node={this.portalNode}>
                    <div
                        ref={this.buttonSizer}
                        id="toolbar-float"
                        className="btn-toolbar"
                        role="toolbar"
                    >
                        {this.props.children}
                    </div>
                </InPortal>

                <div
                    id="task-card"
                    className="card mb-2"
                    data-testid="task-card"
                >
                    <div
                        // div to check if buttons are out of view
                        ref={this.panel}
                        className="card-body bg-focus"
                    >
                        <div
                            // div to get the button heights
                            className="w-100"
                            styleName="out-portal-parent"
                            style={{minHeight: height}}
                        >
                            {floatState === EFloatStates.Hidden && (
                                <OutPortal node={this.portalNode} />
                            )}
                        </div>
                    </div>
                </div>

                {this.renderFloat(this.portalNode)}
            </React.Fragment>
        );
    }

    private renderFloat(node: HtmlPortalNode<React.Component>) {
        const {floatState, hasModal} = this.state;
        if (floatState === EFloatStates.Hidden || hasModal) {
            return null;
        }

        // no bg-light, as we have beige
        let className = "navbar navbar-light bg-focus fixed-top"; // go to navbar/Index.tsx for explain
        switch (floatState) {
            case EFloatStates.SlideDown:
                className += " " + styles["slide-down"];
                break;

            case EFloatStates.SlideUp:
                className += " " + styles["slide-up"];
                break;
        }

        return (
            <nav
                id="nav-bar-float"
                className={className}
                styleName="out-portal-parent"
                onAnimationEnd={this.onAnimationEnd}
            >
                <OutPortal node={node} />
            </nav>
        );
    }

    private setFloatVisibility(visible: boolean) {
        if (visible) {
            return this.state.floatState === EFloatStates.Visible
                ? null
                : {floatState: EFloatStates.SlideDown};
        }

        return this.state.floatState === EFloatStates.Hidden
            ? null
            : {floatState: EFloatStates.SlideUp};
    }

    @boundMethod
    private setHeight() {
        this.setState({height: this.buttonSizer.current!.clientHeight});
    }
}

export default TasksPanel;
