import {AnimatePresence, motion} from "framer-motion";
import {ceil, floor} from "lodash";
import React from "react";

import realtime from "@/services/realtime";
import resize from "@/services/resize";
import {CARD_PADDING} from "@/styles/_variables";
import {animation, onClosed, onOpen} from "@toolbox/design/models";
import {IProjectStats} from "../models";
import {EProjectDisplayModes, MIN_WIDTH_PER_PROJECT} from "./models";

import GridDeck from "@toolbox/design/GridDeck";
import ProjectCard from "./ProjectCard";
import ProjectList from "./ProjectList";

export interface ISearchResultProps {
    projects: IProjectStats[];
}

interface ISearchResultState {
    display: EProjectDisplayModes;
    width: number;
}

class SearchResult extends React.PureComponent<
    ISearchResultProps,
    ISearchResultState
> {
    public readonly state: ISearchResultState = {
        display: realtime.value.projectView,
        width: 100,
    };

    private readonly searchresults = React.createRef<HTMLDivElement>();

    private unsubscribe?: () => void;

    public componentDidMount() {
        const unRealtime = realtime.subscribe({
            realtimeChanged: (value) =>
                this.setState({display: value.projectView}),
        });
        const unResize = resize.subscribe(this.searchresults.current!, (rect) =>
            this.setState({width: rect.width}),
        );

        this.unsubscribe = () => {
            unRealtime();
            unResize();
        };
    }

    public componentWillUnmount() {
        this.unsubscribe?.();
    }

    public render() {
        const {display} = this.state;
        const projects =
            display === EProjectDisplayModes.Cards
                ? this.renderProjectCards()
                : this.renderProjectList();

        return (
            <div ref={this.searchresults} className="mb-2">
                <AnimatePresence exitBeforeEnter={true}>
                    {projects}
                </AnimatePresence>
            </div>
        );
    }

    private renderProjectCards() {
        const {projects} = this.props;
        const {width} = this.state;

        const cols = Math.max(
            floor(
                (width + CARD_PADDING) / (MIN_WIDTH_PER_PROJECT + CARD_PADDING),
            ),
            1,
        );
        const rows = ceil(projects.length / cols);
        const widthPerProject = (width + CARD_PADDING) / cols - CARD_PADDING;

        const cards = projects.map((x) => (
            <motion.div
                key={"card-" + x.id}
                animate={onOpen}
                exit={onClosed}
                initial={onClosed}
                transition={animation}
            >
                <ProjectCard project={x} width={widthPerProject} />
            </motion.div>
        ));

        return (
            <GridDeck cols={cols} rows={rows}>
                {cards}
            </GridDeck>
        );
    }

    private renderProjectList() {
        const items = this.props.projects.map((x) => (
            <motion.tr
                key={"list-" + x.id}
                animate={onOpen}
                exit={onClosed}
                initial={onClosed}
                transition={animation}
                className={x.isHidden ? "bg-tertiary" : undefined}
            >
                <ProjectList project={x} />
            </motion.tr>
        ));

        return (
            <table
                data-testid="project-list"
                className="table table-sm table-striped table-borderless space-nowrap"
            >
                <tbody>{items}</tbody>
            </table>
        );
    }
}

export default SearchResult;
