import {faEraser, faUpload} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {boundMethod} from "autobind-decorator";
import React from "react";
import {IntlContext, IntlShape} from "react-intl";

import {lumLogoDataUrl} from "@/components/navbar/lumlogo";
import http from "@/services/http";

import {blob2DataURL} from "@/services/download";
import Card from "@toolbox/design/Card";
import CustomizeLabel from "@toolbox/display-blocks/CustomizeLabel";
import T, {intl2Str} from "@translate/T";

/** do catch this method at all times !! */
export async function getImage2Url(apiUrl: string) {
    const response = await http.get(apiUrl); // do not catch this part.
    const blob = await response.blob();
    const dataUrl = await blob2DataURL(blob);

    return dataUrl;
}

interface IPrintReportSettingsProps {
    project?: number;
}

interface IPrintReportSettingsState {
    image?: string;
    error?: JSX.Element;
}

class PrintReportSettings extends React.PureComponent<
    IPrintReportSettingsProps,
    IPrintReportSettingsState
> {
    public readonly state: IPrintReportSettingsState = {};
    private readonly ref = React.createRef<HTMLInputElement>();
    private readonly url = "/api/report/logo/" + (this.props.project ?? "");

    public componentDidMount() {
        this.retrieve();
    }

    @boundMethod
    public triggerUpload(e: React.SyntheticEvent) {
        e.preventDefault();

        this.ref.current?.click();
    }

    @boundMethod
    public onFileSelected(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();

        for (const file of e.target.files ?? []) {
            this.save(file);
        }
    }

    @boundMethod
    public onReset(e: React.SyntheticEvent) {
        e.preventDefault();

        this.delete();
    }

    public render() {
        const {children} = this.props;
        const {error} = this.state;

        return (
            <Card
                title={<T>Report settings</T>}
                renderButtons={this.renderButtons}
            >
                <div className="card-body">
                    <input
                        ref={this.ref}
                        type="file"
                        data-testid="image-upload"
                        hidden={true}
                        accept="image/*"
                        onChange={this.onFileSelected}
                    />

                    {!!error && <em className="text-danger">{error}</em>}

                    <IntlContext.Consumer children={this.renderLogo} />

                    {children}
                </div>
            </Card>
        );
    }

    @boundMethod
    private renderButtons(intl: IntlShape) {
        return (
            <React.Fragment>
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.triggerUpload}
                    title={intl2Str(intl, "Upload logo")}
                >
                    <FontAwesomeIcon icon={faUpload} fixedWidth={true} />
                </button>
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.onReset}
                    title={intl2Str(intl, "Use default logo")}
                >
                    <FontAwesomeIcon icon={faEraser} fixedWidth={true} />
                </button>
            </React.Fragment>
        );
    }

    @boundMethod
    private renderLogo(intl: IntlShape) {
        return (
            <CustomizeLabel label={<T>Logo</T>}>
                <img
                    alt={intl2Str(intl, "Report logo")}
                    className="pointer-cursor"
                    height="75px"
                    src={this.state.image}
                    onClick={this.triggerUpload}
                />
            </CustomizeLabel>
        );
    }

    private async retrieve() {
        try {
            const image = await getImage2Url(this.url);
            this.setState({image, error: undefined});
        } catch {
            this.setState({image: lumLogoDataUrl, error: undefined});
        }
    }

    private async save(file: File) {
        const body = new FormData();
        body.append("image", file);

        try {
            const response = await http.post(this.url, {body});
            const blob = await response.blob();
            const image = await blob2DataURL(blob);
            this.setState({image, error: undefined});
        } catch {
            this.setState({
                image: lumLogoDataUrl,
                error: <T>Could not save image.</T>,
            });
        }
    }

    private async delete() {
        try {
            const response = await http.delete(this.url);
            if (!response.ok) {
                throw new http.HTTPError(response);
            }

            this.setState({image: lumLogoDataUrl, error: undefined});
        } catch {
            this.setState({image: lumLogoDataUrl, error: undefined});
        }
    }
}

export default PrintReportSettings;
