import {boundMethod} from "autobind-decorator";
import {ceil} from "lodash";
import React from "react";

import {IPaging} from "./models";

import T from "@translate/T";
import PageNumber from "./PageNumber";

export interface IPagerProps extends Omit<IPaging, "pageSize"> {
    classNameUl?: string;
    topMargin?: boolean;
    bottomMargin?: boolean;
    pageSize: number | "all";

    onChange(page: number): void;
}

const PAGES_AROUND_CURRENT = 4; // number of pages around current page to render

class Pager extends React.PureComponent<IPagerProps> {
    private get pages() {
        const {pageSize, total} = this.props;

        if (pageSize === "all") {
            return 1;
        }

        return Math.max(1, ceil(total / pageSize));
    }

    @boundMethod
    public goFirst() {
        this.props.onChange(1);
    }

    @boundMethod
    public goLast() {
        this.props.onChange(this.pages);
    }

    public render() {
        const {onChange, page, classNameUl, topMargin, bottomMargin} =
            this.props;
        const pages = this.pages;

        if (pages === 1) {
            return null;
        }

        let className = "pagination justify-content-center";
        if (topMargin) {
            className += " mt-2";
        }

        if (bottomMargin) {
            className += " mb-2";
        } else {
            className += " mb-0";
        }

        if (classNameUl) {
            className += " " + classNameUl;
        }

        return (
            <ul className={className} aria-label="page navigation">
                <PageNumber
                    value={1}
                    active={page}
                    text={<T>First</T>}
                    onClick={onChange}
                />

                {this.renderPageNumbers(pages, page)}

                <PageNumber
                    value={pages}
                    active={page}
                    text={<T>Last</T>}
                    onClick={onChange}
                />
            </ul>
        );
    }

    private renderPageNumbers(pages: number, active: number) {
        const {onChange} = this.props;
        const pageItems = [] as JSX.Element[];

        const min = Math.max(2, active - PAGES_AROUND_CURRENT);
        const max = Math.min(pages - 1, active + PAGES_AROUND_CURRENT);

        if (min > 2) {
            pageItems.push(this.renderExtentButton("extLeft"));
        }

        for (let i = min; i <= max; i++) {
            const page = i;
            pageItems.push(
                <PageNumber
                    key={page}
                    value={page}
                    active={active}
                    onClick={onChange}
                />,
            );
        }

        if (max < pages - 1) {
            pageItems.push(this.renderExtentButton("extRight"));
        }

        return pageItems;
    }

    private renderExtentButton(key: string) {
        return (
            <li key={key} className="page-item disabled">
                <button type="button" className="page-link" tabIndex={-1}>
                    ...
                </button>
            </li>
        );
    }
}

export default Pager;
