import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {debounce} from "lodash";
import React from "react";

import {IDLE_DELAY} from "../models";

import T from "@translate/T";
import ContainerCol from "../display-blocks/ContainerCol";

interface ILoadingState {
    pastDelay: boolean;
}

class Loading extends React.PureComponent<{}, ILoadingState> {
    public readonly state: ILoadingState = {
        // avoid content flash
        pastDelay: false,
    };

    private readonly waiting = debounce(
        () => this.setState({pastDelay: true}),
        IDLE_DELAY,
    );

    public componentDidMount() {
        this.waiting();
    }

    public componentWillUnmount() {
        this.waiting.cancel();
    }

    public render() {
        if (!this.state.pastDelay) {
            return null;
        }

        return (
            <ContainerCol col={6}>
                <div className="alert alert-info" role="alert">
                    <FontAwesomeIcon
                        icon={faSpinner}
                        fixedWidth={true}
                        className="mr-1"
                        spin={true}
                    />
                    <T>Loading, please wait...</T>
                </div>
            </ContainerCol>
        );
    }
}

export default Loading;
