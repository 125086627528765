import {boundMethod} from "autobind-decorator";
import React from "react";
import {IntlShape} from "react-intl";

import networkDevices, {IDeviceStatus} from "@/services/network-devices";

import WarningAlert from "@toolbox/design/WarningAlert";
import {intl2Str} from "@translate/T";

interface IUnlicensedDeviceState {
    devices: IDeviceStatus[];
}

class UnlicensedDevice extends React.PureComponent<{}, IUnlicensedDeviceState> {
    public readonly state: IUnlicensedDeviceState = {
        devices: this.getDevices(),
    };

    private unsubscribe?: () => void;

    public componentDidMount() {
        const unNetworkDevices = networkDevices.subscribe({
            devicesChanged: () => this.setState({devices: this.getDevices()}),
        });

        this.unsubscribe = () => {
            unNetworkDevices?.();
        };
    }

    public componentWillUnmount() {
        this.unsubscribe?.();
    }

    public render() {
        const {devices} = this.state;
        if (!devices.length) {
            return null;
        }

        return <WarningAlert renderMessage={this.renderMessage} />;
    }

    @boundMethod
    private renderMessage(intl: IntlShape) {
        const {devices} = this.state;

        return intl2Str(
            intl,
            "Some connected devices ({devices}) are not licensed, no measurement data will be received.",
            {
                devices: devices
                    .map((device) =>
                        networkDevices.getDeviceName(intl, device.device),
                    )
                    .join(", "),
            },
        );
    }

    private getDevices() {
        return networkDevices.devices.filter(
            (x) => x.isOnline && !networkDevices.isLicensed(x.device),
        );
    }
}

export default UnlicensedDevice;
