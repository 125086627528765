import {boundMethod} from "autobind-decorator";
import React from "react";
import {injectIntl, IntlShape} from "react-intl";

import {getSchemeImage, getSpocColorImage} from "./images";
import {EDataColorSchemes} from "./models";

import InlineRadios from "@toolbox/button-like/InlineRadios";
import T, {intl2Str, parseNumber} from "@translate/T";

interface IColorbarSchemeProps {
    intl: IntlShape;
    value: EDataColorSchemes;
    onChange(value: EDataColorSchemes): void;
}

interface IColorbarSchemeState {
    height: number;
}

class ColorbarScheme extends React.PureComponent<
    IColorbarSchemeProps,
    IColorbarSchemeState
> {
    public readonly state: IColorbarSchemeState = {height: 0};

    private readonly div = React.createRef<HTMLDivElement>();

    public componentDidMount() {
        const div = this.div.current;
        const height = (div?.clientHeight ?? 0) * 0.75;
        this.setState({height});
    }

    public render() {
        const {intl, value, onChange} = this.props;

        return (
            <div className="form-row mb-2">
                <div className="col-sm-2">
                    <label>
                        <T>2D density dot plot scheme</T>
                    </label>
                </div>

                <div className="col-sm-4" ref={this.div}>
                    <InlineRadios<EDataColorSchemes>
                        breakEachElement={true}
                        idSuffix="colorbar-schemes"
                        selected={value}
                        onSelected={onChange}
                        values={this.getContent([
                            <T key={EDataColorSchemes.Turbo}>Turbo</T>,
                            <T key={EDataColorSchemes.Warm}>Warm</T>,
                            <T key={EDataColorSchemes.Plasma}>Plasma</T>,
                            <T key={EDataColorSchemes.Sinebow}>Sinebow</T>,
                            <T key={EDataColorSchemes.ColorBlind}>
                                Color blind
                            </T>,
                        ])}
                    />
                </div>

                <div className="col-sm-3 align-self-center text-center">
                    <img
                        alt={intl2Str(intl, "2D density dot plot scheme")}
                        src={getSpocColorImage(value)}
                        height={this.state.height}
                    />
                </div>
            </div>
        );
    }

    @boundMethod
    private getContent(schemes: JSX.Element[]) {
        const {intl} = this.props;

        return schemes.map((scheme) => (
            <React.Fragment key={scheme.key ?? undefined}>
                {scheme}
                <br />
                <img
                    alt={
                        intl2Str(intl, "2D density dot plot scheme") +
                        " " +
                        scheme.key
                    }
                    src={getSchemeImage(
                        parseNumber(scheme.key as string) as EDataColorSchemes,
                    )}
                    width="256"
                    height="10"
                />
            </React.Fragment>
        ));
    }
}

export default injectIntl(ColorbarScheme);
