import "./ColorScheme.module.scss";

import {boundMethod} from "autobind-decorator";
import React from "react";

import {ESampleColorSchemes} from "./models";

import InlineRadios from "@toolbox/button-like/InlineRadios";
import {getColorScheme} from "@toolbox/functions/get-color-scheme";
import T, {parseNumber} from "@translate/T";

interface ISampleColorSchemeProps {
    value: ESampleColorSchemes;
    onChange(value: ESampleColorSchemes): void;
}

class SampleColorScheme extends React.PureComponent<ISampleColorSchemeProps> {
    public render() {
        const {onChange, value} = this.props;

        return (
            <div className="form-row mb-2">
                <div className="col-sm-2">
                    <label>
                        <T>Sample color scheme</T>
                    </label>
                </div>

                <div className="col-sm-10">
                    <InlineRadios<ESampleColorSchemes>
                        breakEachElement={true}
                        idSuffix="sample-color-schemes"
                        selected={value}
                        onSelected={onChange}
                        values={this.getContent([
                            <T key={ESampleColorSchemes.OldSchool}>
                                Old school
                            </T>,
                            <T key={ESampleColorSchemes.Modern}>Modern</T>,
                            <T key={ESampleColorSchemes.Retro}>Retro</T>,
                            <T key={ESampleColorSchemes.Grayscale}>
                                Grayscale
                            </T>,
                        ])}
                    />
                </div>
            </div>
        );
    }

    @boundMethod
    private getContent(schemes: JSX.Element[]) {
        return schemes.map((scheme) => (
            <React.Fragment key={scheme.key ?? undefined}>
                {scheme}
                <br />
                {this.getColors(
                    parseNumber(scheme.key as string) as ESampleColorSchemes,
                )}
            </React.Fragment>
        ));
    }

    private getColors(scheme: ESampleColorSchemes) {
        return getColorScheme(scheme)
            .slice(0, 5)
            .map((x) => (
                <div
                    key={x}
                    styleName="color-scheme-box"
                    style={{backgroundColor: x}}
                />
            ));
    }
}

export default SampleColorScheme;
