import React from "react";
import {IntlProvider, createIntl} from "react-intl";

import {DEFAULT_LANGUAGE} from "@/components/navbar/models";
import intlLocale from "@/services/intlLocale";
import preferences from "@/services/preferences";

export function getTranslator(
    _language?: string,
    _region?: string,
    _messages?: Record<string, string>,
) {
    const locale = getLocale(_language, _region);
    const messages = _messages ?? intlLocale.messages;

    return createIntl({locale, messages});
}

function getLocale(_language?: string, _region?: string) {
    let locale = getValidIntl(_language ?? intlLocale.language);
    const region = _region ?? preferences.value.region;
    if (region) {
        locale += "-" + region;
    }

    return locale;
}

export function getValidIntl(locale: string) {
    try {
        Intl.NumberFormat.supportedLocalesOf(locale); // only see if it crashed, otherwise take "locale"
        return locale;
    } catch {
        return DEFAULT_LANGUAGE.id;
    }
}

interface IProviderState {
    locale: string;
    messages?: Record<string, string>;
}

class Provider extends React.PureComponent<{}, IProviderState> {
    public readonly state: IProviderState = {
        locale: getLocale(),
        messages: intlLocale.messages,
    };

    private unsubscribe?: () => void;

    public componentDidMount() {
        const update = () =>
            this.setState({locale: getLocale(), messages: intlLocale.messages});

        const unLocale = intlLocale.subscribe({localeChanged: update});
        const unRegion = preferences.subscribe({regionChanged: update});

        this.unsubscribe = () => {
            unRegion();
            unLocale();
        };
    }

    public componentWillUnmount() {
        this.unsubscribe?.();
    }

    public render() {
        const {locale, messages} = this.state;

        return (
            <IntlProvider
                locale={locale}
                messages={messages}
                defaultLocale={getLocale("")} // only language code
            >
                {this.props.children}
            </IntlProvider>
        );
    }
}

export default Provider;
