import {format} from "date-fns";
import React from "react";
import {IntlShape, injectIntl} from "react-intl";

import {getIntlLocale} from "@/services/intl-locale";

export function getLongDate(intl: IntlShape, value: Date) {
    return format(value, "PPPPpp", {locale: getIntlLocale(intl.locale).locale});
}

export function getShortDate(intl: IntlShape, value: Date) {
    return format(value, "PPp", {locale: getIntlLocale(intl.locale).locale});
}

interface IRelativeTimeProps {
    intl: IntlShape;
    value: Date;
    intl4NumberRegion?: IntlShape;
}

// render time as relative text (1 day ago) | set `asAbsolute` to `true` to display time as absolute value
class Time extends React.PureComponent<IRelativeTimeProps> {
    public render() {
        try {
            const {intl, value, intl4NumberRegion} = this.props;
            const _intl = intl4NumberRegion ?? intl;

            // how to build relativ Date. (just look below)
            // const relative = formatRelative(value, new Date(), {locale});

            return (
                <time
                    title={getLongDate(_intl, value)}
                    dateTime={value.toISOString()}
                >
                    {getShortDate(_intl, value)}
                </time>
            );
        } catch {
            return null;
        }
    }
}

export default injectIntl(Time);
