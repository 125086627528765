import _af from "date-fns/locale/af";
import _dz from "date-fns/locale/ar-DZ";
import _eg from "date-fns/locale/ar-EG";
import _ma from "date-fns/locale/ar-MA";
import _sa from "date-fns/locale/ar-SA";
import _tn from "date-fns/locale/ar-TN";
import _bg from "date-fns/locale/bg";
import _cs from "date-fns/locale/cs";
import _da from "date-fns/locale/da";
import _de from "date-fns/locale/de";
import _at from "date-fns/locale/de-AT";
import _el from "date-fns/locale/el";
import _au from "date-fns/locale/en-AU";
import _ca from "date-fns/locale/en-CA";
import _gb from "date-fns/locale/en-GB";
import _ie from "date-fns/locale/en-IE";
import _nz from "date-fns/locale/en-NZ";
import _us from "date-fns/locale/en-US";
import _es from "date-fns/locale/es";
import _fi from "date-fns/locale/fi";
import _fr from "date-fns/locale/fr";
import _ch from "date-fns/locale/fr-CH";
import _he from "date-fns/locale/he";
import _hi from "date-fns/locale/hi";
import _hr from "date-fns/locale/hr";
import _hu from "date-fns/locale/hu";
import _id from "date-fns/locale/id";
import _is from "date-fns/locale/is";
import _it from "date-fns/locale/it";
import _ja from "date-fns/locale/ja";
import _kk from "date-fns/locale/kk";
import _ko from "date-fns/locale/ko";
import _lb from "date-fns/locale/lb";
import _ms from "date-fns/locale/ms";
import _nl from "date-fns/locale/nl";
import _be from "date-fns/locale/nl-BE";
import _nn from "date-fns/locale/nn";
import _pl from "date-fns/locale/pl";
import _pt from "date-fns/locale/pt";
import _br from "date-fns/locale/pt-BR";
import _ro from "date-fns/locale/ro";
import _ru from "date-fns/locale/ru";
import _sk from "date-fns/locale/sk";
import _sl from "date-fns/locale/sl";
import _sr from "date-fns/locale/sr";
import _sv from "date-fns/locale/sv";
import _th from "date-fns/locale/th";
import _tr from "date-fns/locale/tr";
import _uz from "date-fns/locale/uz";
import _vi from "date-fns/locale/vi";
import _cn from "date-fns/locale/zh-CN";
import _hk from "date-fns/locale/zh-HK";
import _tw from "date-fns/locale/zh-TW";

import {IIntlLocale, ILanguageId} from "./models";

import {intl2LocaleParts, intl2Str} from "@translate/T";

export const intlLocales: IIntlLocale[] = [
    {
        id: "AO",
        region: (intl) => intl2Str(intl, "Angola"),
        locale: _pt,
        language: "pt",
        passiv: true,
        native: (intl) => intl2Str(intl, "Portuguese"),
    },
    {
        id: "AR",
        region: (intl) => intl2Str(intl, "Argentinia"),
        locale: _es,
        language: "es",
        passiv: true,
        native: (intl) => intl2Str(intl, "Spanish"),
    },
    {
        id: "AT",
        region: (intl) => intl2Str(intl, "Austria"),
        locale: _at,
        language: "de",
        passiv: true,
        native: (intl) => intl2Str(intl, "German"),
    },
    {
        id: "AU",
        region: (intl) => intl2Str(intl, "Australia"),
        locale: _au,
        language: "en",
        passiv: true,
        native: (intl) => intl2Str(intl, "English"),
    },
    {
        id: "BE",
        region: (intl) => intl2Str(intl, "Belgium"),
        locale: _be,
        language: "nl",
        passiv: true,
        native: (intl) => intl2Str(intl, "Dutch"),
    },
    {
        id: "BG",
        region: (intl) => intl2Str(intl, "Bulgaria"),
        locale: _bg,
        language: "bg",
        native: (intl) => intl2Str(intl, "Bulgarian"),
    },
    {
        id: "BR",
        region: (intl) => intl2Str(intl, "Brazil"),
        locale: _br,
        language: "pt",
        passiv: true,
        native: (intl) => intl2Str(intl, "Portuguese"),
    },
    {
        id: "CA",
        region: (intl) => intl2Str(intl, "Canada"),
        locale: _ca,
        language: "en",
        passiv: true,
        native: (intl) => intl2Str(intl, "English"),
    },
    {
        id: "CH",
        region: (intl) => intl2Str(intl, "Switzerland"),
        locale: _ch,
        language: "de",
        passiv: true,
        native: (intl) => intl2Str(intl, "German"),
    },
    {
        id: "CL",
        region: (intl) => intl2Str(intl, "Chile"),
        locale: _es,
        language: "es",
        passiv: true,
        native: (intl) => intl2Str(intl, "Spanish"),
    },
    {
        id: "CN",
        region: (intl) => intl2Str(intl, "China"),
        locale: _cn,
        language: "zh-Hans",
        native: (intl) => intl2Str(intl, "Chinese (Simplified)"),
    },
    {
        id: "CO",
        region: (intl) => intl2Str(intl, "Colombia"),
        locale: _es,
        language: "es",
        passiv: true,
        native: (intl) => intl2Str(intl, "Spanish"),
    },
    {
        id: "CZ",
        region: (intl) => intl2Str(intl, "Czech Republic"),
        locale: _cs,
        language: "cz",
        native: (intl) => intl2Str(intl, "Czech"),
    },
    {
        id: "DE",
        region: (intl) => intl2Str(intl, "Germany"),
        locale: _de,
        language: "de",
        native: (intl) => intl2Str(intl, "German"),
    },
    {
        id: "DK",
        region: (intl) => intl2Str(intl, "Denmark"),
        locale: _da,
        language: "da",
        native: (intl) => intl2Str(intl, "Danish"),
    },
    {
        id: "DZ",
        region: (intl) => intl2Str(intl, "Algeria"),
        locale: _dz,
        language: "ar",
        passiv: true,
        native: (intl) => intl2Str(intl, "Arabic"),
    },
    {
        id: "EG",
        region: (intl) => intl2Str(intl, "Egypt"),
        locale: _eg,
        language: "ar",
        native: (intl) => intl2Str(intl, "Arabic"),
    },
    {
        id: "ES",
        region: (intl) => intl2Str(intl, "Spain"),
        locale: _es,
        language: "es",
        native: (intl) => intl2Str(intl, "Spanish"),
    },
    {
        id: "FI",
        region: (intl) => intl2Str(intl, "Finland"),
        locale: _fi,
        language: "fi",
        native: (intl) => intl2Str(intl, "Finnish"),
    },
    {
        id: "FR",
        region: (intl) => intl2Str(intl, "France"),
        locale: _fr,
        language: "fr",
        native: (intl) => intl2Str(intl, "French"),
    },
    {
        id: "GB",
        region: (intl) => intl2Str(intl, "United Kingdom"),
        locale: _gb,
        language: "gb",
        native: (intl) => intl2Str(intl, "British english"),
    },
    {
        id: "GR",
        region: (intl) => intl2Str(intl, "Greece"),
        locale: _el,
        language: "el",
        native: (intl) => intl2Str(intl, "Greek"),
    },
    {
        id: "HK",
        region: (intl) => intl2Str(intl, "Hong Kong"),
        locale: _hk,
        language: "zh-Hans",
        passiv: true,
        native: (intl) => intl2Str(intl, "Chinese (Simplified)"),
    },
    {
        id: "HR",
        region: (intl) => intl2Str(intl, "Croatia"),
        locale: _hr,
        language: "hr",
        native: (intl) => intl2Str(intl, "Croatian"),
    },
    {
        id: "HU",
        region: (intl) => intl2Str(intl, "Hungary"),
        locale: _hu,
        language: "hu",
        native: (intl) => intl2Str(intl, "Hungarian"),
    },
    {
        id: "ID",
        region: (intl) => intl2Str(intl, "Indonesia"),
        locale: _id,
        language: "id",
        native: (intl) => intl2Str(intl, "Indonesian"),
    },
    {
        id: "IE",
        region: (intl) => intl2Str(intl, "Ireland"),
        locale: _ie,
        language: "en",
        passiv: true,
        native: (intl) => intl2Str(intl, "English"),
    },
    {
        id: "IL",
        region: (intl) => intl2Str(intl, "Israel"),
        locale: _he,
        language: "he",
        native: (intl) => intl2Str(intl, "Hebrew"),
    },
    {
        id: "IN",
        region: (intl) => intl2Str(intl, "India"),
        locale: _hi,
        language: "hi",
        native: (intl) => intl2Str(intl, "Hindi"),
    },
    {
        id: "IS",
        region: (intl) => intl2Str(intl, "Iceland"),
        locale: _is,
        language: "is",
        native: (intl) => intl2Str(intl, "Icelandic"),
    },
    {
        id: "IT",
        region: (intl) => intl2Str(intl, "Italy"),
        locale: _it,
        language: "it",
        native: (intl) => intl2Str(intl, "Italian"),
    },
    {
        id: "JP",
        region: (intl) => intl2Str(intl, "Japan"),
        locale: _ja,
        language: "ja",
        native: (intl) => intl2Str(intl, "Japanese"),
    },
    {
        id: "KR",
        region: (intl) => intl2Str(intl, "Korea (South)"),
        locale: _ko,
        language: "ko",
        native: (intl) => intl2Str(intl, "Korean"),
    },
    {
        id: "KZ",
        region: (intl) => intl2Str(intl, "Kazakhstan"),
        locale: _kk,
        language: "kk",
        native: (intl) => intl2Str(intl, "Kazakh"),
    },
    {
        id: "LU",
        region: (intl) => intl2Str(intl, "Luxembourg"),
        locale: _lb,
        language: "lb",
        native: (intl) => intl2Str(intl, "Luxembourgish"),
    },
    {
        id: "MA",
        region: (intl) => intl2Str(intl, "Morocco"),
        locale: _ma,
        language: "ar",
        passiv: true,
        native: (intl) => intl2Str(intl, "Arabic"),
    },
    {
        id: "MX",
        region: (intl) => intl2Str(intl, "Mexico"),
        locale: _es,
        language: "es",
        passiv: true,
        native: (intl) => intl2Str(intl, "Spanish"),
    },
    {
        id: "MY",
        region: (intl) => intl2Str(intl, "Malaysia"),
        locale: _ms,
        language: "ms",
        native: (intl) => intl2Str(intl, "Malay"),
    },
    {
        id: "MZ",
        region: (intl) => intl2Str(intl, "Mozambique"),
        locale: _pt,
        language: "pt",
        passiv: true,
        native: (intl) => intl2Str(intl, "Portuguese"),
    },
    {
        id: "NL",
        region: (intl) => intl2Str(intl, "Netherlands"),
        locale: _nl,
        language: "nl",
        native: (intl) => intl2Str(intl, "Dutch"),
    },
    {
        id: "NO",
        region: (intl) => intl2Str(intl, "Norway"),
        locale: _nn,
        language: "nn",
        native: (intl) => intl2Str(intl, "Norwegian"),
    },
    {
        id: "NZ",
        region: (intl) => intl2Str(intl, "New Zealand"),
        locale: _nz,
        language: "en",
        passiv: true,
        native: (intl) => intl2Str(intl, "English"),
    },
    {
        id: "PH",
        region: (intl) => intl2Str(intl, "Philippines"),
        locale: _us,
        language: "en",
        passiv: true,
        native: (intl) => intl2Str(intl, "English"),
    },
    {
        id: "PL",
        region: (intl) => intl2Str(intl, "Poland"),
        locale: _pl,
        language: "pl",
        native: (intl) => intl2Str(intl, "Polish"),
    },
    {
        id: "PT",
        region: (intl) => intl2Str(intl, "Portugal"),
        locale: _pt,
        language: "pt",
        native: (intl) => intl2Str(intl, "Portuguese"),
    },
    {
        id: "RO",
        region: (intl) => intl2Str(intl, "Romania"),
        locale: _ro,
        language: "ro",
        native: (intl) => intl2Str(intl, "Romanian"),
    },
    {
        id: "RS",
        region: (intl) => intl2Str(intl, "Serbia"),
        locale: _sr,
        language: "sr",
        native: (intl) => intl2Str(intl, "Serbian"),
    },
    {
        id: "RU",
        region: (intl) => intl2Str(intl, "Russia"),
        locale: _ru,
        language: "ru",
        native: (intl) => intl2Str(intl, "Russian"),
    },
    {
        id: "SA",
        region: (intl) => intl2Str(intl, "Saudi arabia"),
        locale: _sa,
        language: "ar",
        passiv: true,
        native: (intl) => intl2Str(intl, "Arabic"),
    },
    {
        id: "SE",
        region: (intl) => intl2Str(intl, "Sweden"),
        locale: _sv,
        language: "sv",
        native: (intl) => intl2Str(intl, "Swedish"),
    },
    {
        id: "SG",
        region: (intl) => intl2Str(intl, "Singapore"),
        locale: _gb,
        language: "en",
        passiv: true,
        native: (intl) => intl2Str(intl, "British english"),
    },
    {
        id: "SI",
        region: (intl) => intl2Str(intl, "Slovenia"),
        locale: _sl,
        language: "sl",
        native: (intl) => intl2Str(intl, "Slovenian"),
    },
    {
        id: "SK",
        region: (intl) => intl2Str(intl, "Slovakia"),
        locale: _sk,
        language: "sk",
        native: (intl) => intl2Str(intl, "Slovak"),
    },
    {
        id: "TH",
        region: (intl) => intl2Str(intl, "Thailand"),
        locale: _th,
        language: "th",
        native: (intl) => intl2Str(intl, "Thai"),
    },
    {
        id: "TN",
        region: (intl) => intl2Str(intl, "Tunisia"),
        locale: _tn,
        language: "ar",
        passiv: true,
        native: (intl) => intl2Str(intl, "Arabic"),
    },
    {
        id: "TR",
        region: (intl) => intl2Str(intl, "Turkey"),
        locale: _tr,
        language: "tr",
        native: (intl) => intl2Str(intl, "Turkish"),
    },
    {
        id: "TW",
        region: (intl) => intl2Str(intl, "Taiwan"),
        locale: _tw,
        language: "zh-Hans",
        passiv: true,
        native: (intl) => intl2Str(intl, "Chinese (Simplified)"),
    },
    {
        id: "US",
        region: (intl) => intl2Str(intl, "United States"),
        locale: _us,
        language: "en",
        native: (intl) => intl2Str(intl, "English"),
    },
    {
        id: "UY",
        region: (intl) => intl2Str(intl, "Uruguay"),
        locale: _es,
        language: "es",
        passiv: true,
        native: (intl) => intl2Str(intl, "Spanish"),
    },
    {
        id: "UZ",
        region: (intl) => intl2Str(intl, "Uzbekistan"),
        locale: _uz,
        language: "uz",
        native: (intl) => intl2Str(intl, "Uzbek"),
    },
    {
        id: "VN",
        region: (intl) => intl2Str(intl, "Vietnam"),
        locale: _vi,
        language: "vi",
        native: (intl) => intl2Str(intl, "Vietnamese"),
    },
    {
        id: "ZA",
        region: (intl) => intl2Str(intl, "South africa"),
        locale: _af,
        language: "af",
        native: (intl) => intl2Str(intl, "Afrikaans"),
    },
];

export const DEFAULT_INTL_LOCALE: IIntlLocale = {
    id: "",
    region: () => "",
    locale: _us,
    language: "",
    native: () => "",
};

export function getIntlLocale(locale: string): IIntlLocale {
    const {language, region} = intl2LocaleParts(locale);

    const intlLocale = intlLocales.find((x) => x.id === region);
    if (!intlLocale) {
        // fallback, if no country assinged by me in intlLocales-array
        return (
            // if you find fallback for language
            // passiv means: german shall be germany, austria would be passiv. english is usa, australia would be passiv
            intlLocales.find((x) => x?.language === language && !x.passiv) ?? {
                ...DEFAULT_INTL_LOCALE,
                language: language as ILanguageId,
                native: () => language,
            }
        );
    }

    return intlLocale;
}
