import "./LicenseFeatures.module.scss";

import {compressToEncodedURIComponent} from "lz-string";
import React from "react";
import {IntlShape, injectIntl} from "react-intl";
import {Link} from "react-router-dom";

import {
    EModuleLicenses,
    ESopLicenses,
    ILicenseDisplay,
} from "@/components/license/models";
import license from "@/services/license";
import {EServerId} from "@shared/models";
import {ILicensedDevice} from "@toolbox/models";
import {IMissingLicenseResponse} from "@toolbox/render-page/models";

import Card from "@toolbox/design/Card";
import T, {intl2Str} from "@translate/T";
import {ILocalizedText} from "@translate/models";

interface ILicenseFeaturesProps {
    intl: IntlShape;
    id: string;
    title: JSX.Element;

    values: (ESopLicenses | EModuleLicenses)[];
    devices: ILicensedDevice[];
    missing: keyof IMissingLicenseResponse;

    getLicensed(
        serverId: EServerId,
        licensed: (ESopLicenses | EModuleLicenses)[],
        devices: ILicensedDevice[],
    ): (ESopLicenses | EModuleLicenses)[];
    getDisplayName(licensed: ESopLicenses | EModuleLicenses): ILocalizedText;
    isNonBuyable(item: ESopLicenses | EModuleLicenses): boolean;
}

class LicenseFeatures extends React.PureComponent<ILicenseFeaturesProps> {
    public render() {
        const {title, id} = this.props;

        return (
            <Card
                className="h-100"
                headerClassName="bg-secondary"
                title={title}
                noMargin={true}
            >
                <div id={id} className="card-body" data-testid={id}>
                    {this.renderModules()}
                </div>
            </Card>
        );
    }

    private renderModules() {
        const {devices, values} = this.props;
        const centDevices = devices
            .filter((x) => license.getSopLicenses([x]).cent)
            .map((x) => x.deviceClass)
            .sort((a, b) => a.localeCompare(b));
        const allDevices = devices.map((x) => x.deviceClass);
        const centTitle = [...new Set(centDevices)].join(" | ");

        if (!values.length) {
            return (
                <em className="text-muted">
                    <T>None</T>
                </em>
            );
        }

        return (
            <React.Fragment>
                {allDevices.includes("LUMiFrac") &&
                    this.renderList("LUMiFrac", EServerId.Fracture)}
                {!!centDevices.length &&
                    this.renderList(centTitle, EServerId.Centrifuge, " mt-2")}
                {allDevices.includes("LUMiSpoc") &&
                    this.renderList("LUMiSpoc", EServerId.Spoc, " mt-2")}
            </React.Fragment>
        );
    }

    private renderList(
        title: string,
        serverId: EServerId,
        className: string = "",
    ) {
        const licensed = this.renderLicenses(serverId);
        if (!licensed.length) {
            return null;
        }

        return (
            <React.Fragment>
                <h5 className={"card-title text-muted mb-1" + className}>
                    {title}
                </h5>
                <ul className="list-unstyled">{licensed}</ul>
            </React.Fragment>
        );
    }

    private renderLicenses(serverId: EServerId) {
        const {
            intl,
            values,
            missing,
            devices,
            getLicensed,
            getDisplayName,
            isNonBuyable,
        } = this.props;

        const isMissing = (x: ESopLicenses | EModuleLicenses) =>
            !values.includes(x) ? [x] : undefined;
        const sorted: ILicenseDisplay[] = getLicensed(serverId, values, devices)
            .map((x) => ({
                name: getDisplayName(x)(intl),
                missing: {[missing]: isMissing(x)},
                nonBuyable: isNonBuyable(x),
            }))
            .sort((a, b) => a.name.localeCompare(b.name));

        return sorted.map((x, i) => this.displayLicenseFeatures(x, i));
    }

    private displayLicenseFeatures(x: ILicenseDisplay, i: number) {
        const {intl} = this.props;
        if (
            !!x.missing.missingModuleLicenses?.length ||
            !!x.missing.missingSopLicenses?.length
        ) {
            if (x.nonBuyable) {
                return null;
            }

            return (
                <li
                    key={i}
                    title={intl2Str(
                        intl,
                        "Available for purchase, click to request",
                    )}
                >
                    <Link
                        styleName="link-color-stale"
                        to={`/missing?data=${compressToEncodedURIComponent(
                            JSON.stringify(x.missing),
                        )}`}
                    >
                        <em>{x.name}</em>
                    </Link>
                </li>
            );
        }

        return <li key={i}>{x.name}</li>;
    }
}

export default injectIntl(LicenseFeatures);
