import {
    faCopy,
    faFileLines,
    faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {boundMethod} from "autobind-decorator";
import React from "react";
import {IntlShape, injectIntl} from "react-intl";
import QRCode from "react-qr-code";

import {blob2ClipBoard, download} from "@/services/download";
import http from "@/services/http";
import {ILocalizedText} from "@translate/models";

import SaveButton from "@toolbox/button-like/SaveButton";
import Card from "@toolbox/design/Card";
import {ILicenseEmailRequest} from "@toolbox/render-page/models";
import T, {intl2Str} from "@translate/T";

interface IBarcodeProps {
    intl: IntlShape;
    mail: string;
    flavorText: JSX.Element;

    noMargin?: boolean;
}

interface IBarcodeState {
    hasMail: boolean;
}

class Barcode extends React.PureComponent<IBarcodeProps, IBarcodeState> {
    public readonly state: IBarcodeState = {
        hasMail: false,
    };

    private get mail() {
        const mail = this.props.mail
            .replace("mailto:", "")
            .replace("?subject=", "\n\n")
            .replace("&body=", "\n\n");
        return new Blob([mail], {type: "text/plain"});
    }

    public async componentDidMount() {
        await this.retrieve();
    }

    @boundMethod
    public async copy2ClipBoard() {
        await blob2ClipBoard(this.mail);
    }

    @boundMethod
    public toTxtFile() {
        download("sepview-activation-request.txt", this.mail);
    }

    @boundMethod
    public async submit() {
        const {mail} = this.props;
        const json: ILicenseEmailRequest = {mail};

        try {
            const response = await http.post("/api/license/email", {json});
            if (!response.ok) {
                throw new http.HTTPError(response);
            }

            return true;
        } catch {
            return false;
        }
    }

    public render() {
        const {mail, flavorText, noMargin} = this.props;

        return (
            <Card
                title={<T>Offline Activation Request</T>}
                renderButtons={this.renderButtons}
                noMargin={noMargin}
            >
                <div className="card-body text-center">
                    <div className="form-row">
                        <div className="col-sm my-sm-auto mb-2">
                            <div>{flavorText}</div>
                            <div>
                                <T>
                                    Either click on the QR Code, or use an
                                    external gadget (like smartphone) to scan
                                    the QR Code.
                                </T>
                            </div>
                        </div>

                        <div className="col-sm-auto">
                            <a href={mail}>
                                <QRCode size={120} value={mail} />
                            </a>
                        </div>
                    </div>
                </div>
            </Card>
        );
    }

    @boundMethod
    private renderButtons() {
        const {intl} = this.props;
        const {hasMail} = this.state;

        const clipboardTitle = intl2Str(
            intl,
            "Open your preferred Email client, prepare new Email and paste the content from clipboard.",
        );
        const autoTitle: ILocalizedText = (_intl) =>
            intl2Str(_intl, "Send by established Email notification settings.");

        return (
            <React.Fragment>
                <button
                    type="button"
                    id="copy-to-clipboard"
                    className="btn btn-primary"
                    title={clipboardTitle}
                    onClick={this.copy2ClipBoard}
                >
                    <FontAwesomeIcon
                        icon={faCopy}
                        fixedWidth={true}
                        className="mr-1"
                    />
                    <T>To clipboard</T>
                </button>
                <button
                    type="button"
                    id="copy-to-txt-file"
                    className="btn btn-primary"
                    onClick={this.toTxtFile}
                >
                    <FontAwesomeIcon
                        icon={faFileLines}
                        fixedWidth={true}
                        className="mr-1"
                    />
                    <T>To .txt file</T>
                </button>

                {hasMail && (
                    <SaveButton
                        type="button"
                        id="save-group"
                        classNameButton="ml-1"
                        otherIcon={faPaperPlane}
                        otherName={<T>Auto request</T>}
                        title={autoTitle}
                        onSave={this.submit}
                    />
                )}
            </React.Fragment>
        );
    }

    private async retrieve() {
        try {
            const response = await http.get("/api/license/email");
            if (!response.ok) {
                throw new http.HTTPError(response);
            }

            this.setState({hasMail: true});
        } catch {
            this.setState({hasMail: false});
        }
    }
}

export default injectIntl(Barcode);
