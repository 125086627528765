import {IPagedList} from "@toolbox/building-blocks/models";

export enum EMaterialTypes {
    Continuous,
    Dispersed,
    Both,
}

export enum EPropertyTypes {
    Viscosity,
    Density,
    Rpri,
    Ipri,
    Attenuation,
    MassCoefficient,
}

export enum EInvalidMaterialProperty {
    None = 0,
    Viscosity = 1,
    Density = 2,
    Rpri = 4,
    Ipri = 8,
    Attenuation = 16,

    All = 31, // just for me
}

export enum ESortColumns {
    None = "",
    Name = "name",
    Author = "author",
}

export interface IMaterialFunctionModel {
    notes: string;
    formula: string;
    minTemperature?: number;
    maxTemperature?: number;
    minWavelength?: number;
    maxWavelength?: number;
}

export interface IMaterialDetailModel extends IMaterialModel {
    notes: string;

    viscosity: IMaterialFunctionModel[];
    density: IMaterialFunctionModel[];
    rpri: IMaterialFunctionModel[];
    ipri: IMaterialFunctionModel[];
    attenuation: IMaterialFunctionModel[];
    massCoefficient: IMaterialFunctionModel[];

    temperature?: number;
    wavelength?: number;
}

export interface IMaterialModel extends IMaterial {
    author: string;

    isOem: boolean;
    isHidden: boolean;
    type: EMaterialTypes;
}

export interface IMaterial {
    id: number;
    name: string;
}

export type IMaterialsResponse = IPagedList<IMaterialModel>;
