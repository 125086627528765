import {boundMethod} from "autobind-decorator";
import {ceil, floor, round} from "lodash";
import React from "react";
import {IntlContext} from "react-intl";

import {EFormulaKeys, FACTOR} from "./models";

import {getDisplayLabel} from "@chart/chart-labels";
import ValueInput from "@toolbox/nativ-inputs/ValueInput";
import {intl2Str} from "@translate/T";
import Formula from "./Formula";

function calculateRca(rpm: number, r: number) {
    return (rpm / 1000) ** 2 * r * FACTOR;
}

function calculateRpm(rca: number, r: number) {
    return round(Math.sqrt(rca / (r * FACTOR)) * 1000);
}

interface IRcaCalculatorProps {
    rpm?: number;
    minRpm?: number;
    maxRpm?: number;

    onRpmChange?(rpm: number): void;
}

interface IRcaCalculatorState {
    r: number;
    rca: number;
    rpm: number;
}

class RcaCalculator extends React.PureComponent<
    IRcaCalculatorProps,
    IRcaCalculatorState
> {
    public readonly state: IRcaCalculatorState = {
        r: 105,
        rca: calculateRca(this.props.rpm ?? 1000, 105),
        rpm: this.props.rpm ?? 1000,
    };

    private readonly minR = 100;
    private readonly maxR = 130;
    private readonly minRpm = 100;
    private readonly maxRpm = 5500;
    private readonly minRca = ceil(calculateRca(this.minRpm, this.minR));
    private readonly maxRca = floor(calculateRca(this.maxRpm, this.maxR));

    @boundMethod
    public onInputChanged(value: number, key: EFormulaKeys) {
        if (key === EFormulaKeys.Rca) {
            this.onRcaChanged(value);
            return;
        }

        let {r, rpm} = this.state;
        if (key === EFormulaKeys.R) {
            r = value;
        } else {
            rpm = value;
        }

        this.updateRca(r, rpm);
    }

    public render() {
        const {minRpm, maxRpm} = this.props;
        const {r, rca, rpm} = this.state;

        return (
            <React.Fragment>
                <div className="jumbotron d-flex justify-content-center">
                    <Formula />
                </div>
                <div className="form-row">
                    <ValueInput<EFormulaKeys>
                        id={EFormulaKeys.Rca}
                        className="col-sm-4"
                        step={1}
                        min={this.minRca}
                        max={this.maxRca}
                        decimals={2}
                        value={rca}
                        header={
                            <IntlContext.Consumer
                                children={getDisplayLabel({
                                    name: (_intl) => intl2Str(_intl, "RCA"),
                                    unit: (_intl) => intl2Str(_intl, "g"),
                                })}
                            />
                        }
                        onChange={this.onInputChanged}
                    />
                    <ValueInput<EFormulaKeys>
                        id={EFormulaKeys.Rpm}
                        className="col-sm-4"
                        step={100}
                        min={minRpm ?? this.minRpm}
                        max={maxRpm ?? this.maxRpm}
                        value={rpm}
                        header={
                            <IntlContext.Consumer
                                children={getDisplayLabel({
                                    name: (_intl) => intl2Str(_intl, "Speed"),
                                    unit: (_intl) => intl2Str(_intl, "RPM"),
                                })}
                            />
                        }
                        onChange={this.onInputChanged}
                    />
                    <ValueInput<EFormulaKeys>
                        id={EFormulaKeys.R}
                        className="col-sm-4"
                        step={1}
                        min={this.minR}
                        max={this.maxR}
                        decimals={2}
                        value={r}
                        header={
                            <IntlContext.Consumer
                                children={getDisplayLabel({
                                    name: (_intl) => intl2Str(_intl, "Radius"),
                                    unit: (_intl) => intl2Str(_intl, "mm"),
                                })}
                            />
                        }
                        onChange={this.onInputChanged}
                    />
                </div>
            </React.Fragment>
        );
    }

    private onRcaChanged(rca: number) {
        const rpm = calculateRpm(rca, this.state.r);
        this.setState({rca, rpm}, this.updatePropsRpm);
    }

    private updateRca(r: number, rpm: number) {
        const rca = calculateRca(rpm, r);
        this.setState({r, rca, rpm}, this.updatePropsRpm);
    }

    @boundMethod
    private updatePropsRpm() {
        this.props.onRpmChange?.(this.state.rpm);
    }
}

export default RcaCalculator;
