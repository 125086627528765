import {isEqual} from "lodash";

import EventHandlers from "@/services/handlers";
import http from "@/services/http";
import signal from "@/services/signal";
import {IAcsvError} from "./models";

interface IEventHandler {
    acsverrorChanged?(errors: IAcsvError[]): void;
}

class ACsvError {
    private readonly events = new EventHandlers<IEventHandler>();

    private _errors: IAcsvError[] = [];

    public constructor() {
        signal.register({
            acsverrorChanged: () => this.retrieve(),
        });
    }

    public get errors() {
        return this._errors;
    }

    public set errors(errors: IAcsvError[]) {
        if (isEqual(this._errors, errors)) {
            return;
        }

        this._errors = errors;
        this.events.publish((x) => x.acsverrorChanged?.(this._errors));
    }

    public async retrieve() {
        try {
            const response = await http
                .get("/api/notification/acsverror", {cache: "no-cache"})
                .json<IAcsvError[]>();

            this.errors = response;
        } catch {
            // keep old version of error list
        }
    }

    public async dismiss(error: IAcsvError) {
        const {id} = error;

        try {
            const response = await http.delete(
                `/api/notification/acsverror/${id}`,
            );
            if (!response.ok) {
                throw new http.HTTPError(response);
            }

            this.errors = this._errors.filter((x) => x.id !== id);
        } catch {
            // keep old version of error list
        }
    }

    public async submitAnyway(error: IAcsvError) {
        const {id} = error;

        try {
            const response = await http.post(
                `/api/notification/acsverror/${id}`,
            );
            if (!response.ok) {
                throw new http.HTTPError(response);
            }

            this.errors = this._errors.filter((x) => x.id !== id);
        } catch {
            // keep old version of error list
        }
    }

    public subscribe(handler: IEventHandler) {
        return this.events.register(handler);
    }
}

const aCsvError = new ACsvError();
export default aCsvError;
