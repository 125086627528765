import {Transition} from "framer-motion";

export const onOpen = {opacity: 1, height: "auto"};
export const onClosed = {opacity: 0, height: 0};
export const animation: Transition = {duration: 0.5};

export interface TableCellSnapshot {
    width: number;
    height: number;
}
