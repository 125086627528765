import {faCheck, faExclamationCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import React from "react";
import {injectIntl, IntlShape} from "react-intl";

import {ELicenseUploadErrors, IFileUploadResult} from "./models";

import T, {intl2Str} from "@translate/T";

interface IUploadResultProps {
    intl: IntlShape;
    value: IFileUploadResult;
}

class UploadResult extends React.PureComponent<IUploadResultProps> {
    public render() {
        const {
            intl,
            value: {name, result},
        } = this.props;

        switch (result) {
            case ELicenseUploadErrors.None:
                return (
                    <li className="text-success">
                        <FontAwesomeIcon
                            icon={faCheck}
                            fixedWidth={true}
                            className="mr-1"
                        />
                        {name}
                    </li>
                );

            case ELicenseUploadErrors.NotALicenseFile:
                return (
                    <li className="text-warning">
                        <FontAwesomeIcon
                            icon={faExclamationCircle}
                            fixedWidth={true}
                            className="mr-1"
                        />
                        {intl2Str(intl, "Not a activation file: {name}", {
                            name,
                        })}
                    </li>
                );

            case ELicenseUploadErrors.InvalidInstallationId:
                return (
                    <li className="text-warning">
                        <FontAwesomeIcon
                            icon={faExclamationCircle}
                            fixedWidth={true}
                            className="mr-1"
                        />
                        {intl2Str(
                            intl,
                            "Installation ID does not match: {name}",
                            {name},
                        )}
                    </li>
                );

            case ELicenseUploadErrors.Expired:
                return (
                    <li className="text-warning">
                        <FontAwesomeIcon
                            icon={faExclamationCircle}
                            fixedWidth={true}
                            className="mr-1"
                        />
                        {intl2Str(
                            intl,
                            "Activation file already expired: {name}",
                            {name},
                        )}
                    </li>
                );

            case ELicenseUploadErrors.Existed:
                return (
                    <li className="text-warning">
                        <FontAwesomeIcon
                            icon={faExclamationCircle}
                            fixedWidth={true}
                            className="mr-1"
                        />
                        {intl2Str(
                            intl,
                            "Activation file already in use: {name}",
                            {name},
                        )}
                    </li>
                );

            case ELicenseUploadErrors.CantSave:
                return (
                    <li className="text-warning">
                        <FontAwesomeIcon
                            icon={faExclamationCircle}
                            fixedWidth={true}
                            className="mr-1"
                        />
                        {intl2Str(intl, "Failed to upload: {name}", {name})}
                    </li>
                );

            case ELicenseUploadErrors.InvalidSerialKey:
                return (
                    <li className="text-warning">
                        <FontAwesomeIcon
                            icon={faExclamationCircle}
                            fixedWidth={true}
                            className="mr-1"
                        />
                        {intl2Str(
                            intl,
                            "Serial key contained in uploaded activation file does not match (activation file still got stored, license inactive): {name}",
                            {name},
                        )}
                    </li>
                );

            case ELicenseUploadErrors.ActivationServerNotReachable:
                return (
                    <li className="text-warning">
                        <FontAwesomeIcon
                            icon={faExclamationCircle}
                            fixedWidth={true}
                            className="mr-1"
                        />
                        <T>
                            Connection to license service failed, please ensure
                            Internet access or use offline activation.
                        </T>
                    </li>
                );

            case ELicenseUploadErrors.OnlineActivationFailed:
                return (
                    <li className="text-warning">
                        <FontAwesomeIcon
                            icon={faExclamationCircle}
                            fixedWidth={true}
                            className="mr-1"
                        />
                        <T>
                            Online activation failed, please use offline
                            activation.
                        </T>
                    </li>
                );

            case ELicenseUploadErrors.Corrupted:
                return (
                    <li className="text-warning">
                        <FontAwesomeIcon
                            icon={faExclamationCircle}
                            fixedWidth={true}
                            className="mr-1"
                        />
                        <T>License file corrupted, please contact service.</T>
                    </li>
                );
        }
    }
}

export default injectIntl(UploadResult);
