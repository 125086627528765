import {boundMethod} from "autobind-decorator";
import React from "react";

import {
    ERoles,
    HTML_PRINT_KEY,
    HTTP_LOGS_KEY,
    HTTP_UNLIMITED,
    SIGNAL_KEY,
    SIGNAL_LOGS_KEY,
    WARNING_KEY,
} from "@/services/models";
import session from "@/services/session";
import signal from "@/services/signal";
import {CLICK} from "@shared/compare/chart/models";

import InlineSwitch from "@toolbox/button-like/InlineSwitch";
import NumberInput from "@toolbox/nativ-inputs/NumberInput";

interface IFooterExtraState {
    showT: boolean;
}

class FooterExtra extends React.PureComponent<{}, IFooterExtraState> {
    public readonly state: IFooterExtraState = {showT: false};

    @boundMethod
    public show(e: React.MouseEvent<HTMLElement, MouseEvent>) {
        if (CLICK(e)) {
            e.preventDefault();

            this.setState({showT: !this.state.showT});
        }
    }

    @boundMethod
    public update(e: React.ChangeEvent<HTMLInputElement>) {
        const id = e.target.id.split("-")[1];
        if (e.target.checked) {
            localStorage.setItem(id, "true");
        } else {
            localStorage.removeItem(id);
        }

        switch (id) {
            case SIGNAL_KEY:
                signal.toggleOnOff();
                return;

            default:
                // not desired
                // window.location.reload();
                return;
        }
    }

    public render() {
        if (!this.state.showT) {
            return this.renderChild();
        }

        return (
            <div className="form-row">
                <div className="col-lg-3">{this.renderChild()}</div>
                <div className="col-lg-2">
                    <NumberInput
                        id="session-override"
                        decimals={0}
                        min={ERoles.None}
                        max={ERoles.GlobalAdministrator}
                        value={session.claims?.global ?? 0}
                        onChange={session.override}
                    />
                </div>
                <div className="col-lg-2">
                    <InlineSwitch
                        idSuffix={SIGNAL_KEY}
                        checked={localStorage.getItem(SIGNAL_KEY) !== null}
                        toggle={this.update}
                    >
                        <React.Fragment>Stop signals</React.Fragment>
                    </InlineSwitch>
                    <InlineSwitch
                        idSuffix={WARNING_KEY}
                        checked={localStorage.getItem(WARNING_KEY) !== null}
                        toggle={this.update}
                    >
                        <React.Fragment>Stop warnings</React.Fragment>
                    </InlineSwitch>
                </div>
                <div className="col-lg-2">
                    <InlineSwitch
                        idSuffix={HTTP_LOGS_KEY}
                        checked={localStorage.getItem(HTTP_LOGS_KEY) !== null}
                        toggle={this.update}
                    >
                        <React.Fragment>Http logs</React.Fragment>
                    </InlineSwitch>
                    <InlineSwitch
                        idSuffix={SIGNAL_LOGS_KEY}
                        checked={localStorage.getItem(SIGNAL_LOGS_KEY) !== null}
                        toggle={this.update}
                    >
                        <React.Fragment>Signal logs</React.Fragment>
                    </InlineSwitch>
                </div>
                <div className="col-lg-2">
                    <InlineSwitch
                        idSuffix={HTML_PRINT_KEY}
                        checked={localStorage.getItem(HTML_PRINT_KEY) !== null}
                        toggle={this.update}
                    >
                        <React.Fragment>Html print</React.Fragment>
                    </InlineSwitch>
                    <InlineSwitch
                        idSuffix={HTTP_UNLIMITED}
                        checked={localStorage.getItem(HTTP_UNLIMITED) !== null}
                        toggle={this.update}
                    >
                        <React.Fragment>Http unlimited</React.Fragment>
                    </InlineSwitch>
                </div>
            </div>
        );
    }

    private renderChild() {
        return <span onDoubleClick={this.show}>{this.props.children}</span>;
    }
}

export default FooterExtra;
