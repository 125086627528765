import {faCopyright, faFileContract} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import React from "react";
import {Link} from "react-router-dom";

import T from "@translate/T";
import UserManual from "./UserManual";

class Help extends React.PureComponent {
    public render() {
        return (
            <li
                className="nav-item dropdown"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
            >
                <Link
                    id="help-menu"
                    className="nav-link dropdown-toggle"
                    aria-expanded="false"
                    aria-haspopup="true"
                    data-toggle="dropdown"
                    role="button"
                    to=""
                >
                    <T>Help</T>
                </Link>

                <div
                    aria-labelledby="help-menu"
                    className="dropdown-menu dropdown-menu-right"
                >
                    <UserManual />

                    <Link to="/eula">
                        <button type="button" className="dropdown-item">
                            <FontAwesomeIcon
                                fixedWidth={true}
                                icon={faFileContract}
                                className="mr-1"
                            />
                            <T>End user license agreement</T>
                        </button>
                    </Link>

                    <Link to="/third-party">
                        <button type="button" className="dropdown-item">
                            <FontAwesomeIcon
                                fixedWidth={true}
                                icon={faCopyright}
                                className="mr-1"
                            />
                            <T>Third party licenses</T>
                        </button>
                    </Link>
                </div>
            </li>
        );
    }
}

export default Help;
