import {boundMethod} from "autobind-decorator";
import React from "react";
import {injectIntl, IntlShape} from "react-intl";
import {Link} from "react-router-dom";

import networkDevices, {IDeviceStatus} from "@/services/network-devices";

import Card from "@toolbox/design/Card";
import Time from "@toolbox/design/Time";
import T, {intl2Num} from "@translate/T";

interface IDeviceProps {
    intl: IntlShape;
    device: IDeviceStatus;
}

class Device extends React.PureComponent<IDeviceProps> {
    public render() {
        const {device, intl} = this.props;

        return (
            <Card
                title={networkDevices.getDeviceName(intl, device.device)}
                headerClassName={`bg-${
                    device.isOnline ? "primary" : "secondary"
                }`}
            >
                <div className="card-body">
                    <div>
                        <span className="mr-1">
                            <T>Connection:</T>
                        </span>
                        {device.isOnline ? <T>Online</T> : <T>Offline</T>}
                    </div>
                    <div>
                        <span className="mr-1">
                            <T>Status:</T>
                        </span>
                        {intl2Num(intl, device.state)}
                    </div>
                    <div>
                        <span className="mr-1">
                            <T>Most recent measurement:</T>
                        </span>
                        {this.renderLastMeasurement()}
                    </div>
                </div>
                <div className="card-footer text-muted">
                    <small>
                        <span className="mr-1">
                            <T>Last activity:</T>
                        </span>
                        <Time value={new Date(device.lastMessage)} />
                    </small>
                </div>
            </Card>
        );
    }

    @boundMethod
    private renderLastMeasurement() {
        const {lastMeasurement} = this.props.device;

        if (!lastMeasurement) {
            return (
                <em>
                    <T>No previous measurement found</T>
                </em>
            );
        }

        return (
            <Link
                to={`/project/${lastMeasurement.project}/centrifuge/measurement/${lastMeasurement.id}`}
                target="_blank"
            >
                {lastMeasurement.name}
            </Link>
        );
    }
}

export default injectIntl(Device);
