import React from "react";

import {EImportProgress, IImportFile} from "./models";

interface IImportProgressProps {
    files: IImportFile[];
}

class Progress extends React.PureComponent<IImportProgressProps> {
    private get status() {
        const status = {
            completed: 0,
            failed: 0,
            pending: 0,
            total: 0,
            working: 0,
        };

        for (const file of this.props.files) {
            status.total++;

            switch (file.progress) {
                case EImportProgress.Pending:
                    status.pending++;
                    break;

                case EImportProgress.Processing:
                    status.working++;
                    break;

                case EImportProgress.Imported:
                    status.completed++;
                    break;

                default:
                    status.failed++;
            }
        }

        if (!status.total) {
            return status;
        }

        status.pending = (status.pending / status.total) * 100;
        status.working = (status.working / status.total) * 100;
        status.completed = (status.completed / status.completed) * 100;
        status.failed = (status.failed / status.total) * 100;

        return status;
    }

    public render() {
        const status = this.status;
        if (status.total === 0) {
            return null;
        }

        return (
            <div className="progress my-2">
                <div
                    role="progressbar"
                    className="progress-bar bg-danger"
                    aria-valuemin={0}
                    aria-valuemax={100}
                    aria-valuenow={status.failed}
                    style={{width: status.failed + "%"}}
                />
                <div
                    role="progressbar"
                    className="progress-bar bg-success"
                    aria-valuemin={0}
                    aria-valuemax={100}
                    aria-valuenow={status.completed}
                    style={{width: status.completed + "%"}}
                />
                <div
                    role="progressbar"
                    className="progress-bar bg-success progress-bar-striped progress-bar-animated"
                    aria-valuemin={0}
                    aria-valuemax={100}
                    aria-valuenow={status.working}
                    style={{width: status.working + "%"}}
                />
                <div
                    role="progressbar"
                    className="progress-bar bg-info"
                    aria-valuemin={0}
                    aria-valuemax={100}
                    aria-valuenow={status.pending}
                    style={{width: status.pending + "%"}}
                />
            </div>
        );
    }
}

export default Progress;
