import {faExclamation, IconDefinition} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {boundMethod} from "autobind-decorator";
import React from "react";

import Modal, {CancelButton} from "@toolbox/modals/Modal";
import T from "@translate/T";

interface IConfirmationModalProps {
    header: JSX.Element;
    noConfirm?: boolean;
    noWarning?: boolean;
    otherIcon?: IconDefinition;
    otherName?: JSX.Element;
    size: "sm" | "md" | "lg" | "xl";

    onSubmit(): void;
    afterClose?(): void;
}

interface IConfirmationModalState {
    show: boolean;
}

class ConfirmationModal extends React.PureComponent<
    IConfirmationModalProps,
    IConfirmationModalState
> {
    public readonly state: IConfirmationModalState = {show: false};

    private readonly ref = React.createRef<Modal>();

    public showModal() {
        this.setState({show: true});
    }

    @boundMethod
    public onSubmit() {
        this.props.onSubmit();
        this.ref.current?.triggerClose();
    }

    @boundMethod
    public afterClose() {
        this.setState({show: false});
    }

    public render() {
        const {
            children,
            header,
            noConfirm,
            noWarning,
            otherIcon,
            otherName,
            size,
        } = this.props;
        if (!this.state.show) {
            return null;
        }

        return (
            <Modal
                ref={this.ref}
                header={header}
                size={size}
                afterClose={this.afterClose}
            >
                <div className="modal-body">
                    <span className={!noWarning ? "text-warning" : undefined}>
                        {children}
                    </span>
                </div>

                <div className="modal-footer">
                    {!noConfirm && (
                        <button
                            type="submit"
                            className="btn btn-primary mr-1"
                            onClick={this.onSubmit}
                        >
                            <FontAwesomeIcon
                                icon={otherIcon ?? faExclamation}
                                fixedWidth={true}
                                className="mr-1"
                            />
                            {otherName ?? <T>Confirm</T>}
                        </button>
                    )}

                    <CancelButton />
                </div>
            </Modal>
        );
    }
}

export default ConfirmationModal;
