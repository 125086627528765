import {boundMethod} from "autobind-decorator";
import {orderBy} from "lodash";
import React from "react";
import {IntlShape, injectIntl} from "react-intl";
import {Link} from "react-router-dom";

import {
    EMaterialTypes,
    IMaterial,
    IMaterialDetailModel,
} from "@/components/materials/models";
import http from "@/services/http";
import resize from "@/services/resize";
import {CARD_PADDING} from "@/styles/_variables";
import {ILocalizedText} from "@translate/models";
import {IFindMaterialsRequest, IFindMaterialsResponse} from "./models";

import InlineCheckbox from "@toolbox/button-like/InlineCheckbox";
import RadioSearchResults from "@toolbox/display-blocks/RadioSearchResults";
import Modal, {CancelButton} from "@toolbox/modals/Modal";
import SearchInput from "@toolbox/nativ-inputs/SearchInput";
import T, {intl2Str} from "@translate/T";
import MaterialDetails from "./MaterialDetails";

interface IMaterialModalProps {
    intl: IntlShape;
    id: string;

    selected?: IMaterial;
    material?: IMaterialDetailModel;

    temperature?: number;
    wavelength?: number;

    materialWarning?: JSX.Element | null;
    optional?: boolean; // set to true to allow no material
    overrideModalTitle?: ILocalizedText;
    showAll?: boolean;

    onChange(material?: IMaterial): void;
    onClose(): void;
}

interface IMaterialModalState {
    query: string;
    options: IMaterial[];
    showAll: boolean;

    height: number;
}

class MaterialModal extends React.PureComponent<
    IMaterialModalProps,
    IMaterialModalState
> {
    public readonly state: IMaterialModalState = {
        query: "",
        options: [],
        showAll: this.props.showAll ?? false,
        height: 400,
    };

    private readonly modal = React.createRef<Modal>();
    private readonly maxHeight = React.createRef<HTMLDivElement>();
    private readonly search = React.createRef<HTMLDivElement>();
    private readonly footer = React.createRef<HTMLDivElement>();

    private unsubscribe?: () => void;

    private get maxContainerHeight() {
        const brother = this.maxHeight.current?.clientHeight ?? 0; // brother-height
        const header = this.modal.current?.getHeader()?.offsetHeight ?? 0; // offset cause of border
        const cardPadding = 2 * CARD_PADDING;
        const search = this.search.current?.clientHeight ?? 0; // searchbar-height
        const searchMargin = 16;
        const footer = this.footer.current?.offsetHeight ?? 0; // offset cause of border

        // only substract in fullscreen what is visible
        if (this.modal.current?.isFullscreen) {
            return (
                window.screen.height -
                header -
                cardPadding -
                search -
                searchMargin -
                footer
            );
        }

        return Math.max(400, brother - search - searchMargin);
    }

    public componentDidMount() {
        const unResize = resize.subscribe(this.maxHeight.current!, () =>
            this.setState({height: this.maxContainerHeight}),
        );

        this.loadOptions("");

        this.unsubscribe = () => {
            unResize();
        };
    }

    public componentWillUnmount() {
        this.unsubscribe?.();
    }

    @boundMethod
    public onSearch(query: string) {
        this.setState({query}, () => this.loadOptions(query));
    }

    @boundMethod
    public toggleShowAll(checked: boolean) {
        this.setState({showAll: checked}, () =>
            this.loadOptions(this.state.query),
        );
    }

    public render() {
        const {
            selected,
            material,
            materialWarning,
            optional,
            onClose,
            onChange,
        } = this.props;
        const {query, options, height} = this.state;

        return (
            <Modal
                ref={this.modal}
                canFullscreen={true}
                header={this.renderTitle()}
                size="xl"
                afterClose={onClose}
            >
                <div className="modal-body">
                    <div className="form-row h-100">
                        <div className="col-md-3">
                            <div ref={this.search}>
                                <SearchInput
                                    autofocus={true}
                                    query={query}
                                    onSearch={this.onSearch}
                                />
                            </div>

                            <RadioSearchResults<IMaterial>
                                selectedId={selected?.id.toString()}
                                options={options}
                                emptyText={optional ? <T>None</T> : undefined}
                                height={height}
                                onChange={onChange}
                                getName={this.renderName}
                                getId={this.renderId}
                            />
                        </div>
                        <div className="col-md-9">
                            <div ref={this.maxHeight}>
                                {this.renderSubTitle()}

                                <MaterialDetails
                                    selected={selected}
                                    material={material}
                                    materialWarning={materialWarning}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div ref={this.footer} className="modal-footer">
                    <Link className="mr-auto" to="/materials" target="_blank">
                        <T>View materials list</T>
                    </Link>

                    <CancelButton>
                        <T>Close</T>
                    </CancelButton>
                </div>
            </Modal>
        );
    }

    private renderTitle() {
        const {id, overrideModalTitle, intl} = this.props;
        const type = id.startsWith("fluid") ? (
            <T>Continuous</T>
        ) : (
            <T>Dispersed</T>
        );

        if (overrideModalTitle) {
            return overrideModalTitle(intl);
        }

        return (
            <React.Fragment>
                {type} <T>Material</T>
            </React.Fragment>
        );
    }

    private renderSubTitle() {
        const {showAll} = this.state;
        const title: ILocalizedText = (intl) =>
            intl2Str(intl, "If checked, you will see all materials.");

        return (
            <div className="d-flex mb-2" style={{height: "35px"}}>
                <h2
                    className="mr-auto" // not smaller than h2, since the input has the same height
                >
                    <T>Material details</T>
                </h2>

                <InlineCheckbox
                    idSuffix="material-show-all"
                    title={title}
                    checked={showAll}
                    toggle={this.toggleShowAll}
                >
                    <T>Show all</T>
                </InlineCheckbox>
            </div>
        );
    }

    private renderName(value: IMaterial) {
        return value.name;
    }

    private renderId(value: IMaterial) {
        return value.id.toString();
    }

    private async loadOptions(query: string) {
        const {id, temperature, wavelength} = this.props;
        const {showAll} = this.state;

        let type = id.startsWith("fluid")
            ? EMaterialTypes.Continuous
            : EMaterialTypes.Dispersed;
        if (showAll) {
            type = EMaterialTypes.Both;
        }

        const json: IFindMaterialsRequest = {
            maxResults: -1,
            query,
            type,
            temperature: temperature ?? 0,
            wavelength: wavelength ?? 0,
        };

        let options: IMaterial[] = [];
        try {
            const response = await http
                .post("/api/material/find", {json})
                .json<IFindMaterialsResponse>();

            const ordered = orderBy(response.matches, ["name"]);
            options = ordered.map((x) => ({name: x.name, id: x.id}));
        } catch {
            options = [];
        }

        this.setState({options});
    }
}

export default injectIntl(MaterialModal);
