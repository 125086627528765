import {faVials} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {boundMethod} from "autobind-decorator";
import React from "react";

import {ICombineModel} from "./models";

import T from "@translate/T";
import Combine from "./Combine";

interface ICombineButtonProps {
    model: ICombineModel;
}

interface ICombineButtonState {
    show: boolean;
}

class CombineButton extends React.PureComponent<
    ICombineButtonProps,
    ICombineButtonState
> {
    public readonly state: ICombineButtonState = {show: false};

    @boundMethod
    public showCombineModal(e: React.SyntheticEvent) {
        e.preventDefault();

        this.setState({show: true});
    }

    @boundMethod
    public hideCombineModal() {
        this.setState({show: false});
    }

    public render() {
        return (
            <React.Fragment>
                <button
                    type="button"
                    className="btn btn-secondary mr-1"
                    onClick={this.showCombineModal}
                >
                    <FontAwesomeIcon
                        icon={faVials}
                        fixedWidth={true}
                        className="mr-1"
                    />
                    <T>Combine</T>
                </button>
                {this.renderCombineModal()}
            </React.Fragment>
        );
    }

    private renderCombineModal() {
        if (!this.state.show) {
            return null;
        }

        return (
            <Combine
                model={this.props.model}
                afterClose={this.hideCombineModal}
            />
        );
    }
}

export default CombineButton;
