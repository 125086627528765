import React from "react";
import {IntlContext, IntlShape} from "react-intl";

import {ILocalizedText} from "@translate/models";
import {boundMethod} from "autobind-decorator";

interface ICustomizeLabelProps {
    label?: JSX.Element | ILocalizedText;
    htmlFor?: string;
    title?: ILocalizedText;

    labelNumber?: number;
    half?: boolean;
    noMargin?: boolean;
    topMargin?: boolean;
    btnGroup?: boolean;
}

class CustomizeLabel extends React.PureComponent<ICustomizeLabelProps> {
    public render() {
        const {half, noMargin, topMargin} = this.props;

        if (half) {
            return <IntlContext.Consumer children={this.renderInner} />;
        }

        let margin = " mb-2";
        if (noMargin) {
            margin = "";
        } else if (topMargin) {
            margin = " mt-2";
        }

        return (
            <div className={`form-row${margin}`}>
                <IntlContext.Consumer children={this.renderInner} />
            </div>
        );
    }

    @boundMethod
    private renderInner(intl: IntlShape) {
        const {btnGroup, children, half, htmlFor, label, labelNumber, title} =
            this.props;

        const _labelNumber = half ? 3 : labelNumber ?? 4;
        const _otherNumber = half ? 3 : 12 - _labelNumber;
        const className1 = "col-sm-" + _labelNumber;
        let className2 = "col-sm-" + _otherNumber;
        if (btnGroup) {
            className2 = "col-sm";
        }

        if (!label) {
            const className =
                className2 + " " + className1.replace("col", "offset");

            return <div className={className}>{children}</div>;
        }

        return (
            <React.Fragment>
                <div className={"d-flex " + className1} title={title?.(intl)}>
                    <label
                        className="d-flex align-items-center"
                        htmlFor={htmlFor}
                    >
                        {typeof label === "function" ? label(intl) : label}
                    </label>
                </div>
                <div className={className2}>{children}</div>
            </React.Fragment>
        );
    }
}

export default CustomizeLabel;
