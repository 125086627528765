import {boundMethod} from "autobind-decorator";
import React from "react";

import display from "@/services/display-name";
import {ERoles} from "@/services/models";
import session from "@/services/session";
import {IUserDetails} from "./models";

import ContainerCol from "@toolbox/display-blocks/ContainerCol";
import Loading from "@toolbox/render-page/Loading";
import NotFound from "@toolbox/render-page/NotFound";
import T from "@translate/T";
import AccountSettings from "./AccountSettings";
import RolesTable from "./RolesTable";

interface IAccountState {
    userDetail?: IUserDetails;
    isNotFound: boolean;
}

class Account extends React.PureComponent<{}, IAccountState> {
    public readonly state: IAccountState = {
        isNotFound: false,
    };

    public async componentDidMount() {
        await this.retrieveUser(session.user ?? "");
    }

    @boundMethod
    public onChange(changes: Partial<IUserDetails>) {
        const {userDetail} = this.state;
        if (!userDetail) {
            return;
        }

        this.setState({userDetail: {...userDetail, ...changes}});
    }

    public render() {
        const {isNotFound, userDetail} = this.state;

        if (isNotFound) {
            return (
                <NotFound id={session.user} item={<T>The specified user</T>} />
            );
        }

        if (!userDetail) {
            return <Loading />;
        }

        return (
            <ContainerCol col={8}>
                <h3>
                    <T>Acccount settings</T>
                </h3>
                <hr />

                <AccountSettings
                    user={session.user ?? ""}
                    redirectUrl=""
                    userDetail={userDetail}
                    onUserChance={this.onChange}
                >
                    {this.renderExtraContent(userDetail)}
                </AccountSettings>
            </ContainerCol>
        );
    }

    private renderExtraContent(userDetail: IUserDetails) {
        const groupsUrl = session.hasRole(ERoles.Administrator)
            ? "admin/groups"
            : undefined;

        return (
            <RolesTable
                roles={userDetail.roles}
                groups={userDetail.groups}
                projects={userDetail.projects}
                groupsUrl={groupsUrl}
            />
        );
    }

    private async retrieveUser(username: string) {
        const userDetail = await display.getUserDetails(username);

        if (!userDetail) {
            this.setState({isNotFound: true});
            return;
        }

        this.setState({userDetail});
    }
}

export default Account;
