import {ILanguageId} from "@/services/models";

export const NAVBAR_HEIGHT = 42 - 2; // 2px for smooth transition, in sync with _customization.scss sticky-top or navbar
export const STICKY_MARGIN = 8; // 8px === mb-2
export const DEFAULT_LANGUAGE: ILanguage = {id: "en", name: "English"}; // do not translate this

export interface ILanguage {
    id: ILanguageId;
    name: string;
}
