import {mean} from "d3-array";
import {color, lch, rgb} from "d3-color";
import {
    interpolateCividis,
    interpolateInferno,
    interpolatePlasma,
    interpolateRdYlGn,
    interpolateSinebow,
    interpolateTurbo,
    interpolateViridis,
    interpolateWarm,
} from "d3-scale-chromatic";

import {
    EDataColorSchemes,
    ESampleColorSchemes,
} from "@/components/preferences/models";

export function getColorScheme(scheme: ESampleColorSchemes) {
    switch (scheme) {
        default:
        case ESampleColorSchemes.OldSchool:
            return [
                "#000000",
                "#ff0000",
                "#0000ff",
                "#730000",
                "#32cd32",
                "#007300",
                "#66cccc",
                "#007373",
                "#404040",
                "#000073",
                "#f79646",
                "#dc680e",
                "#0070c0",
                "#17375e",
                "#c65551",
                "#8e3135",
                "#9ebe5c",
                "#6c8831",
                "#8367a5",
                "#6d2d9d",
                "#948a54",
                "#4a452a",
                "#e0c106",
                "#737300",
            ];

        case ESampleColorSchemes.Modern:
            return [
                "#7cb5ec",
                "#434348",
                "#90ed7d",
                "#f7a35c",
                "#8085e9",
                "#f15c80",
                "#e4d354",
                "#8085e8",
                "#8d4653",
                "#91e8e1",
                "#4e79a7",
                "#f28e2b",
                "#e15759",
                "#76b7b2",
                "#59a14f",
                "#edc948",
                "#b07aa1",
                "#ff9da7",
                "#9c755f",
                "#bab0ac",
            ];

        case ESampleColorSchemes.Retro:
            return [
                "#1f77b4",
                "#ff7f0e",
                "#2ca02c",
                "#d62728",
                "#9467bd",
                "#8c564b",
                "#e377c2",
                "#7f7f7f",
                "#bcbd22",
                "#17becf",
                "#aec7e8",
                "#ffbb78",
                "#98df8a",
                "#ff9896",
                "#c5b0d5",
                "#c49c94",
                "#f7b6d2",
                "#c7c7c7",
                "#dbdb8d",
                "#9edae5",
            ];

        case ESampleColorSchemes.Grayscale:
            return [
                "#222222",
                "#666666",
                "#aaaaaa",
                "#333333",
                "#777777",
                "#bbbbbb",
                "#444444",
                "#888888",
                "#cccccc",
                "#555555",
                "#999999",
                "#dddddd",
            ];
    }
}

export function invertColor(col: string) {
    const parsed = rgb(col);
    const inverted = rgb(
        255 - parsed.r,
        255 - parsed.g,
        255 - parsed.b,
        parsed.opacity,
    );

    return inverted.toString();
}

// inverts color and cuts to black or white
export function blackXwhite(col: string) {
    const parsed = rgb(col);
    const a = mean([parsed.r, parsed.g, parsed.b]) ?? 0;

    if (a - 128 >= 0) {
        return "#000000";
    } else {
        return "#ffffff";
    }
}

export function hex2rgb(col: string) {
    const parsed = rgb(col);
    return [parsed.r / 255, parsed.g / 255, parsed.b / 255];
}

export function rgb2hex(col: number[], opacity?: number) {
    const parsed = rgb(col[0] * 255, col[1] * 255, col[2] * 255, opacity);
    return parsed.formatHex();
}

export function rgb2NativColorString(col: number[]) {
    const parsed = rgb(col[0] * 255, col[1] * 255, col[2] * 255);
    return parsed.toString();
}

export function brightenColor(col: string) {
    const parsed = lch(rgb(col));
    const brightened = parsed.brighter(2);

    return brightened.toString();
}

// get LUM color scheme color, value beween 0-1, with 0 being the first profile, 1 the last
function getLumColor(index: number) {
    return [1 - index, index, 0];
}

function getCividisColor(index: number) {
    const col = color(interpolateCividis(1 - index))!.rgb();
    return [col.r / 255, col.g / 255, col.b / 255];
}

function getInfernoColor(index: number) {
    const col = color(interpolateInferno(1 - index))!.rgb();
    return [col.r / 255, col.g / 255, col.b / 255];
}

function getPlasmaColor(index: number) {
    const col = color(interpolatePlasma(1 - index))!.rgb();
    return [col.r / 255, col.g / 255, col.b / 255];
}

function getRdYlGnColor(index: number) {
    const col = color(interpolateRdYlGn(index))!.rgb();
    return [col.r / 255, col.g / 255, col.b / 255];
}

function getSinebowColor(index: number) {
    const col = color(interpolateSinebow(index))!.rgb();
    return [col.r / 255, col.g / 255, col.b / 255];
}

function getTurboColor(index: number) {
    const col = color(interpolateTurbo(1 - index))!.rgb();
    return [col.r / 255, col.g / 255, col.b / 255];
}

function getViridisColor(index: number) {
    const col = color(interpolateViridis(1 - index))!.rgb();
    return [col.r / 255, col.g / 255, col.b / 255];
}

function getWarmColor(index: number) {
    const col = color(interpolateWarm(1 - index))!.rgb();
    return [col.r / 255, col.g / 255, col.b / 255];
}

// index in the range [0,1]
export function getColorHandler(scheme: EDataColorSchemes) {
    let handler: (x: number) => number[];
    switch (scheme) {
        case EDataColorSchemes.ColorBlind:
            handler = getCividisColor;
            break;

        case EDataColorSchemes.Inferno:
            handler = getInfernoColor;
            break;

        case EDataColorSchemes.Plasma:
            handler = getPlasmaColor;
            break;

        case EDataColorSchemes.RdYlGn:
            handler = getRdYlGnColor;
            break;

        case EDataColorSchemes.Sinebow:
            handler = getSinebowColor;
            break;

        case EDataColorSchemes.Turbo:
            handler = getTurboColor;
            break;

        case EDataColorSchemes.Viridis:
            handler = getViridisColor;
            break;

        case EDataColorSchemes.Warm:
            handler = getWarmColor;
            break;

        default:
            handler = getLumColor;
            break;
    }

    return (index: number) => {
        if (isNaN(index)) {
            // zero profile
            return [0, 0, 1];
        }

        return handler(index);
    };
}
