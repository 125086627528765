import {ERoles} from "@/services/models";
import {IPagedList} from "@toolbox/building-blocks/models";

export enum EDocumentTypes {
    Unknown,
    Project,
    User,
    Group,
    Stamp,
    Material,
    Template,
    Sop,
    Measurement,
    Analysis,
    Report,
    Alignment,
    Hindrance,
}

export enum ESearchQueries {
    // url enum
    Sops = "type:sop",
    Measurements = "type:measurement",
    Analyses = "type:analysis",
}

export interface IRoleAssignment {
    role: ERoles;
    target: string;
}

export interface IProjectModel {
    id: number;
    name: string;
    isHidden: boolean;
}

export interface IProjectModelWithRoles extends IProjectModel {
    users: IRoleAssignment[];
    groups: IRoleAssignment[];
}

export interface IProjectStats extends IProjectModel {
    sops: number;
    analyses: number;
    measurements: number;
}

export type ISearchResult = IPagedList<{name: string; displayName?: string}>;
