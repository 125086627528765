import {boundMethod} from "autobind-decorator";
import React from "react";
import {IntlContext, IntlShape} from "react-intl";

import {intlLocales} from "@/services/intl-locale";
import {IIntlLocale, IRegionId} from "@/services/models";
import {ISelectValue} from "@toolbox/button-like/models";
import {ILocalizedText} from "@translate/models";

import InlineSelect from "@toolbox/button-like/InlineSelect";
import Time from "@toolbox/design/Time";
import {getSeperator} from "@toolbox/functions/csv";
import T, {intl2Num, intl2Str} from "@translate/T";

interface IRegionsProps<TValue> {
    value: string;
    others?: IIntlLocale<TValue>[];
    otherMargin?: string;
    intl4NumberRegion?: IntlShape;
    disabled?: boolean;
    tooltip?: ILocalizedText;

    onChange(value: string): void;
}

class Regions<TValue = IRegionId> extends React.PureComponent<
    IRegionsProps<TValue>
> {
    private get regions(): IIntlLocale[] {
        const {others} = this.props;

        if (others) {
            return [
                ...(others as unknown as IIntlLocale<IRegionId>[]),
                ...intlLocales,
            ];
        }

        return intlLocales;
    }

    public render() {
        const {otherMargin} = this.props;

        return (
            <div className={"form-row " + (otherMargin ?? "mb-2")}>
                <IntlContext.Consumer children={this.renderDropdown} />
                <div className="col-sm-2 align-self-center text-center">
                    <IntlContext.Consumer children={this.renderNumber} />
                </div>
                <div className="col-sm-3 align-self-center text-center">
                    <IntlContext.Consumer children={this.renderSeperator} />
                </div>
                <div className="col-sm-3 align-self-center text-center">
                    {this.renderTime()}
                </div>
            </div>
        );
    }

    @boundMethod
    private renderDropdown(intl: IntlShape) {
        const {disabled, onChange, others, tooltip, value} = this.props;
        const title: ILocalizedText = (_intl) =>
            intl2Str(_intl, "Applies to date, time, and numbers.");
        const values = intlLocales
            .map(({id, region}) => ({id, name: region(intl)}))
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((x) => x.id);

        // add default option
        values.unshift("");

        // shall not be sorted, but on top
        if (others) {
            values.unshift(
                ...(others as unknown as IIntlLocale<IRegionId>[]).map(
                    (x) => x.id,
                ),
            );
        }

        return (
            <InlineSelect<string>
                idSuffix="predefined-region"
                classNameLabel="col-sm-2 d-flex align-items-center"
                classNameDiv="col-sm-2"
                disabled={disabled}
                label={<T>Number Format</T>}
                selected={value}
                title={tooltip ?? title}
                onSelected={onChange}
                convert={this.convert}
                values={values}
            />
        );
    }

    @boundMethod
    private renderNumber(intl: IntlShape) {
        const {intl4NumberRegion} = this.props;

        return intl2Num(intl4NumberRegion ?? intl, 1234567.89, undefined, {
            notation: "standard",
        });
    }

    @boundMethod
    private renderSeperator(intl: IntlShape) {
        const {intl4NumberRegion} = this.props;
        const separator = getSeperator(intl4NumberRegion ?? intl);
        const char =
            separator === ","
                ? intl2Str(intl, "comma")
                : intl2Str(intl, "semicolon");

        return intl2Str(intl, "{char} as csv separator", {
            char: separator + " (" + char + ")",
        });
    }

    private renderTime() {
        const {intl4NumberRegion} = this.props;

        return (
            <Time value={new Date()} intl4NumberRegion={intl4NumberRegion} />
        );
    }

    @boundMethod
    private convert(id: string): ISelectValue {
        const region = this.regions.find((x) => x.id === id);
        if (!region) {
            return {
                id,
                name: (intl) => intl2Str(intl, "Default"),
            };
        }

        return {id: region.id, name: region.region};
    }
}

export default Regions;
