import {faMinus, faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {boundMethod} from "autobind-decorator";
import {clamp, debounce, kebabCase, round} from "lodash";
import React from "react";
import {FormatNumberOptions, IntlShape, injectIntl} from "react-intl";

import {ILocalizedText} from "@translate/models";
import {IDLE_DELAY} from "../models";

import {intl2Num, intl2Str, parseNumber} from "@translate/T";

function getText(value: number | null, props: INumberInputProps) {
    const {decimals, intl, noTrailingZero, options} = props;
    const decimal = noTrailingZero || options ? undefined : decimals ?? 2;
    if (typeof value !== "number") {
        return "";
    }

    return intl2Num(intl, value, decimal, {
        maximumFractionDigits: decimals ?? 2,
        useGrouping: false,
        ...options,
    });
}

export interface INumberInputProps {
    intl: IntlShape;
    id: string;
    value: number | null;

    min?: number;
    max?: number;
    step?: number; // for +/- buttons, default: 1
    decimals?: number; // fraction digits, default: 2
    optional?: boolean; // set to true to allow null value
    className?: string; // additional class names for the input element
    classNameOverride?: string; // will only take this className
    classNameDiv?: string; // additional class names for the outer div element
    focus?: boolean; // Mount class with focus in input

    title?: ILocalizedText;
    placeholder?: ILocalizedText;
    inputOnly?: boolean;
    disabled?: boolean;
    disableWheelEvents?: boolean;
    invalid?: boolean;
    warning?: boolean;
    options?: FormatNumberOptions;
    noTrailingZero?: boolean;

    onChange(value: number | null, id: string): void;
    detectCancel?(): void;
    specialRound?(value: number, decimals?: number): number;
}

interface INumberInputState {
    text: string;
    previousIntl?: IntlShape;
    previousValue: number | null;
}

class NumberInput extends React.PureComponent<
    INumberInputProps,
    INumberInputState
> {
    public readonly state: INumberInputState = {previousValue: 0, text: "0"};

    private readonly input = React.createRef<HTMLInputElement>();
    private readonly reset = debounce(this.resetText, IDLE_DELAY);

    public static getDerivedStateFromProps(
        props: INumberInputProps,
        state: INumberInputState,
    ): Partial<INumberInputState> {
        if (
            props.value === state.previousValue &&
            props.intl === state.previousIntl
        ) {
            return {};
        }

        return {
            previousIntl: props.intl,
            previousValue: props.value,
            text: getText(props.value, props),
        };
    }

    public componentDidMount() {
        if (this.props.focus) {
            this.input.current?.focus();
        }
    }

    public componentDidUpdate() {
        this.reset.cancel();
    }

    public componentWillUnmount() {
        this.reset.cancel();
    }

    @boundMethod
    public setText(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();

        this.setState({text: e.target.value});
    }

    @boundMethod
    public increase(e: React.SyntheticEvent) {
        e.preventDefault();

        this.stepChange(this.props.step ?? 1);
    }

    @boundMethod
    public decrease(e: React.SyntheticEvent) {
        e.preventDefault();

        this.stepChange(-(this.props.step ?? 1));
    }

    @boundMethod
    public clear(e: React.SyntheticEvent) {
        e.preventDefault();

        const {onChange, id} = this.props;
        this.reset();
        onChange(null, id);
    }

    @boundMethod
    public commitTextValue() {
        const {optional, value, onChange, id} = this.props;
        const textValue = this.getValueFromText();

        // value changed, notify
        if ((optional || textValue !== null) && textValue !== value) {
            this.reset();
            onChange(textValue, id);
            return;
        }

        // value not changed, reset text
        this.resetText();
    }

    @boundMethod
    public handleWheel(e: React.WheelEvent<HTMLInputElement>) {
        if (
            this.props.disableWheelEvents ||
            e.target !== document.activeElement
        ) {
            // only change value if focused
            return;
        }

        e.preventDefault();

        if (e.deltaY < 0) {
            this.increase(e);
        } else if (e.deltaY > 0) {
            this.decrease(e);
        }
    }

    @boundMethod
    public handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        switch (e.key) {
            case "PageUp":
            case "ArrowUp":
                this.increase(e);
                break;

            case "PageDown":
            case "ArrowDown":
                this.decrease(e);
                break;

            case "Escape":
                e.preventDefault();

                // note: this triggers after modal closes
                this.resetText();
                this.props.detectCancel?.();
                break;

            case "Enter":
                e.preventDefault();

                this.commitTextValue();
                break;
        }
    }

    public render() {
        const {disabled, id, inputOnly, classNameDiv} = this.props;

        if (inputOnly) {
            return this.renderInput();
        }

        const className =
            "input-group" + (classNameDiv ? " " + classNameDiv : "");

        return (
            <div className={className}>
                <span className="input-group-prepend">
                    <button
                        type="button"
                        className="btn btn-sm btn-secondary"
                        aria-label={"decrease-" + id}
                        data-role="decrease"
                        disabled={disabled}
                        tabIndex={-1}
                        onClick={this.decrease}
                    >
                        <FontAwesomeIcon icon={faMinus} fixedWidth={true} />
                    </button>
                </span>

                {this.renderInput()}

                <span className="input-group-append">
                    {this.renderClearButton()}

                    <button
                        type="button"
                        className="btn btn-sm btn-secondary"
                        aria-label={"increase-" + id}
                        data-role="increase"
                        disabled={disabled}
                        tabIndex={-1}
                        onClick={this.increase}
                    >
                        <FontAwesomeIcon icon={faPlus} fixedWidth={true} />
                    </button>
                </span>
            </div>
        );
    }

    private renderInput() {
        const {
            className,
            classNameOverride,
            disabled,
            id,
            intl,
            invalid,
            optional,
            placeholder,
            title,
            value,
            warning,
        } = this.props;
        const text =
            placeholder?.(intl) ??
            (optional ? intl2Str(intl, "Not set") : getText(value, this.props));

        let inputClassName =
            classNameOverride ??
            "form-control" + (className ? " " + className : "");
        if (invalid) {
            inputClassName += " is-invalid";
        } else if (warning) {
            inputClassName += " is-invalid-warning";
        }

        return (
            <input
                ref={this.input}
                type="text"
                id={kebabCase(id)}
                data-testid={kebabCase(id)}
                className={inputClassName}
                autoComplete="off"
                disabled={disabled}
                onChange={this.setText}
                placeholder={text}
                required={!optional}
                title={title?.(intl)}
                value={this.state.text}
                // onWheel={this.handleWheel}
                onBlur={this.commitTextValue}
                onKeyDown={this.handleKeyDown}
            />
        );
    }

    private renderClearButton() {
        const {disabled, optional} = this.props;
        if (!optional) {
            return null;
        }

        return (
            <button
                type="button"
                className="btn btn-sm btn-secondary"
                aria-label="clear"
                data-role="clear"
                disabled={disabled}
                tabIndex={-1}
                onClick={this.clear}
            >
                <FontAwesomeIcon icon={faTimes} fixedWidth={true} />
            </button>
        );
    }

    private stepChange(step: number) {
        const props = this.props;
        const {max, min, value} = props;
        let text;
        if (typeof value === "number") {
            // add step
            text = value + step;
        } else if (typeof min === "number") {
            // currently null, uses min value
            text = min;
        } else if (typeof max === "number") {
            // currently null, uses max value
            text = max;
        } else {
            // null and no value, use zero
            text = 0;
        }

        this.setState({text: getText(text, props)}, this.commitTextValue);
    }

    private getValueFromText() {
        const {optional, value, decimals, max, min, specialRound} = this.props;
        const {text} = this.state;

        // empty value after focus
        if (text === "") {
            return null;
        }

        let updated = parseNumber(text);

        // invalid value
        if (isNaN(updated)) {
            if (optional) {
                return null;
            }

            return value;
        }

        // min max clamping
        updated = clamp(updated, min ?? -Infinity, max ?? Infinity);

        return (
            specialRound?.(updated, decimals ?? 2) ??
            round(updated, decimals ?? 2)
        );
    }

    @boundMethod
    private resetText() {
        const props = this.props;
        this.setState({text: getText(props.value, props)});
    }
}

export default injectIntl(NumberInput);
