import {boundMethod} from "autobind-decorator";
import React from "react";
import {Link} from "react-router-dom";

import languages from "@/services/languages";
import {ERoles} from "@/services/models";
import session from "@/services/session";
import sleep from "@/services/sleep";

import {scroll2View} from "@toolbox/button-like/ValidatedForm";
import WarningAlert from "@toolbox/design/WarningAlert";
import T from "@translate/T";

interface ILanguageInfoState {
    update: boolean;
    applied: boolean;
}

class LanguageInfo extends React.PureComponent<{}, ILanguageInfoState> {
    public readonly state: ILanguageInfoState = {
        update: languages.update,
        applied: languages.applied,
    };

    private unsubscribe?: () => void;

    public async componentDidMount() {
        this.unsubscribe = languages.subscribe({
            updatesChanged: (update, applied) =>
                this.setState({update, applied}),
        });
    }

    public componentWillUnmount() {
        this.unsubscribe?.();
    }

    @boundMethod
    public async onUpdateClick(e: React.SyntheticEvent) {
        // do not preventDefault, cause of link

        const ele = document.getElementById("get-request-language");
        if (!ele) {
            await sleep(1);
            await this.onUpdateClick(e);
            return;
        }

        await scroll2View(ele, false, "get-request-language");
    }

    @boundMethod
    public onDismissUpdateClick(e: React.SyntheticEvent) {
        e.preventDefault();

        languages.dismiss({update: true});
    }

    @boundMethod
    public onDismissAppliedClick(e: React.SyntheticEvent) {
        e.preventDefault();

        languages.dismiss({applied: true});
    }

    public render() {
        const {update, applied} = this.state;
        if (!update && !applied) {
            return null;
        }

        return (
            <React.Fragment>
                {update && (
                    <WarningAlert
                        otherBg="alert-info"
                        renderElement={
                            <T>
                                There is a new language update. Please contact
                                your administrator.
                            </T>
                        }
                    >
                        {this.renderUpdateButtons()}
                    </WarningAlert>
                )}
                {applied && (
                    <WarningAlert
                        otherBg="alert-info"
                        renderElement={
                            <T>Automatic language update has been applied.</T>
                        }
                    >
                        {this.renderAppliedButtons()}
                    </WarningAlert>
                )}
            </React.Fragment>
        );
    }

    private renderUpdateButtons() {
        if (!session.hasRole(ERoles.Administrator)) {
            return null;
        }

        return (
            <div className="btn-group btn-group-sm ml-1">
                <Link className="btn-group" to="/admin">
                    <button
                        type="button"
                        id="redirect"
                        className="btn btn-sm btn-primary"
                        onClick={this.onUpdateClick}
                    >
                        <T>Update</T>
                    </button>
                </Link>
                <button
                    type="button"
                    className="btn btn-sm btn-info"
                    onClick={this.onDismissUpdateClick}
                >
                    <T>Dismiss</T>
                </button>
            </div>
        );
    }

    private renderAppliedButtons() {
        return (
            <div className="btn-group btn-group-sm ml-1">
                <button
                    type="button"
                    className="btn btn-sm btn-info"
                    onClick={this.onDismissAppliedClick}
                >
                    <T>Dismiss</T>
                </button>
            </div>
        );
    }
}

export default LanguageInfo;
