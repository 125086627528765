import {faEdit} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import React from "react";
import {IntlShape, injectIntl} from "react-intl";
import {Link} from "react-router-dom";

import {
    EPropertyTypes,
    IMaterial,
    IMaterialDetailModel,
    IMaterialFunctionModel,
} from "@/components/materials/models";
import {DEFAULT_TEMPERATURE_DECIMAL} from "@toolbox/display-blocks/models";

import MathBlock from "@/components/math/MathBlock";
import {
    displayWavelength,
    displayWavelengthLabel,
} from "@toolbox/display-blocks/Wavelength";
import T, {intl2Num} from "@translate/T";
import {
    getFormulaUnit,
    getTemperatureRange,
    getWavelengthRange,
    usesEnergy,
} from "../property-editor/FunctionRow";
import {getFunctionTitle} from "../property-editor/MaterialFunctions";

interface IMaterialDetailsProps {
    intl: IntlShape;
    selected?: IMaterial;
    material?: IMaterialDetailModel;

    materialWarning?: JSX.Element | null;
}

class MaterialDetails extends React.PureComponent<IMaterialDetailsProps> {
    public render() {
        const {material, selected} = this.props;
        if (!material) {
            return (
                <h5>
                    <T>Select material to view.</T>
                </h5>
            );
        }

        if (selected?.id !== material.id) {
            return (
                <h5>
                    <T>Loading, please wait...</T>
                </h5>
            );
        }

        return (
            <React.Fragment>
                <div className="mb-2">{this.renderGeneralInfos(material)}</div>
                <div className="mb-2">{this.renderFormulas(material)}</div>
                <div className="mx-1 mb-2">{this.renderCalcInfos()}</div>
                <div>{this.renderEditButton(material)}</div>
            </React.Fragment>
        );
    }

    private renderGeneralInfos(material: IMaterialDetailModel) {
        const {name, author, notes} = material;

        return (
            <table className="table table-sm table-striped table-bordered">
                <tbody>
                    <tr>
                        <td>
                            <strong>
                                <T>Name</T>
                            </strong>
                        </td>
                        <td>
                            <strong>
                                <T>Notes</T>
                            </strong>
                        </td>
                    </tr>
                    <tr>
                        <td>{name}</td>
                        <td rowSpan={3}>{notes}</td>
                    </tr>
                    <tr>
                        <td>
                            <strong>
                                <T>Author</T>
                            </strong>
                        </td>
                    </tr>
                    <tr>
                        <td>{author}</td>
                    </tr>
                </tbody>
            </table>
        );
    }

    private renderFormulas(material: IMaterialDetailModel) {
        const {materialWarning} = this.props;

        return (
            <React.Fragment>
                <div className="overflow-auto">
                    <table className="table table-sm table-striped table-bordered">
                        {this.renderHeader()}
                        {this.renderFormulaTable(material)}
                    </table>
                </div>

                {!!materialWarning && (
                    <div className="text-warning mt-1">{materialWarning}</div>
                )}
            </React.Fragment>
        );
    }

    private renderHeader() {
        return (
            <thead>
                <tr>
                    <th>
                        <T>Name</T>
                    </th>
                    <th>
                        <T>Formula</T>
                    </th>
                    <th>
                        <T>Temperature</T>
                    </th>
                    <th>
                        <T>Wavelength</T>
                    </th>
                </tr>
            </thead>
        );
    }

    private renderFormulaTable(material: IMaterialDetailModel) {
        const {viscosity, density, rpri, ipri, attenuation, massCoefficient} =
            material;

        return (
            <tbody className="space-nowrap">
                {this.atLeast1Entry(viscosity).map((value, i) => (
                    <tr key={i}>
                        {this.renderRow(value, EPropertyTypes.Viscosity)}
                    </tr>
                ))}
                {this.atLeast1Entry(density).map((value, i) => (
                    <tr key={i}>
                        {this.renderRow(value, EPropertyTypes.Density)}
                    </tr>
                ))}
                {this.atLeast1Entry(rpri).map((value, i) => (
                    <tr key={i}>
                        {this.renderRow(value, EPropertyTypes.Rpri)}
                    </tr>
                ))}
                {this.atLeast1Entry(ipri).map((value, i) => (
                    <tr key={i}>
                        {this.renderRow(value, EPropertyTypes.Ipri)}
                    </tr>
                ))}
                {this.atLeast1Entry(attenuation).map((value, i) => (
                    <tr key={i}>
                        {this.renderRow(value, EPropertyTypes.Attenuation)}
                    </tr>
                ))}
                {this.atLeast1Entry(massCoefficient).map((value, i) => (
                    <tr key={i}>
                        {this.renderRow(value, EPropertyTypes.MassCoefficient)}
                    </tr>
                ))}
            </tbody>
        );
    }

    private renderRow(value: IMaterialFunctionModel, type: EPropertyTypes) {
        const {intl} = this.props;

        return (
            <React.Fragment>
                <td>
                    {getFunctionTitle(type)}
                    {getFormulaUnit(type)}
                </td>
                <td>
                    {value.formula ? (
                        <MathBlock
                            value={value.formula}
                            useEnergy={usesEnergy(type)}
                        />
                    ) : (
                        "-"
                    )}
                </td>
                <td>{getTemperatureRange(intl, value, type)}</td>
                <td>{getWavelengthRange(intl, value, type)}</td>
            </React.Fragment>
        );
    }

    private renderCalcInfos() {
        const {intl, material} = this.props;

        return (
            <div className="form-row border">
                <div className="col-md-6">
                    <div className="form-row">
                        <div className="col">
                            <T>Temperature</T>
                        </div>
                        <div className="col-auto border-right text-right">
                            {material?.temperature !== undefined ? (
                                <React.Fragment>
                                    {intl2Num(
                                        intl,
                                        material.temperature,
                                        DEFAULT_TEMPERATURE_DECIMAL,
                                    ) + " "}
                                    <T>°C</T>
                                </React.Fragment>
                            ) : (
                                <T>Unknown</T>
                            )}
                        </div>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="form-row">
                        <div className="col border-left">
                            {displayWavelengthLabel(intl, material?.wavelength)}
                        </div>
                        <div className="col-auto text-right">
                            {material?.wavelength !== undefined ? (
                                displayWavelength(intl, material.wavelength)
                            ) : (
                                <T>Unknown</T>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private renderEditButton(material: IMaterialDetailModel) {
        return (
            <Link to={`/materials/edit/${material.id}`} target="_blank">
                <button type="button" className="btn btn-secondary">
                    <FontAwesomeIcon
                        icon={faEdit}
                        fixedWidth={true}
                        className="mr-1"
                    />
                    <T>Edit material</T>
                </button>
            </Link>
        );
    }

    private atLeast1Entry(
        value: IMaterialFunctionModel[],
    ): IMaterialFunctionModel[] {
        if (!value.length) {
            return [{formula: "", notes: ""}];
        }

        return value;
    }
}

export default injectIntl(MaterialDetails);
