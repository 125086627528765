import {boundMethod} from "autobind-decorator";
import {decompressFromEncodedURIComponent} from "lz-string";
import React from "react";
import {Route, RouteComponentProps, Switch} from "react-router-dom";

import license from "@/services/license";
import {IMissingLicense} from "@toolbox/render-page/models";

import createRedirector from "@toolbox/functions/create-redirector";
import Loading from "@toolbox/render-page/Loading";
import MissingLicense from "@toolbox/render-page/MissingLicense";
import Downloads from "./Downloads";
import SessionExpiredNotification from "./SessionExpiredNotification";
import Account from "./account/Index";
import Devices from "./devices/Index";
import Preferences from "./preferences/Index";
import Project from "./project/Index";
import RcaCalc from "./rcaCalc/Index";
import Template from "./template/Index";

// license pages are not used for most of users, don't load it if we don't have to
const License = React.lazy(() => import("./license/Index"));
// administration pages are not used for most of users, don't load it if we don't have to
const Admin = React.lazy(() => import("./admin/Index"));
// device config pages are not used for most of users, don't load it if we don't have to
const DeviceConfig = React.lazy(() => import("./device-config/Index"));
// stamps uses Mathjs and Latex rendering that is only used for editing of stamps
const Stamps = React.lazy(() => import("./stamps/Index"));
// materials uses Mathjs and Latex rendering that is only used for editing of materials
const Materials = React.lazy(() => import("./materials/Index"));
// hindrance uses Mathjs and Latex rendering that is only used for editing of hindrance
const Hindrance = React.lazy(() => import("./hindrance/Index"));

class Home extends React.PureComponent {
    public render() {
        return (
            <React.Fragment>
                {this.renderContent()}
                <SessionExpiredNotification />
            </React.Fragment>
        );
    }

    private renderContent() {
        if (license.status.daysLeft <= 0) {
            return (
                <Switch>
                    <Route path="/license" render={this.renderLicense} />
                    <Route component={createRedirector("/license")} />
                </Switch>
            );
        }

        return (
            <Switch>
                <Route path="/account" render={this.renderAccount} />
                <Route path="/admin" render={this.renderAdmin} />
                <Route
                    path="/device_config"
                    component={this.renderDeviceConfig}
                />
                <Route path="/devices" component={Devices} />
                <Route path="/downloads" component={Downloads} />
                <Route path="/hindrance" render={this.renderHindrance} />
                <Route path="/license" render={this.renderLicense} />
                <Route path="/materials" render={this.renderMaterials} />
                <Route path="/missing" render={this.renderMissingLicense} />
                {/* <Route path="/normalizations" component={Normalizations} /> */}
                <Route path="/preferences" component={Preferences} />
                <Route path="/rca" component={RcaCalc} />
                <Route path="/stamps" render={this.renderStamps} />
                <Route path="/templates" render={this.renderTemplates} />

                <Route component={Project} />
            </Switch>
        );
    }

    @boundMethod
    private renderAccount() {
        return <Account />;
    }

    @boundMethod
    private renderAdmin(props: RouteComponentProps) {
        return (
            <React.Suspense fallback={<Loading />}>
                <Admin baseUrl={props.match.url} />
            </React.Suspense>
        );
    }

    @boundMethod
    private renderDeviceConfig(props: RouteComponentProps) {
        return (
            <React.Suspense fallback={<Loading />}>
                <DeviceConfig baseUrl={props.match.url} />
            </React.Suspense>
        );
    }

    @boundMethod
    private renderHindrance(props: RouteComponentProps) {
        return (
            <React.Suspense fallback={<Loading />}>
                <Hindrance baseUrl={props.match.url} />
            </React.Suspense>
        );
    }

    @boundMethod
    private renderLicense(props: RouteComponentProps) {
        const search = props.location.search;
        let redirect = null;
        if (search) {
            redirect = new URLSearchParams(search).get("redirect");
        }

        return (
            <React.Suspense fallback={<Loading />}>
                <License redirect={redirect} />
            </React.Suspense>
        );
    }

    @boundMethod
    private renderMaterials(props: RouteComponentProps) {
        return (
            <React.Suspense fallback={<Loading />}>
                <Materials baseUrl={props.match.url} />
            </React.Suspense>
        );
    }

    @boundMethod
    private renderMissingLicense(props: RouteComponentProps) {
        const search = new URLSearchParams(props.location.search);
        const data = search.get("data");
        const missing: IMissingLicense = data
            ? JSON.parse(decompressFromEncodedURIComponent(data))
            : {};

        return (
            <MissingLicense
                missingModuleLicenses={missing.missingModuleLicenses}
                missingSopLicenses={missing.missingSopLicenses}
            />
        );
    }

    @boundMethod
    private renderStamps(props: RouteComponentProps) {
        return (
            <React.Suspense fallback={<Loading />}>
                <Stamps baseUrl={props.match.url} />
            </React.Suspense>
        );
    }

    @boundMethod
    private renderTemplates(props: RouteComponentProps) {
        return <Template baseUrl={props.match.url} />;
    }
}

export default Home;
