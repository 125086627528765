import React from "react";

import {EModules} from "@/models";
import http from "@/services/http";
import {IModuleGroup} from "@shared/analysis/analyze-button/models";
import {EServerId} from "@shared/models";

import {trademark} from "@/Footer";
import AnalyzeButton from "@shared/analysis/analyze-button/Index";
import T from "@translate/T";

export async function getAvailableModules(
    targets: string,
    searchParams?: {deviceClass: number; wavelengths: number},
): Promise<IModuleGroup[]> {
    const groups: IModuleGroup[] = [];

    if (!targets && !searchParams) {
        return groups;
    }

    let modules: EModules[] = [];
    try {
        modules = await http
            .get(`/api/centrifuge/analysis/modules?${targets}`, {searchParams})
            .json<EModules[]>();
    } catch {
        return groups;
    }

    groups.push(
        {
            header: (
                <React.Fragment>
                    <T>LUMView</T>
                    {trademark()}
                </React.Fragment>
            ),
            id: "lum",
            modules: [EModules.LumView],
        },
        {
            header: <T>Basic analysis</T>,
            id: "standard",
            modules: [
                EModules.Instability,
                EModules.Integration,
                EModules.FrontTracking,
            ],
        },
        {
            header: <T>Particle size characterization</T>,
            id: "psa-pos",
            modules: [
                EModules.PsaVelocityPos,
                EModules.PsaIntensityPos,
                EModules.PsaVolumeWeightedPos,
                EModules.PsaNumberWeightedPos,
            ],
        },
        {
            header: <T>PSA constant time</T>,
            id: "psa-time",
            modules: [
                EModules.PsaVelocityTime,
                EModules.PsaIntensityTime,
                EModules.PsaVolumeWeightedTime,
                EModules.PsaNumberWeightedTime,
            ],
        },
        {
            header: <T>Advanced analysis</T>,
            id: "advanced",
            modules: [
                EModules.Filtration,
                EModules.SedimentationHeight,
                EModules.FirstDerivative,
                EModules.MwlRatio,
                EModules.Astm,
            ],
        },
        {
            header: <T>Multiple wavelengths PSA</T>,
            id: "mwl",
            modules: [
                EModules.PsaMwlVolumeWeighted,
                EModules.PsaMwlNumberWeighted,
            ],
        },
    );

    for (const group of groups) {
        group.modules = group.modules.filter((module) =>
            modules.includes(module),
        );
    }

    return groups.filter((x) => x.modules.length > 0);
}

interface ICentAnalyzeButtonProps {
    project: number;
    targets: string;

    className?: string;
    hrefTarget?: string;

    getTargets?(): (engine?: EModules) => string;
}

class CentAnalyzeButton extends React.PureComponent<ICentAnalyzeButtonProps> {
    public render() {
        const {hrefTarget, project, targets, className, getTargets} =
            this.props;
        const _getTargets = () => () => targets;

        return (
            <AnalyzeButton
                className={className}
                hrefTarget={hrefTarget}
                project={project}
                serverId={EServerId.Centrifuge}
                getModules={this.getModules}
                getTargets={getTargets ?? _getTargets}
            />
        );
    }

    private async getModules(targets: string) {
        return getAvailableModules(targets);
    }
}

export default CentAnalyzeButton;
