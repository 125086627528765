import {faEye} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {boundMethod} from "autobind-decorator";
import React from "react";
import {FormattedMessage, IntlShape, injectIntl} from "react-intl";

import {EModuleLicenses} from "@/components/license/models";
import http from "@/services/http";
import license, {ILicenseStatus} from "@/services/license";
import {IMissingLicense} from "./models";

import Barcode from "@/components/license/Barcode";
import LicenseActivation from "@/components/license/LicenseActivation";
import {getDisplayModule} from "@/components/license/ModuleLicense";
import {sopLicenseDisplayName} from "@/components/license/SopFeatures";
import {getSepviewVersion} from "@/Footer";
import ErrorMessage from "@toolbox/design/ErrorMessage";
import T, {intl2Str} from "@translate/T";
import {getDeviceDisplayName} from "../functions/device-check";

interface IMissingLicenseProps extends IMissingLicense {
    intl: IntlShape;
    dismissApi?: string;
}

interface IMissingLicenseState {
    licenseStatus: ILicenseStatus;
}

class MissingLicense extends React.PureComponent<
    IMissingLicenseProps,
    IMissingLicenseState
> {
    public readonly state: IMissingLicenseState = {
        licenseStatus: license.status,
    };

    private unsubscribe?: () => void;

    private get missingFeature() {
        const {
            intl,
            missingDeviceLicense,
            missingModuleLicenses,
            missingSopLicenses,
        } = this.props;
        const missingFeature: string[] =
            missingSopLicenses?.map((x) => sopLicenseDisplayName(x)(intl)) ??
            [];

        if (missingModuleLicenses) {
            missingFeature.push(
                ...missingModuleLicenses.map((x) => getDisplayModule(x)(intl)),
            );
        }

        if (missingDeviceLicense) {
            const missingDeviceLicenses = Array.isArray(missingDeviceLicense)
                ? missingDeviceLicense
                : [missingDeviceLicense];

            missingFeature.push(
                ...missingDeviceLicenses.map(
                    (x) =>
                        getDeviceDisplayName(x) +
                        " " +
                        intl2Str(intl, "device"),
                ),
            );
        }

        return missingFeature;
    }

    private get mail() {
        const {installationId, serialKey} = this.state.licenseStatus;

        return (
            "mailto:info@lum-gmbh.de?subject=Request to extend License&body=" +
            "To Whom It May Concern, please contact me regarding an offer to upgrade my license." +
            `Feature: ${
                this.missingFeature
            }, Installation ID: ${installationId}, Serial key: ${
                serialKey ?? "not found"
            }, Version: ${getSepviewVersion()}`
        );
    }

    private get showButton() {
        const {dismissApi, missingModuleLicenses} = this.props;
        const missingLicense =
            !!missingModuleLicenses?.includes(EModuleLicenses.MultiPeaks) ||
            !!missingModuleLicenses?.includes(
                EModuleLicenses.VolumeConcentration,
            ) ||
            !!missingModuleLicenses?.includes(EModuleLicenses.Hindrance);

        return !!dismissApi && missingLicense;
    }

    public componentDidMount() {
        this.unsubscribe = license.subscribe({
            licenseChanged: (licenseStatus) => this.setState({licenseStatus}),
        });
    }

    public componentWillUnmount() {
        this.unsubscribe?.();
    }

    @boundMethod
    public async showAnyway(e: React.SyntheticEvent) {
        e.preventDefault();

        const {dismissApi} = this.props;

        try {
            const response = await http.post(dismissApi ?? "");
            if (!response.ok) {
                throw new http.HTTPError(response);
            }

            window.location.reload();
        } catch {
            // do nothing for now
        }
    }

    public render() {
        return (
            <React.Fragment>
                <ErrorMessage
                    header={<T>Missing license</T>}
                    extra={this.renderExtra()}
                >
                    <FormattedMessage
                        id="You are missing some licenses and therefore do not have the permission to open this document. Your current license can be upgraded to include the desired feature, kindly contact your local LUM sales partner."
                        defaultMessage="You are missing some licenses and therefore do not have the permission to open this document. Your current license can be upgraded to include the desired feature, kindly contact your local LUM sales partner."
                    />
                    <ul>
                        {this.missingFeature.map((x, i) => (
                            <li key={i}>{x}</li>
                        ))}
                    </ul>
                    {this.renderButton()}
                </ErrorMessage>
            </React.Fragment>
        );
    }

    private renderExtra() {
        const {installationId, serialKey} = this.state.licenseStatus;

        return (
            <React.Fragment>
                <LicenseActivation
                    installationId={installationId}
                    serialKey={serialKey!}
                />
                <Barcode
                    mail={this.mail}
                    noMargin={true}
                    flavorText={
                        <T>You can request a new license on this computer.</T>
                    }
                />
            </React.Fragment>
        );
    }

    private renderButton() {
        const {intl} = this.props;
        if (!this.showButton) {
            return null;
        }

        return (
            <button
                type="button"
                id="view-anyway"
                className="btn btn-danger mt-2"
                title={intl2Str(
                    intl,
                    "View document anyway, but remember that saved license features will be removed from that document.",
                )}
                onClick={this.showAnyway}
            >
                <FontAwesomeIcon
                    icon={faEye}
                    fixedWidth={true}
                    className="mr-1"
                />
                <T>Show without license feature</T>
            </button>
        );
    }
}

export default injectIntl(MissingLicense);
