import {boundMethod} from "autobind-decorator";
import React from "react";

import {ECheckStates} from "./models";

import InlineCheckbox from "./InlineCheckbox";

interface ITristateCheckboxProps {
    value: ECheckStates;
    suffixId: string;

    checkboxClassNameOverwrite?: string;
    dataTestId?: string;

    onChange(checked: boolean, id: string): void;
}

class TristateCheckbox extends React.PureComponent<ITristateCheckboxProps> {
    @boundMethod
    public onClick(checked: boolean) {
        const {onChange, suffixId} = this.props;
        onChange(checked, suffixId);
    }

    public render() {
        const {
            value,
            checkboxClassNameOverwrite,
            dataTestId,
            suffixId,
            onChange,
        } = this.props;
        const id = "tristate-checkbox-" + suffixId;

        return (
            <InlineCheckbox
                idSuffix="tristate"
                data-testid={dataTestId ?? id}
                checkboxClassNameOverwrite={checkboxClassNameOverwrite}
                checked={value === ECheckStates.Checked}
                indeterminate={value === ECheckStates.Indeterminate}
                toggle={this.onClick}
            />
        );
    }
}

export default TristateCheckbox;
