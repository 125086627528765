import {kebabCase} from "lodash";
import React from "react";
import {IntlContext, IntlShape} from "react-intl";

import NumberInput from "@toolbox/nativ-inputs/NumberInput";
import {intl2Num, intl2Str} from "@translate/T";

export interface IValueInputProps<TValue extends string> {
    id: TValue;
    value: number;
    header: React.ReactChild;

    className?: string;
    decimals?: number; // default: 0
    disabled?: boolean;
    max?: number;
    min?: number;
    step?: number; // default: 1

    onChange(value: number, key: TValue): void;
}

class ValueInput<TValue extends string = string> extends React.PureComponent<
    IValueInputProps<TValue>
> {
    public render() {
        const props = this.props;

        return (
            <div className={props.className}>
                <label className="mb-1" htmlFor={kebabCase(props.id)}>
                    {props.header}
                </label>
                <NumberInput
                    decimals={props.decimals ?? 0}
                    disabled={props.disabled}
                    id={props.id}
                    max={props.max}
                    min={props.min}
                    onChange={props.onChange}
                    step={props.step ?? 1}
                    value={props.value}
                />
                {this.renderRange()}
            </div>
        );
    }

    private renderRange() {
        const {min, max} = this.props;
        const parts: React.ReactChild[] = [];
        if (typeof min === "number") {
            const text = (intl: IntlShape) =>
                intl2Str(intl, "Min: {min}", {min: intl2Num(intl, min)});
            parts.push(<IntlContext.Consumer key="min" children={text} />);
        }

        if (typeof max === "number") {
            if (parts.length > 0) {
                parts.push(", ");
            }

            const text = (intl: IntlShape) =>
                intl2Str(intl, "Max: {max}", {max: intl2Num(intl, max)});
            parts.push(<IntlContext.Consumer key="max" children={text} />);
        }

        if (!parts.length) {
            return null;
        }

        return <small>{parts}</small>;
    }
}

export default ValueInput;
