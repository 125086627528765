import {IconDefinition} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {boundMethod} from "autobind-decorator";
import React from "react";

import {MODAL_VALUE} from "@/components/warnings/hard-drice-space/models";

import ModalWarning from "@/components/warnings/hard-drice-space/ModalWarning";

interface IExecuteButtonProps {
    icon: IconDefinition;
    url?: string;

    onClick: () => void;
}

class ExecuteButton extends React.PureComponent<IExecuteButtonProps> {
    private readonly ref = React.createRef<ModalWarning>();

    @boundMethod
    public onClick() {
        const current = this.ref.current;
        if (!current) {
            return;
        }

        if (current.state.value <= 3) {
            current.open();
            return;
        }

        this.props.onClick();

        if (current.state.value <= MODAL_VALUE) {
            current.open();
        }
    }

    public render() {
        return (
            <React.Fragment>
                <ModalWarning ref={this.ref} />
                {this.renderButton()}
            </React.Fragment>
        );
    }

    private renderButton() {
        const {url} = this.props;
        const linkClassName = "mr-1";
        const buttonClassName = "btn btn-success";

        if (url === undefined) {
            return (
                <button
                    type="button"
                    className={buttonClassName + " " + linkClassName}
                    onClick={this.onClick}
                >
                    {this.renderIcon()}
                </button>
            );
        }

        return (
            <a className={linkClassName} href={url} onClick={this.onClick}>
                <button type="button" className={buttonClassName}>
                    {this.renderIcon()}
                </button>
            </a>
        );
    }

    private renderIcon() {
        const {icon, children} = this.props;

        return (
            <React.Fragment>
                <FontAwesomeIcon
                    icon={icon}
                    fixedWidth={true}
                    className="mr-1"
                />
                {children}
            </React.Fragment>
        );
    }
}

export default ExecuteButton;
