import {boundMethod} from "autobind-decorator";
import React from "react";
import {injectIntl, IntlShape} from "react-intl";
import {Link} from "react-router-dom";

import intlLocale from "@/services/intlLocale";
import languages from "@/services/languages";
import {ILanguageId} from "@/services/models";
import {intl2LocaleParts} from "../toolbox/translate/T";
import {DEFAULT_LANGUAGE, ILanguage} from "./models";

interface ILanguagesProps {
    intl: IntlShape;
}

interface ILanguagesState {
    options: ILanguage[];
}

class Languages extends React.PureComponent<ILanguagesProps, ILanguagesState> {
    public readonly state: ILanguagesState = {
        options: languages.options,
    };

    private unsubscribe?: () => void;

    public async componentDidMount() {
        this.unsubscribe = languages.subscribe({
            languagesChanged: (options) => this.setState({options}),
        });

        await languages.retrieveLanguages();
    }

    public componentWillUnmount() {
        this.unsubscribe?.();
    }

    @boundMethod
    public async onClick(e: React.SyntheticEvent) {
        e.preventDefault();

        await intlLocale.loadLanguage((e.target as HTMLElement).id);
    }

    public render() {
        const {intl} = this.props;
        const {options} = this.state;
        const {language} = intl2LocaleParts(intl.locale);
        const selected =
            options.find((x) => language === x.id) ?? DEFAULT_LANGUAGE;

        return (
            <li
                className="nav-item dropdown"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
            >
                <Link
                    id="languages"
                    className="nav-link dropdown-toggle"
                    aria-expanded="false"
                    aria-haspopup="true"
                    data-toggle="dropdown"
                    role="button"
                    to=""
                >
                    {selected.name}
                </Link>

                <div
                    aria-labelledby="languages"
                    className="dropdown-menu dropdown-menu-right"
                >
                    {options
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((x) => this.renderLanguage(x.id, x.name))}
                </div>
            </li>
        );
    }

    private renderLanguage(id: ILanguageId, name: string) {
        return (
            <button
                type="button"
                key={id}
                id={id}
                className="dropdown-item"
                onClick={this.onClick}
            >
                {name}
            </button>
        );
    }
}

export default injectIntl(Languages);
