import {faHourglassHalf} from "@fortawesome/free-solid-svg-icons";

import {boundMethod} from "autobind-decorator";
import React from "react";
import {IntlShape} from "react-intl";

import license, {ILicenseStatus} from "@/services/license";

import WarningAlert from "@toolbox/design/WarningAlert";
import {intl2Str} from "@translate/T";

interface ILicenseWarningState {
    licenseStatus: ILicenseStatus;
}

class LicenseWarning extends React.PureComponent<{}, ILicenseWarningState> {
    public readonly state: ILicenseWarningState = {
        licenseStatus: license.status,
    };

    private unsubscribe?: () => void;

    public componentDidMount() {
        this.unsubscribe = license.subscribe({
            licenseChanged: (licenseStatus) => this.setState({licenseStatus}),
        });
    }

    public componentWillUnmount() {
        this.unsubscribe?.();
    }

    public render() {
        const {isValidSystemTime, licensee, serialKey, isTrial, daysLeft} =
            this.state.licenseStatus;
        if (
            isValidSystemTime &&
            ((!licensee && !serialKey) || (!isTrial && daysLeft > 14))
        ) {
            return null;
        }

        return (
            <WarningAlert
                icon={faHourglassHalf}
                renderMessage={this.renderMessage}
            />
        );
    }

    @boundMethod
    private renderMessage(intl: IntlShape) {
        const {isValidSystemTime, daysLeft, isTrial} = this.state.licenseStatus;

        let systemTime = "";
        if (isValidSystemTime === false) {
            systemTime =
                " " +
                intl2Str(
                    intl,
                    "Server system date is older than the last stored date.",
                );
        }

        const buyNew =
            " " +
            intl2Str(intl, "Please consider purchasing or renew your license.");

        if (daysLeft <= 0) {
            return (
                intl2Str(intl, "Your license is expired.") + buyNew + systemTime
            );
        }

        if (daysLeft <= 14 || isTrial) {
            switch (daysLeft) {
                case 1:
                    return (
                        intl2Str(intl, "Your license will expire in 1 day.") +
                        buyNew +
                        systemTime
                    );

                default:
                    return (
                        intl2Str(
                            intl,
                            "Your license will expire in {daysLeft} days.",
                            {daysLeft},
                        ) +
                        buyNew +
                        systemTime
                    );
            }
        }

        if (systemTime !== "") {
            return systemTime.substring(1);
        }

        return intl2Str(intl, "License warning");
    }
}

export default LicenseWarning;
