import "./DeleteTemplateList.module.scss";

import sopIcon from "@/images/icons/sop.svg";

import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {boundMethod} from "autobind-decorator";
import {AnimatePresence, motion} from "framer-motion";
import React from "react";
import {Link} from "react-router-dom";

import http from "@/services/http";
import {animation, onClosed, onOpen} from "@toolbox/design/models";
import {IRegularAndQueueSop, ISopListResponse} from "./models";

import Card from "@toolbox/design/Card";
import ModuleIcon from "@toolbox/display-blocks/ModuleIcon";
import T from "@translate/T";

interface IDeleteTemplateListProps {
    id: string;
}

interface IDeleteTemplateListState {
    sops: IRegularAndQueueSop[];
    allowed2Delete: boolean;
}

class DeleteTemplateList extends React.PureComponent<
    IDeleteTemplateListProps,
    IDeleteTemplateListState
> {
    public readonly state: IDeleteTemplateListState = {
        sops: [],
        allowed2Delete: false,
    };

    public componentDidMount() {
        this.retrieve();
    }

    public render() {
        return (
            <React.Fragment>
                {this.renderNotAllowed2Delete()}
                {this.renderSopList()}
            </React.Fragment>
        );
    }

    private renderNotAllowed2Delete() {
        const {allowed2Delete} = this.state;
        if (allowed2Delete) {
            return null;
        }

        return (
            <div className="alert alert-danger text-center">
                <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    fixedWidth={true}
                    className="mr-1"
                />
                <T>
                    You cannot delete this template as you do not have rights to
                    all projects where this template is used.
                </T>
            </div>
        );
    }

    private renderSopList() {
        const {sops} = this.state;
        if (!sops.length) {
            return null;
        }

        return (
            <Card
                headerClassName="bg-warning"
                title={
                    <T>
                        List of SOPs that uses the template, including queued
                        SOPs
                    </T>
                }
            >
                <div className="card-body">
                    <ul className="list-unstyled" styleName="list-analyze-ul">
                        <AnimatePresence exitBeforeEnter={true}>
                            {sops.map((sop, i) => this.renderSop(sop, i))}
                        </AnimatePresence>
                    </ul>
                </div>
            </Card>
        );
    }

    private renderSop(sop: IRegularAndQueueSop, i: number) {
        return (
            <motion.li
                key={i}
                animate={onOpen}
                exit={onClosed}
                initial={onClosed}
                transition={animation}
            >
                <Link id={`link-to-${i}`} to={this.getUrl(sop)}>
                    <ModuleIcon icon={sopIcon} /> {sop.name}
                </Link>
            </motion.li>
        );
    }

    private getUrl(sop: IRegularAndQueueSop) {
        const {project, id, queue} = sop;
        const q = queue ? "queue/" : "";

        return `/project/${project}/centrifuge/sop/${q + id}`;
    }

    @boundMethod
    private async retrieve() {
        try {
            const response = await http
                .get(`/api/template/sops/${this.props.id}`)
                .json<ISopListResponse>();

            this.setState({
                sops: response.sops,
                allowed2Delete: response.gotAllSop,
            });
        } catch {
            this.setState({sops: [], allowed2Delete: false});
        }
    }
}

export default DeleteTemplateList;
