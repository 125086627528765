import {faSearch, faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {boundMethod} from "autobind-decorator";
import React from "react";
import {IntlContext, IntlShape} from "react-intl";

import {intl2Str} from "@translate/T";

export interface ISearchInputProps {
    autofocus?: boolean;

    query: string;
    onSearch(value: string): void;
}

class SearchInput extends React.PureComponent<ISearchInputProps> {
    private _query: string = this.props.query;
    private readonly input = React.createRef<HTMLInputElement>();

    private set query(_query: string) {
        this._query = _query;
        this.props.onSearch(_query);
    }

    public componentDidMount() {
        if (this.props.autofocus === false) {
            return;
        }

        this.input.current?.focus();
    }

    public componentDidUpdate() {
        const {query} = this.props;
        if (query === "" && query !== this._query) {
            // in case user reset via <link>, we shall trigger again
            this.query = query;
        }
    }

    @boundMethod
    public setQuery(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();

        const value = e.target.value;
        this.query = value;
    }

    @boundMethod
    public triggerSearch(e: React.SyntheticEvent) {
        e.preventDefault();

        // reassign to call it again
        this.query = this.props.query;
        this.input.current?.focus();
    }

    @boundMethod
    public handleKeydown(e: React.KeyboardEvent) {
        if (e.key !== "Enter") {
            return;
        }

        this.triggerSearch(e);
    }

    @boundMethod
    public clear(e: React.SyntheticEvent) {
        e.preventDefault();

        this.query = "";
        this.input.current?.focus();
    }

    public render() {
        return (
            <div className="input-group mb-2">
                <div className="input-group-prepend">
                    <button
                        type="button"
                        aria-label="search"
                        id="btn-search"
                        onClick={this.triggerSearch}
                        className="btn btn-secondary"
                    >
                        <FontAwesomeIcon icon={faSearch} fixedWidth={true} />
                    </button>
                </div>

                <IntlContext.Consumer children={this.renderInput} />

                <span className="input-group-append">
                    <button
                        type="button"
                        aria-label="clear"
                        id="btn-delete"
                        onClick={this.clear}
                        className="btn btn-secondary"
                    >
                        <FontAwesomeIcon icon={faTimes} fixedWidth={true} />
                    </button>

                    {this.props.children}
                </span>
            </div>
        );
    }

    @boundMethod
    private renderInput(intl: IntlShape) {
        return (
            <input
                type="search"
                id="search-input"
                className="form-control"
                data-testid="search-input"
                ref={this.input}
                value={this.props.query}
                onChange={this.setQuery}
                placeholder={intl2Str(intl, "Search")}
                onKeyDown={this.handleKeydown}
            />
        );
    }
}

export default SearchInput;
