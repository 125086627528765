import AboutConfig from "@/images/others/about-config.png";

import {boundMethod} from "autobind-decorator";
import React from "react";

import warning from "../../services/warning";

import {isFirefox, isHttps} from "@toolbox/functions/browser-checks";
import Modal, {CancelButton} from "@toolbox/modals/Modal";
import T from "@translate/T";

interface ICopy2ClipboardModalState {
    show: boolean;
}

class Copy2ClipboardModal extends React.PureComponent<
    {},
    ICopy2ClipboardModalState
> {
    public readonly state: ICopy2ClipboardModalState = {show: false};

    private readonly modal = React.createRef<Modal>();

    private unsubscribe?: () => void;

    public componentDidMount() {
        this.unsubscribe = warning.subscribe({
            copy2ClipboardWarningChanged: this.showModal,
        });
    }

    public componentWillUnmount() {
        this.unsubscribe?.();
    }

    @boundMethod
    public showModal() {
        this.setState({show: true});
    }

    @boundMethod
    public afterClose() {
        this.setState({show: false});
    }

    public render() {
        if (!this.state.show) {
            return null;
        }

        return (
            <Modal
                ref={this.modal}
                header={<T>You need to enable copy to clipboard</T>}
                size="md"
                afterClose={this.afterClose}
            >
                <div className="modal-body">
                    <div>
                        <T>Your browser has copy to clipboard blocked.</T>
                    </div>
                    <div>
                        <T>
                            You can use a different browser to use that feature.
                        </T>
                    </div>
                    {this.renderHttp()}
                    {this.renderFirefox()}
                </div>

                <div className="modal-footer">
                    <CancelButton />
                </div>
            </Modal>
        );
    }

    private renderHttp() {
        if (isHttps()) {
            return null;
        }

        return (
            <div>
                <T>
                    You are accessing the SEPView server via plain http, some
                    browser limit clipboard functionality, if not using SSL
                    encryption (https).
                </T>
            </div>
        );
    }

    private renderFirefox() {
        if (!isFirefox()) {
            return null;
        }

        return (
            <React.Fragment>
                <div>
                    <T>
                        If you want to stay here, you need to enable copy to
                        clipboard.
                    </T>
                </div>
                <div>
                    <T>Please open a new tab and enter the following url:</T>
                    <kbd className="ml-1">about:config</kbd>
                </div>
                <div>
                    <T>Enter the following search param:</T>
                    <kbd className="ml-1">clipboardItem</kbd>
                </div>
                <div>
                    <T>Double click on it, to change the param to true.</T>
                </div>
                <img
                    className="img-fluid border mt-2"
                    alt="about:config clipboardItem" // no translate here pls
                    src={AboutConfig}
                />
            </React.Fragment>
        );
    }
}

export default Copy2ClipboardModal;
