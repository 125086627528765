import {boundMethod} from "autobind-decorator";
import React from "react";

import {IMaterial} from "../materials/models";

import MaterialSelector from "@/components/materials/selector/Index";
import T from "@translate/T";

interface IContinuousMaterialProps {
    value?: IMaterial;
    onChange(value?: IMaterial): void;
}

class ContinuousMaterial extends React.PureComponent<IContinuousMaterialProps> {
    @boundMethod
    public onChange(material?: IMaterial) {
        this.props.onChange(material);
    }

    public render() {
        const {value} = this.props;

        return (
            <div className="form-row mb-2">
                <div className="col-sm-2">
                    <label>
                        <T>Continuous material</T>
                    </label>
                </div>

                <div className="col-sm-10">
                    <MaterialSelector
                        id="fluid"
                        optional={true}
                        selected={value}
                        onChange={this.onChange}
                    />
                </div>
            </div>
        );
    }
}

export default ContinuousMaterial;
