import "katex/dist/katex.css";

import {boundMethod} from "autobind-decorator";
import {extent} from "d3-array";
import katex from "katex";
import {clamp} from "lodash";
import React from "react";
import {IntlContext, IntlShape} from "react-intl";

import {
    DEFAULT_ENERGY_DECIMAL,
    DEFAULT_TEMPERATURE_DECIMAL,
    DEFAULT_WAVELENGTH_DECIMAL,
} from "@toolbox/display-blocks/models";
import {EPropertyTypes, IMaterialFunctionModel} from "../../models";

import MathEvaluator from "@/components/math/math-evaluator";
import {getDisplayLabel} from "@chart/chart-labels";
import Card from "@toolbox/design/Card";
import CustomizeLabel from "@toolbox/display-blocks/CustomizeLabel";
import {
    displayWavelength,
    displayWavelengthLabel,
    getPicoNumberForBoolean,
} from "@toolbox/display-blocks/Wavelength";
import {kev2Nm} from "@toolbox/functions/units/length";
import NumberInput from "@toolbox/nativ-inputs/NumberInput";
import T, {intl2Str} from "@translate/T";
import {
    getEnergyWavelength,
    getFormulaUnit,
    usesEnergy,
    usesTemperature,
    usesWavelength,
} from "../FunctionRow";

interface IFunctionTestProps extends Omit<IMaterialFunctionModel, "notes"> {
    type: EPropertyTypes;

    evaluator: MathEvaluator;
}

interface IFunctionTestState {
    temperature: number;
    wavelength: number;
}

class FunctionTest extends React.PureComponent<
    IFunctionTestProps,
    IFunctionTestState
> {
    public readonly state: IFunctionTestState = this.clampState();

    public componentDidUpdate() {
        this.setState(
            this.clampState(this.state.temperature, this.state.wavelength),
        );
    }

    @boundMethod
    public setTemperature(temperature: number) {
        this.setState({temperature});
    }

    @boundMethod
    public setWavelength(wavelength: number) {
        this.setState({
            wavelength: getEnergyWavelength(wavelength, this.props.type, true),
        });
    }

    public render() {
        const {type} = this.props;

        return (
            <Card
                headerClassName="bg-secondary"
                title={
                    <React.Fragment>
                        <T>Function test</T>
                        {getFormulaUnit(type)}
                    </React.Fragment>
                }
                noMargin={true}
            >
                <div className="card-body">
                    <div className="form-row mb-2">
                        {this.renderTemperature()}
                        {this.renderWavelength()}
                    </div>

                    <IntlContext.Consumer children={this.renderResult} />
                </div>
            </Card>
        );
    }

    private renderTemperature() {
        const {maxTemperature, minTemperature, type} = this.props;
        const {temperature} = this.state;
        if (!usesTemperature(type)) {
            return null;
        }

        return (
            <CustomizeLabel
                label={getDisplayLabel({
                    name: (intl) => intl2Str(intl, "Temperature"),
                    unit: (intl) => intl2Str(intl, "°C"),
                })}
                htmlFor="test-temperature"
                half={true}
            >
                <NumberInput
                    id="test-temperature"
                    decimals={DEFAULT_TEMPERATURE_DECIMAL}
                    min={minTemperature}
                    max={maxTemperature}
                    value={temperature}
                    onChange={this.setTemperature}
                />
            </CustomizeLabel>
        );
    }

    private renderWavelength() {
        const {maxWavelength, minWavelength, type} = this.props;
        const {wavelength} = this.state;
        if (!usesWavelength(type)) {
            return null;
        }

        const useEnergy = usesEnergy(type);
        const usePico = getPicoNumberForBoolean(useEnergy);
        const decimals = useEnergy
            ? DEFAULT_ENERGY_DECIMAL
            : DEFAULT_WAVELENGTH_DECIMAL;

        return (
            <CustomizeLabel
                label={getDisplayLabel({
                    name: (intl) => displayWavelengthLabel(intl, usePico),
                    unit: (intl) => displayWavelength(intl, usePico, -1),
                })}
                htmlFor="test-wavelength"
                half={true}
            >
                <NumberInput
                    id="test-wavelength"
                    decimals={decimals}
                    min={getEnergyWavelength(minWavelength!, type)}
                    max={getEnergyWavelength(maxWavelength!, type)}
                    value={getEnergyWavelength(wavelength, type)}
                    onChange={this.setWavelength}
                />
            </CustomizeLabel>
        );
    }

    @boundMethod
    private renderResult(intl: IntlShape) {
        const {formula, type, evaluator} = this.props;
        const {temperature, wavelength} = this.state;
        const result = evaluator.getFullLatex(intl, formula, {
            temperature,
            wavelength: getEnergyWavelength(wavelength, type),
        });

        if (result === undefined) {
            return (
                <em id="text-danger" className="text-danger">
                    <T>Function is invalid!</T>
                </em>
            );
        }

        const html = katex.renderToString(result);
        return (
            <div
                id="function-result"
                dangerouslySetInnerHTML={{__html: html}}
            />
        );
    }

    private clampState(
        temperature?: number,
        wavelength?: number,
    ): IFunctionTestState {
        const {
            minTemperature,
            maxTemperature,
            minWavelength,
            maxWavelength,
            type,
        } = this.props;

        return {
            temperature: clamp(
                temperature ?? 32,
                minTemperature ?? NaN,
                maxTemperature ?? NaN,
            ),
            wavelength: clamp(
                wavelength ?? (usesEnergy(type) ? kev2Nm(17.46) : 650),
                ...(extent([minWavelength ?? NaN, maxWavelength ?? NaN]) as [
                    number,
                    number,
                ]),
            ),
        };
    }
}

export default FunctionTest;
