import {EvalFunction, MathNode, parse} from "mathjs";
import {IntlShape} from "react-intl";

import {MathValues} from "./models";

import {toTex} from "@/components/math/MathBlock";
import {intl2Num} from "@translate/T";

function getFullLatex(intl: IntlShape, latex: string, result: any) {
    if (isNaN(result)) {
        return latex + "= NaN";
    }

    let equalSign = "=";
    if (result === Infinity) {
        result = "\\infty";
    } else if (result === -Infinity) {
        result = "-\\infty";
    } else if (typeof result === "number") {
        const precise = intl2Num(intl, result);
        const rounded = intl2Num(intl, result, undefined, {
            maximumFractionDigits: 3,
        }); // have to do maxi, since we compare strings
        if (rounded !== precise) {
            equalSign = "\\approx";
        }

        result = rounded;
    }

    return latex + equalSign + result;
}

function isValidExpression(
    root: MathNode,
    useTemperature: boolean,
    useWavelength: boolean,
    useConcentration: boolean,
) {
    const queue = [root];
    const validVars: string[] = [];

    if (useTemperature) {
        validVars.push("T");
    }

    if (useWavelength) {
        validVars.push("L");
    }

    if (useConcentration) {
        validVars.push("c");
    }

    do {
        const node = queue.shift();
        if (!node) {
            return true;
        }

        if (node.args) {
            queue.push(...node.args);
        }

        if (!node.isSymbolNode) {
            continue;
        }

        if (node.name && validVars.indexOf(node.name) >= 0) {
            continue;
        }

        return false;
    } while (true);
}

class MathEvaluator {
    private formulaNode?: MathNode;
    private formula = "";
    private result?: EvalFunction;

    public constructor(
        private readonly useTemperature: boolean = false,
        private readonly useWavelength: boolean = false,
        private readonly useEnergy: boolean = false,
        private readonly useConcentration: boolean = false,
    ) {}

    // remember to call this
    public setFormula(formula: string) {
        this.formula = formula;
        this.formulaNode = undefined;
        this.result = undefined;

        if (!formula) {
            return this;
        }

        try {
            const root = parse(formula);
            const isValid = isValidExpression(
                root,
                this.useTemperature,
                this.useWavelength,
                this.useConcentration,
            );

            if (!isValid) {
                throw 405;
            }

            this.result = root.compile();
            this.formulaNode = root;

            return this;
        } catch {
            this.result = undefined;
            // no need to reset formulaNode, as it will be reseted at the beginning and at the end, where nothing can fail anymore

            return this;
        }
    }

    public isValid() {
        return this.result !== undefined;
    }

    public evaluate(formula: string, value: MathValues) {
        if (formula !== this.formula) {
            this.setFormula(formula);
        }

        if (this.result === undefined) {
            return undefined;
        }

        const scope: {T?: number; L?: number; c?: number} = {};

        if (this.useTemperature) {
            scope.T = value.temperature;
        }

        if (this.useWavelength) {
            scope.L = value.wavelength;
        }

        if (this.useConcentration) {
            scope.c = value.concentration;
        }

        try {
            return this.result.evaluate(scope) as number;
        } catch {
            return undefined;
        }
    }

    public getFullLatex(intl: IntlShape, formula: string, value: MathValues) {
        const result = this.evaluate(formula, value);
        if (this.formulaNode === undefined) {
            return undefined;
        }

        try {
            // formulaNode will be checked in this.evaluate
            const formulaTex = toTex(intl, this.formulaNode, this.useEnergy);
            return getFullLatex(intl, formulaTex, result);
        } catch {
            return undefined;
        }
    }
}

export default MathEvaluator;
