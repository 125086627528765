import {
    faCheck,
    faCopy,
    faSave,
    faSpinner,
    faTimes,
    IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {boundMethod} from "autobind-decorator";
import {debounce} from "lodash";
import React from "react";

import {ILocalizedText} from "@translate/models";
import {BUTTON_ANIMATION_DELAY} from "../models";
import {EButtonState} from "./models";

import T from "@translate/T";
import {IntlContext, IntlShape} from "react-intl";
import Tooltip from "../building-blocks/Tooltip";
import DropdownButton from "./DropdownButton";

interface ISaveButtonProps {
    onSave(): Promise<boolean>;
    onSaveAsNew?(): Promise<boolean>;

    classNameButton?: string;
    disabled?: boolean;
    form?: string;
    id?: string;
    noAnimation?: boolean;
    otherIcon?: IconDefinition;
    otherName?: JSX.Element;
    title?: ILocalizedText; // intl and "noSpanElement" is not working thogether
    type?: "button" | "submit" | "reset";
}

interface ISaveButtonState {
    saveState: EButtonState;
}

class SaveButton extends React.PureComponent<
    ISaveButtonProps,
    ISaveButtonState
> {
    public readonly state: ISaveButtonState = {saveState: EButtonState.None};

    private onSave = true;
    private readonly click = debounce(
        () => this.setState({saveState: EButtonState.None}),
        BUTTON_ANIMATION_DELAY,
    );

    public componentWillUnmount() {
        this.click.cancel();
    }

    @boundMethod
    public async submitForm() {
        const {noAnimation, onSave, onSaveAsNew} = this.props;
        this.setState({saveState: EButtonState.InProgress});

        const saved = await (this.onSave ? onSave() : onSaveAsNew?.());
        if (saved && noAnimation) {
            return;
        }

        this.setState(
            {saveState: saved ? EButtonState.Done : EButtonState.Error},
            this.click,
        );
    }

    @boundMethod
    public pressedSave() {
        this.onSave = true;
        if (this.props.type === "button") {
            this.submitForm();
        }
    }

    @boundMethod
    public pressedSaveAsNew() {
        this.onSave = false;
        if (this.props.type === "button") {
            this.submitForm();
        }
    }

    public render() {
        return <IntlContext.Consumer children={this.renderButtons} />;
    }

    @boundMethod
    private renderButtons(intl: IntlShape) {
        return (
            <Tooltip
                content={this.getTooltip()}
                visible={true}
                noSpanElement={true}
            >
                {this.renderButtonGroup(intl)}
            </Tooltip>
        );
    }

    @boundMethod
    private renderButtonGroup(intl: IntlShape) {
        const {onSaveAsNew} = this.props;
        const {saveState} = this.state;
        const {className, icon} = this.getClassName(saveState);

        if (!onSaveAsNew) {
            return this.renderSaveButton(intl, className, icon);
        }

        return (
            <div className="btn-group mr-1">
                {this.renderSaveButton(intl, className, icon)}
                <DropdownButton
                    key="important-for-save-btn-group-children"
                    type="primary"
                    idSuffix="save-menu"
                    classNameButton={`${className} dropdown-toggle-split`}
                    disabled={saveState === EButtonState.InProgress}
                    menu={this.renderSaveNew}
                />
            </div>
        );
    }

    private renderSaveButton(
        intl: IntlShape,
        className: string,
        icon: IconDefinition,
    ) {
        const {classNameButton, disabled, form, id, title, type, otherName} =
            this.props;
        const {saveState} = this.state;

        const addClassName = classNameButton ? " " + classNameButton : "";

        return (
            <button
                type={type ?? "submit"}
                id={id}
                data-testid={id}
                className={className + addClassName}
                disabled={disabled || saveState === EButtonState.InProgress}
                form={form ? "validated-form-" + form : undefined}
                title={title?.(intl)}
                onClick={this.pressedSave}
            >
                <FontAwesomeIcon
                    icon={icon}
                    fixedWidth={true}
                    className="mr-1"
                    spin={saveState === EButtonState.InProgress}
                />
                {otherName ?? <T>Save</T>}
            </button>
        );
    }

    @boundMethod
    private renderSaveNew() {
        const {form, type, children} = this.props;

        return (
            <React.Fragment>
                <button
                    type={type ?? "submit"}
                    id="save-as-new"
                    data-testid="save-as-new"
                    form={form ? "validated-form-" + form : undefined}
                    className="dropdown-item"
                    onClick={this.pressedSaveAsNew}
                >
                    <FontAwesomeIcon
                        icon={faCopy}
                        fixedWidth={true}
                        className="mr-1"
                    />
                    <T>Save as New</T>
                </button>
                {children}
            </React.Fragment>
        );
    }

    private getClassName(saveState: EButtonState) {
        const {otherIcon} = this.props;

        switch (saveState) {
            case EButtonState.Done:
                return {className: "btn btn-success", icon: faCheck};

            case EButtonState.Error:
                return {className: "btn btn-secondary", icon: faTimes};

            case EButtonState.InProgress:
                return {className: "btn btn-primary", icon: faSpinner};

            default:
                return {
                    className: "btn btn-primary",
                    icon: otherIcon ?? faSave,
                };
        }
    }

    private getTooltip() {
        const {saveState} = this.state;

        if (saveState !== EButtonState.Error) {
            return null;
        }

        return (
            <span className="text-warning">
                <T>Could not be submitted.</T>
            </span>
        );
    }
}

export default SaveButton;
