import {faExclamation} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {boundMethod} from "autobind-decorator";
import React from "react";
import {IntlContext, IntlShape} from "react-intl";

import {ERROR_RED} from "@/styles/_variables";

import {intl2Str} from "@translate/T";

interface IACsvErrorIndicatorProps {
    hasError?: boolean;
}

class ACsvErrorIndicator extends React.PureComponent<IACsvErrorIndicatorProps> {
    public render() {
        const {hasError} = this.props;
        if (!hasError) {
            return null;
        }

        return (
            <span className="ml-1">
                <IntlContext.Consumer children={this.renderIcon} />
            </span>
        );
    }

    @boundMethod
    private renderIcon(intl: IntlShape) {
        return (
            <FontAwesomeIcon
                icon={faExclamation}
                fixedWidth={true}
                color={ERROR_RED}
                title={intl2Str(intl, "A-CSV export error.")}
            />
        );
    }
}

export default ACsvErrorIndicator;
