import {ILicensedDevice} from "@toolbox/models";
import {IMissingLicense} from "@toolbox/render-page/models";

export enum ETrialStates {
    NetworkError = -2,
    None = -1,
    InvalidSerial,
    TrialStarted,
    AlreadyTrial,
}

export enum ELicenseUploadErrors {
    None,
    NotALicenseFile,
    InvalidInstallationId,
    Expired,
    Existed,
    CantSave,
    InvalidSerialKey,
    ActivationServerNotReachable, // 404
    OnlineActivationFailed, // 406
    Corrupted,
}

export enum EModuleLicenses {
    FracView = "Mod_FracView",
    FracAnalysis = "Mod_FracAnalysis",

    LumView = "Mod_LumView",
    Instability = "Mod_Clarification",
    Integration = "Mod_Integration",
    FrontTracking = "Mod_FrontTracking",

    Psa = "Mod_PSA",
    PsaMwl = "Mod_Mwl",

    Filtration = "Mod_Filtration",
    Sedimentation = "Mod_Sedimentation",
    FirstDerivative = "Mod_Derivative",
    ExtinctionRatio = "Mod_Ratio",
    Astm = "Mod_ASTM",

    SpocView = "Mod_SpocView",
    SpocCount = "Mod_SpocCount",
    SpocSize = "Mod_SpocSize",

    MultiPeaks = "Mod_MultiPeaks",
    VolumeConcentration = "Mod_VolumeConcentration",
    Hindrance = "Mod_Hindrance",
    AnalyticalUltraCentrifugation = "MOD_AnalyticalUltraCentrifugation",
}

export enum ESopLicenses {
    Fast = "FastSOP",
    Long = "LongSOP",
    Magnet = "Magnet",
    Template = "Template",
    Unlimited = "UnlimitedSOP",
    TemperatureRamp = "Temperature",
    SectorShapedCells = "SectorShapedCells",
    ACsvExport = "ACsvExport",
    FracUnlimitedDuration = "FracUnlimitedDurationSOP",
    FracMultiCycleStressCurveSOP = "FracMultiCycleStressCurveSOP",
}

export interface ILicenseDisplay {
    name: string;
    missing: IMissingLicense;
    nonBuyable: boolean;
}

export interface IFileUploadResult {
    name: string;
    result: ELicenseUploadErrors;
}

export interface IUploadResult {
    license: ILicenseResponse;
    files: IFileUploadResult[];
}

export interface IRequestTrialResponse {
    license: ILicenseResponse;
    result: ETrialStates;
}

export interface ILicenseResponse {
    expiry: string;

    inactiveKeys: string[];
    installationId: string;
    licensee: string;
    serialKey?: string;
    sessions: number;
    users: number;

    isTrial: boolean;
    isValidSystemTime: boolean;
    isCorrupted: boolean;

    devices: ILicensedDevice[];
    modules: EModuleLicenses[];
    sops: ESopLicenses[];
}

// do not touch interfaces below, license server interfaces which cannot be changed

export interface IAuthTokenRequest {
    username: string;
    password: string;
}

export interface IAuthTokenResponse {
    CookieName: string;
    CookieValue: string;
}
