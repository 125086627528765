import {EModules} from "@/models";
import {IDeviceId} from "@toolbox/models";
import {ICompareStorage} from "./services/other/compare-display";
import {IZoomScale} from "./services/other/scale";
import {IBasicAnalyzeSettings} from "./services/settings";

// the base model received and send from and to server
export interface ISampleBaseModel {
    /**
     * Unique string in MEASUREMENT-POSITION-OPTICAL or MEASUREMENT-SERIES-REPETITION format
     * to uniquely identify a sample in analysis
     */
    id: string;

    measurementName: string;
    name: string;
    device: IDeviceId;

    data?: ISampleData;
}

// analysis base data to be sent to server
export interface IAnalysisBaseModel extends IDataStorageOnly {
    id: number;
    engine: EModules;
    project: number;

    name: string;
    owner: string;
    tags: string[];

    missingSampleCount?: number;

    samples: ISampleBaseModel[];
}

export interface ISampleData {
    color?: string;
    enabled?: boolean;
    manual?: string;
}

export interface IAnalysisData<
    TValue extends IBasicAnalyzeSettings = IBasicAnalyzeSettings,
> {
    bargraphZoom?: IZoomScale;
    compare?: Partial<ICompareStorage>;
    hiddenstats?: string[];
    settings?: Partial<TValue>;
}

export interface IDataStorageOnly {
    data?: IAnalysisData;
    samples: Partial<ISampleBaseModel>[];
}

export enum EServerId {
    Centrifuge = "centrifuge",
    Fracture = "fracture",
    Spoc = "spoc",
}
