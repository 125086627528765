import {boundMethod} from "autobind-decorator";
import {parseISO} from "date-fns";
import React from "react";
import {IntlContext, IntlShape} from "react-intl";
import {Link} from "react-router-dom";

import {EDocumentTypes} from "@/components/project/models";
import {EDocIdPrefixes} from "@/components/project/search/models";
import http from "@/services/http";
import {ERoles} from "@/services/models";
import networkDevices from "@/services/network-devices";
import session from "@/services/session";
import {IQueuedSopModel} from "./models";

import {getDocIdInfo} from "@/components/project/search/doc-type";
import UserLink from "@toolbox/building-blocks/UserLink";
import DeleteButton from "@toolbox/button-like/DeleteButton";
import Time from "@toolbox/design/Time";

interface ISopInfoProps {
    sop: IQueuedSopModel;
}

class SopInfo extends React.PureComponent<ISopInfoProps> {
    public static getQueueUrl(sop: IQueuedSopModel) {
        return getDocIdInfo(
            EDocIdPrefixes.CentSopQueues + "/" + sop.id,
            sop.project,
        ).url;
    }

    public render() {
        const {sop} = this.props;

        return (
            <tr key={sop.id}>
                <td>
                    <Link to={SopInfo.getQueueUrl(sop)}>{sop.sopName}</Link>
                </td>
                <td>
                    <IntlContext.Consumer children={this.renderDevice} />
                </td>
                <td>
                    <Time value={parseISO(sop.created)} />
                </td>
                <td>
                    <UserLink
                        username={sop.user.username}
                        displayName={sop.user.displayName}
                        documentType={EDocumentTypes.User}
                    />
                </td>
                {this.renderDeleteButton()}
            </tr>
        );
    }

    private renderDeleteButton() {
        if (!session.hasRole(ERoles.Recorder)) {
            return null;
        }

        return (
            <td>
                <DeleteButton onDelete={this.removeSop} />
            </td>
        );
    }

    @boundMethod
    private renderDevice(intl: IntlShape) {
        return networkDevices.getDeviceName(intl, this.props.sop.device);
    }

    @boundMethod
    private async removeSop() {
        try {
            await http.delete(`/api/centrifuge/sop/queue/${this.props.sop.id}`);

            return true;
        } catch {
            return false;
        }
    }
}

export default SopInfo;
